import React from 'react';
import { Link } from 'react-router-dom';
import './Hub.css';
import { FaGlobe, FaCube, FaMap, FaPuzzlePiece } from 'react-icons/fa'; // Import FaPuzzlePiece

function Hub() {
  return (
    <div className="hub-container">
      <h1 className="hub-title">Flows</h1>
      <div className="hub-cards">
        <Link to="/360" className="hub-card">
          <FaGlobe className="hub-card-icon" />
          <h2 className="hub-card-title">360</h2>
          <p className="hub-card-description">
            Immerse yourself in interactive 360° environments.
          </p>
        </Link>

        <Link to="/2d" className="hub-card">
          <FaGlobe className="hub-card-icon" />
          <h2 className="hub-card-title">2D</h2>
          <p className="hub-card-description">
               Create, manage and edit images, videos and other 2d assets.
          </p>
        </Link>

        <Link to="/game" className="hub-card">
          <FaCube className="hub-card-icon" />
          <h2 className="hub-card-title">3D</h2>
          <p className="hub-card-description">
            Design and interact with 3D models and scenes.
          </p>
        </Link>

        <Link to="/map" className="hub-card">
          <FaMap className="hub-card-icon" />
          <h2 className="hub-card-title">Map</h2>
          <p className="hub-card-description">
            Visualize and interact on a geographical map.
          </p>
        </Link>

        <Link to="/product" className="hub-card">
          <FaPuzzlePiece className="hub-card-icon" /> {/* Changed icon to FaPuzzlePiece */}
          <h2 className="hub-card-title">Apps</h2>
          <p className="hub-card-description">
            Build, customize and manage visual applications.
          </p>
        </Link>
      </div>
    </div>
  );
}

export default Hub;
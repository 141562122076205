// SignIn.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSignIn, SignIn as ClerkSignIn } from '@clerk/clerk-react';

const SignIn = () => {
  const navigate = useNavigate();
  const { isLoaded, signIn, setActive } = useSignIn();

  useEffect(() => {
    console.log("useEffect triggered");
    console.log("  isLoaded:", isLoaded);
    console.log("  signIn:", signIn);

    const handleSignIn = async () => {
      console.log("handleSignIn called");
      console.log("  signIn?.createdSessionId:", signIn?.createdSessionId);

      if (signIn?.createdSessionId) {
        console.log("  Redirect condition met (createdSessionId)");
        try {
          console.log("  Attempting to setActive with session:", signIn.createdSessionId);
          await setActive({ session: signIn.createdSessionId });
          console.log("  setActive successful");
          console.log("  Redirecting to /workspace/explore");
          navigate('/workspace/explore', { replace: true });
        } catch (err) {
          console.error("  Error setting active session:", err);
        }
      } else {
        console.log("  Redirect condition NOT met");
      }
    };

    if (isLoaded) { // Only call handleSignIn when Clerk is loaded to ensure signIn object is available
      handleSignIn();
    }
  }, [isLoaded, signIn, setActive, navigate]); // Removed signInCompleted from dependency array

  console.log("Rendering SignIn component");
  console.log("  isLoaded:", isLoaded);
  console.log("  signIn:", signIn);
  console.log("  signIn?.createdSessionId:", signIn?.createdSessionId);

  const appearance = {
    baseTheme: 'dark',
    elements: {
      card: {
        maxWidth: '100vw', // Ensure card doesn't exceed viewport width
        maxHeight: '96vh', // Ensure card doesn't exceed viewport height with margin
        overflowY: 'auto', // Enable vertical scrolling if content overflows
        marginTop: '2vh',   // Add top margin of 2vh
        marginBottom: '2vh', // Add bottom margin of 2vh
        width: 'auto',      // Let width adjust to content, within maxWidth
      },
    },
  };

  return (
    <div
      style={{
        backgroundImage: `url('/blue_ocean.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}
    >
      {isLoaded && !signIn?.createdSessionId && (
        <ClerkSignIn
          afterSignInUrl="/" // Changed afterSignInUrl to desired redirect
          routing="path"
          path="/signin"
          signUpUrl="/signup"
          appearance={appearance} // Apply the appearance object
        />
      )}
    </div>
  );
};

export default SignIn;
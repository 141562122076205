import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  FileImageOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  AudioOutlined,
  DeleteOutlined,
  CustomerServiceOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { RiUploadCloudFill } from 'react-icons/ri';
import './sandbox.css';
import { useAuth, SignedIn } from '@clerk/clerk-react';

const Sandbox = () => {
  const { userId } = useAuth();
  const [mediaData, setMediaData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [uploadType, setUploadType] = useState('single');
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);
  const [previewType, setPreviewType] = useState(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [editingName, setEditingName] = useState('');
  const [editingDescription, setEditingDescription] = useState('');
  const fileInputRef = useRef(null);
  const [activeSearchType, setActiveSearchType] = useState('search');

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const fetchMediaData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://embed.axv.ai/api/image?page=${page}&limit=20&user_id=${userId}&search=${searchQuery}`
      );
      const data = await response.json();

      if (data && Array.isArray(data.imageList)) {
        setTotalPages(data.totalPages);
        if (searchQuery) {
          setMediaData(data.imageList);
        } else {
          setMediaData((prevData) => {
            const newData = data.imageList.filter(
              (newItem) =>
                !prevData.some((prevItem) => prevItem._id === newItem._id)
            );
            return [...prevData, ...newData];
          });
        }
      } else {
        console.error('Invalid API response format: imageList not found.');
      }
    } catch (error) {
      console.error('Error fetching media data:', error);
    } finally {
      setLoading(false);
    }
  }, [page, searchQuery, userId]);

  useEffect(() => {
    fetchMediaData();
  }, [fetchMediaData]);

  useEffect(() => {
      setMediaData([]);
  }, [])

  const handleLoadMore = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleSearchChange = useCallback(
    debounce((query) => {
      setSearchQuery(query);
      setPage(1);
      if (!query) {
        setMediaData([]);
        fetchMediaData();
      }
    }, 300),
    [fetchMediaData]
  );

  const handleInputChange = (e) => {
        handleSearchChange(e.target.value);
    };

    const getSearchPlaceholder = () => {
        switch (activeSearchType) {
            case 'search':
                return 'Search assets...';
            case '2d':
                return 'Search 2D assets...';
            case '3d':
                return 'Search 3D assets...';
            default:
                return 'Search assets...';
        }
    };

  const handleUploadModalOpen = () => {
    setUploadModalVisible(true);
    setUploadType('single');
  };

  const handleUploadModalClose = () => {
    setUploadModalVisible(false);
    setSelectedFile(null);
    setSelectedFileName('');
    setSelectedCategory('');
    setUploading(false);
    setUploadSuccess(false);
    setUploadError(false);
  };

  const handleUploadTypeChange = (type) => {
    setUploadType(type);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setSelectedFileName(file.name);
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      const fileType = file.type;
      const isImage = fileType.startsWith('image/');
      const isAudio = fileType === 'audio/mpeg';
      const isPDF = fileType === 'application/pdf';

      if (selectedCategory === 'UI' && !isImage) {
        alert('Please select an image file for UI category.');
        return;
      }
      if (
        (selectedCategory === 'Sound' || selectedCategory === 'Voice') &&
        !isAudio
      ) {
        alert('Please select an MP3 file for Sound/Voice category.');
        return;
      }
      if (selectedCategory === 'PDF' && !isPDF) {
        alert('Please select a PDF file for PDF category.');
        return;
      }

      setSelectedFile(file);
      setSelectedFileName(file.name);
    }
  };

  const handleFileDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleFileDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleUpload = async () => {

    if (!selectedFile) {
      alert('Please select a file to upload.');
      return;
    }

    if (!selectedFileName) {
      alert('Please enter a name for the asset.');
      return;
    }

    if (!selectedCategory) {
      alert('Please select a category for the asset.');
      return;
    }

    let type = '';
    if (selectedCategory === 'UI') {
      type = 'image';
    } else if (selectedCategory === 'Sound' || selectedCategory === 'Voice') {
      type = 'audio';
    } else if (selectedCategory === 'PDF') {
      type = 'pdf';
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('user_id', '00');
    formData.append('name', selectedFileName);
    formData.append('category', selectedCategory);
    formData.append('type', type);

    setUploading(true);
    setUploadSuccess(false);
    setUploadError(false);

    try {
      const response = await fetch('https://embed.axv.ai/api/image/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setUploadSuccess(true);
        setMediaData([]);
        setPage(1);
        fetchMediaData();
        setSelectedFile(null);
        setSelectedFileName('');
        setSelectedCategory('');
      } else {
        setUploadError(true);
        console.error('Upload failed:', response.statusText);
      }
    } catch (error) {
      setUploadError(true);
      console.error('Error during upload:', error);
    } finally {
      setUploading(false);
    }
  };

  const handlePreview = (item) => {
    setPreviewType(item.type);

    switch (item.type) {
      case 'audio':
      case 'speech':
        setPreviewContent(
          <div className="audioPlayerContainer" key={item._id}>
            <audio
              className="audioPlayer"
              src={item.file_url}
              onPlay={() => setIsAudioPlaying(true)}
              onPause={() => setIsAudioPlaying(false)}
              onEnded={() => setIsAudioPlaying(false)}
              controls
            />
          </div>
        );
        setPreviewModalVisible(true);
        break;
      case 'pdf':
        setPreviewContent(
          <iframe
            className="pdfPreview"
            src={item.file_url}
            title="PDF Preview"
          />
        );
        setPreviewModalVisible(true);
        break;
      case 'image':
        setPreviewContent(
          <div className="previewImageContainer">
            <img
              src={item.file_url}
              alt={item.name}
              className="previewImage"
            />
          </div>
        );
        setPreviewModalVisible(true);
        break;
      default:
        setPreviewContent(null);
        setPreviewModalVisible(false);
    }
  };

  const handleDelete = async (
    id,
    event
  ) => {

    event.stopPropagation();
    try {
      const response = await fetch(
        `https://embed.axv.ai/api/image/${id}?user_id=${userId}`,
        {
          method: 'DELETE',
        }
      );

      if (response.ok) {
        setMediaData((prevData) => prevData.filter((item) => item._id !== id));
      } else {
        console.error('Failed to delete asset:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting asset:', error);
    }
  };

  const handlePreviewModalClose = () => {
    setPreviewModalVisible(false);
    setPreviewContent(null);
    setPreviewType(null);
    setIsAudioPlaying(false);
  };

  const handleEdit = (item) => {
    setEditingItem(item);
    setEditingName(item.name);
    setEditingDescription(item.description || '');
    setEditModalVisible(true);
  };

  const handleEditModalClose = () => {
    setEditModalVisible(false);
    setEditingItem(null);
    setEditingName('');
    setEditingDescription('');
  };

  const handleEditSave = async () => {
    if (!editingItem) return;

    try {
      const response = await fetch(
        `https://embed.axv.ai/api/image/${editingItem._id}?user_id=${userId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: editingName,
            description: editingDescription,
          }),
        }
      );

      if (response.ok) {
        setMediaData((prevData) =>
          prevData.map((item) =>
            item._id === editingItem._id
              ? { ...item, name: editingName, description: editingDescription }
              : item
          )
        );
        handleEditModalClose();
      } else {
        console.error('Failed to update asset:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating asset:', error);
    }
  };

  const handleSearchTypeChange = (type) => {
    setActiveSearchType(type);
    setSearchQuery('');
    setPage(1);
    setMediaData([]);
    fetchMediaData();
  };


  return (
    <SignedIn>
      <div className="sandbox-container">
        <div className="mediaPage">
          <div className="topBar">
            <div className="uploadSection">
                <RiUploadCloudFill
                  className="uploadIcon"
                  onClick={handleUploadModalOpen}
                  style={{ cursor: 'pointer' }}
                />
            </div>
            <div className="searchBarContainer">
              <div className="searchTabs">
                  <span
                      className={`searchTab ${activeSearchType === 'search' ? 'active' : ''}`}
                      onClick={() => handleSearchTypeChange('search')}
                      title="Search Assets"
                  >
                      <SearchOutlined />
                  </span>
                  <span
                      className={`searchTab ${activeSearchType === '2d' ? 'active' : ''}`}
                      onClick={() => handleSearchTypeChange('2d')}
                      title="Search 2D Assets"
                  >
                      2D
                  </span>
                  <span
                      className={`searchTab ${activeSearchType === '3d' ? 'active' : ''}`}
                      onClick={() => handleSearchTypeChange('3d')}
                      title="Search 3D Assets"
                  >
                      3D
                  </span>
              </div>
              <div className="searchBar">
              <input
                  type="text"
                  placeholder={getSearchPlaceholder()}
                  onChange={handleInputChange}
                  className="searchInput"
              />
              </div>
            </div>

          </div>

          {mediaData.length === 0 && !loading && (
            <div className="emptyStateContainer">
              <div className="emptyStateCard">
                <h2 className="emptyStateTitle">Your Assets</h2>
                <p className="emptyStateText">
                  Neocu allows you to import your media files into our engine
                  to use while you generate. Start uploading and using today.
                </p>
              </div>
            </div>
          )}

          <div className="mediaGrid">
            {mediaData.map((item) => (
              <div
                key={item._id}
                className="mediaCard"
                onClick={() => handlePreview(item)}
                onMouseEnter={(e) => {
                  const deleteIconContainer = e.currentTarget.querySelector(
                    `.deleteIconContainer`
                  );
                  const editIconContainer = e.currentTarget.querySelector(
                    `.editIconContainer`
                  );

                  if (deleteIconContainer) {
                    deleteIconContainer.style.opacity = '1';
                  }
                  if (editIconContainer) {
                    editIconContainer.style.opacity = '1';
                  }
                }}
                onMouseLeave={(e) => {
                  const deleteIconContainer = e.currentTarget.querySelector(
                    `.deleteIconContainer`
                  );
                  const editIconContainer = e.currentTarget.querySelector(
                    `.editIconContainer`
                  );

                  if (deleteIconContainer) {
                    deleteIconContainer.style.opacity = '0';
                  }
                  if (editIconContainer) {
                    editIconContainer.style.opacity = '0';
                  }
                }}
              >
                <div className="deleteIconContainer">
                  <DeleteOutlined
                    className="deleteIcon"
                    onClick={(e) => handleDelete(item._id, e)}
                  />
                </div>
                <div className="editIconContainer">
                  <EditOutlined
                    className="editIcon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(item);
                    }}
                  />
                </div>
                <div className="mediaIconContainer">
                  {item.type === 'audio' && (
                    <AudioOutlined className="mediaIcon" />
                  )}
                  {item.type === 'speech' && (
                    <CustomerServiceOutlined className="mediaIcon" />
                  )}
                  {item.type === 'pdf' && (
                    <FilePdfOutlined className="mediaIcon" />
                  )}
                  {item.type === 'image' && (
                    <div className="imageContainer">
                      <img
                        src={item.file_url}
                        alt={item.name}
                        className="mediaImage"
                      />
                    </div>
                  )}
                </div>
                <span className="mediaName">
                    {item.name.substring(0, 9)}
                    {item.name.length > 9 && '...'}
                </span>
              </div>
            ))}
          </div>

          {loading && (
            <div className="loadingIndicator">
              <LoadingOutlined />
            </div>
          )}

          {page < totalPages && !loading && (
            <button className="loadMoreButton" onClick={handleLoadMore}>
              Load More
            </button>
          )}

          <div
            className={`modalOverlay ${
              uploadModalVisible ? 'visible' : ''
            }`}
            onClick={handleUploadModalClose}
          >
            <div
              className="modalContent"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modalHeader">
                <h2 className="modalTitle">Upload Media</h2>
                <button
                  className="closeButton"
                  onClick={handleUploadModalClose}
                >
                  ×
                </button>
              </div>
              <div className="modalBody">
                {uploadType === 'single' && (
                  <>
                    <div className="uploadInfo">
                      <input
                        type="text"
                        placeholder="Enter asset name"
                        value={selectedFileName}
                        onChange={(e) => setSelectedFileName(e.target.value)}
                        className="assetNameInput"
                      />
                      <select
                        className="selectCategory"
                        value={selectedCategory}
                        onChange={(e) => handleCategoryChange(e.target.value)}
                      >
                        <option value="">Select Category</option>
                        <option value="UI">UI (Image)</option>
                        <option value="Sound">Sound (MP3)</option>
                        <option value="Voice">Voice (MP3)</option>
                        <option value="PDF">PDF</option>
                      </select>
                    </div>
                    <div
                      className={`dropzone ${
                        isDragging ? 'dragging' : ''
                      }`}
                      onDrop={handleFileDrop}
                      onDragOver={handleFileDragOver}
                      onDragLeave={handleFileDragLeave}
                      onClick={() => fileInputRef.current?.click()}
                    >
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                        style={{ display: 'none' }}
                      />
                      {selectedFile && selectedCategory === 'UI' && (
                        <div className="imagePreview">
                          <img
                            src={URL.createObjectURL(selectedFile)}
                            alt="Preview"
                          />
                        </div>
                      )}
                      {selectedFile ? (
                        <p>Selected File: {selectedFileName}</p>
                      ) : (
                        <p>
                          Drag and drop a file here or click to select a file
                        </p>
                      )}
                    </div>
                    <button
                      className="uploadButton"
                      onClick={handleUpload}
                      disabled={uploading}
                    >
                      {uploading ? (
                        <>
                          <LoadingOutlined /> Uploading...
                        </>
                      ) : (
                        'Upload'
                      )}
                    </button>
                  </>
                )}

                {uploadType === 'bulk' && (
                  <div className="comingSoon">
                    <p>Coming Soon</p>
                    <button
                      className="backButton"
                      onClick={() => handleUploadTypeChange('single')}
                    >
                      Back
                    </button>
                  </div>
                )}

                {uploadType !== 'single' && uploadType !== 'bulk' && (
                  <div className="uploadTypeButtons">
                    <button
                      className={uploadType === 'single' ? 'active' : ''}
                      onClick={() => handleUploadTypeChange('single')}
                    >
                      Single
                    </button>
                    <button
                      className={uploadType === 'bulk' ? 'active' : ''}
                      onClick={() => handleUploadTypeChange('bulk')}
                    >
                      Bulk
                    </button>
                  </div>
                )}
              </div>

              {uploadSuccess && (
                <div className={`uploadStatus success`}>
                  <CheckCircleOutlined /> Upload successful!
                </div>
              )}

              {uploadError && (
                <div className={`uploadStatus error`}>
                  <CloseCircleOutlined /> Upload failed. Please try again.
                </div>
              )}
            </div>
          </div>

          <div
            className={`modalOverlay ${
              previewModalVisible ? 'visible' : ''
            }`}
            onClick={handlePreviewModalClose}
          >
            <div
              className={`modalContent previewModal`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modalHeader">
                <h2 className="modalTitle">Preview {previewType}</h2>
                <button
                  className="closeButton"
                  onClick={handlePreviewModalClose}
                >
                  ×
                </button>
              </div>
              <div className="modalBody">{previewContent}</div>
            </div>
          </div>

          <div
            className={`modalOverlay ${
              editModalVisible ? 'visible' : ''
            }`}
            onClick={handleEditModalClose}
          >
            <div
              className={`modalContent editModal`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modalHeader">
                <h2 className="modalTitle">Edit Asset</h2>
                <button
                  className="closeButton"
                  onClick={handleEditModalClose}
                >
                  ×
                </button>
              </div>
              <div className="modalBody">
                <div className="editForm">
                  <input
                    type="text"
                    placeholder="Name"
                    value={editingName}
                    onChange={(e) => setEditingName(e.target.value)}
                    className="editInput"
                  />
                  <textarea
                    placeholder="Description"
                    value={editingDescription}
                    onChange={(e) => setEditingDescription(e.target.value)}
                    className="editTextArea"
                  />
                  <button className="saveButton" onClick={handleEditSave}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SignedIn>
  );
};

export default Sandbox;
import React, { useState } from 'react';
import './Usage.css';
import { FaQuestionCircle, FaPlus } from 'react-icons/fa';

function Usage() {
  const [billingCycle, setBillingCycle] = useState('yearly'); // Default to yearly
  const [studentCardExpanded, setStudentCardExpanded] = useState(false);
  const [inviteCardExpanded, setInviteCardExpanded] = useState(false);

  const monthlyPlans = [
    {
      name: 'Basic Plan',
      price: '$0',
      priceSuffix: '/month',
      features: [
        '50 credits renew daily (10 songs)',
        'Non-commercial terms',
        'No credit top ups',
        'Shared generation queue',
        '2 running jobs at once',
      ],
      buttonText: 'Active',
      isButtonActive: true,
    },
    {
      name: 'Pro Plan',
      price: '$10',
      priceSuffix: '/month',
      features: [
        'Access to our newest model, v4',
        '2,500 credits renew monthly (500 songs)',
        'Early access to new beta features',
        'General commercial terms',
        'Optional credit top ups',
        'Priority generation queue',
        '10 running jobs at once',
      ],
      buttonText: 'Subscribe',
      badge: 'Most Popular',
    },
    {
      name: 'Premier Plan',
      price: '$30',
      priceSuffix: '/month',
      features: [
        'Access to our newest model, v4',
        '10,000 credits renew monthly (2,000 songs)',
        'Early access to new beta features',
        'General commercial terms',
        'Optional credit top ups',
        'Priority generation queue',
        '10 running jobs at once',
      ],
      buttonText: 'Subscribe',
      badge: 'Best Value',
    },
  ];

  const yearlyPlans = [
    {
      name: 'Basic Plan',
      price: '$0',
      priceSuffix: '/month',
      features: [
        '50 credits renew daily (10 songs)',
        'Non-commercial terms',
        'No credit top ups',
        'Shared generation queue',
        '2 running jobs at once',
      ],
      buttonText: 'Active',
      isButtonActive: true,
    },
    {
      name: 'Pro Plan',
      price: '$8', // $10 monthly * 12 * 0.8 (20% off) / 12 = $8
      priceSuffix: '/month',
      features: [
        'Access to our newest model, v4',
        '2,500 credits renew monthly (500 songs)',
        'Early access to new beta features',
        'General commercial terms',
        'Optional credit top ups',
        'Priority generation queue',
        '10 running jobs at once',
      ],
      buttonText: 'Subscribe',
      badge: 'Most Popular',
    },
    {
      name: 'Premier Plan',
      price: '$24', // $30 monthly * 12 * 0.8 (20% off) / 12 = $24
      priceSuffix: '/month',
      features: [
        'Access to our newest model, v4',
        '10,000 credits renew monthly (2,000 songs)',
        'Early access to new beta features',
        'General commercial terms',
        'Optional credit top ups',
        'Priority generation queue',
        '10 running jobs at once',
      ],
      buttonText: 'Subscribe',
      badge: 'Best Value',
    },
  ];

  const studentPlanDetails = [
    'Access to our newest model, v4',
    '2,500 credits renew monthly (500 songs)',
    'Early access to new beta features',
    'General commercial terms',
    'Optional credit top ups',
    'Priority generation queue',
    '10 running jobs at once',
  ];

  const inviteFriendsDetails = [
    'Benefit from free credits by inviting your friends',
    'Each invite gives you 1000 free credits',
    'No limit to the number of friends you can invite',
    'Credits are added to your account instantly',
  ];


  const currentPlans = billingCycle === 'monthly' ? monthlyPlans : yearlyPlans;

  return (
    <div className="pricing-page">
      <div className="billing-options">
        <div className="credit-button-container">
          <button className="credit-button">
            Credit: 25 <FaPlus className="plus-icon" />
          </button>
        </div>
        <div className="billing-toggle-container"> {/* Container for centering toggle */}
          <div className="billing-toggle">
            <button
              className={billingCycle === 'monthly' ? 'active' : ''}
              onClick={() => setBillingCycle('monthly')}
            >
              Monthly billing
            </button>
            <button
              className={billingCycle === 'yearly' ? 'active' : ''}
              onClick={() => setBillingCycle('yearly')}
            >
              Yearly billing (20% off)
            </button>
          </div>
        </div>
      </div>


      <div className="pricing-cards-container">
        {currentPlans.map((plan, index) => (
          <div key={index} className={`pricing-card ${plan.badge ? 'featured-card' : ''}`}>
            {plan.badge && <div className="badge">{plan.badge}</div>}
            <h3 className="plan-name">{plan.name}</h3>
            <div className="price">
              {plan.price}
              <span className="price-suffix">{plan.priceSuffix}</span>
            </div>
            <div className="features-separator"></div>
            <ul className="features-list">
              {plan.features.map((feature, featureIndex) => (
                <li key={featureIndex}>
                  <svg className="checkmark" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
            <button className={`subscribe-button ${plan.isButtonActive ? 'active-button' : ''}`}>
              {plan.buttonText}
            </button>
          </div>
        ))}
      </div>

      <div className="small-cards-container">
        <div className={`small-card ${studentCardExpanded ? 'expanded' : ''}`}>
          <div className="small-card-header" onClick={() => setStudentCardExpanded(!studentCardExpanded)}>
            <div className="plan-title-section">
              <h3 className="small-card-title">Student Pro Plan</h3>
              <div className="price small-price">
                $5<span className="price-suffix small-suffix">/month</span>
              </div>
              <div className="discount-note">1st month free</div>
            </div>
            <button className="details-button">
              {studentCardExpanded ? 'Hide details' : 'See details'}
              <svg className={`chevron-icon ${studentCardExpanded ? 'chevron-up' : 'chevron-down'}`} viewBox="0 0 24 24">
                <path fill="currentColor" d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6-6-6 1.41-1.42z"/>
              </svg>
            </button>
          </div>
          <div className="small-card-action">
            <button className="request-access-button">
              Request Access <FaQuestionCircle className="question-icon" />
            </button>
          </div>
          {studentCardExpanded && (
            <div className="small-card-details">
              <div className="features-separator"></div>
              <ul className="features-list">
                {studentPlanDetails.map((feature, index) => (
                  <li key={index}>
                    <svg className="checkmark" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className={`small-card ${inviteCardExpanded ? 'expanded' : ''}`}>
          <div className="small-card-header" onClick={() => setInviteCardExpanded(!inviteCardExpanded)}>
            <div className="plan-title-section">
              <h3 className="small-card-title">Invite Friends and Earn Free Credits</h3>
            </div>
            <button className="details-button">
              {inviteCardExpanded ? 'Hide details' : 'See details'}
              <svg className={`chevron-icon ${inviteCardExpanded ? 'chevron-up' : 'chevron-down'}`} viewBox="0 0 24 24">
                <path fill="currentColor" d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6-6-6 1.41-1.42z"/>
              </svg>
            </button>
          </div>
          <div className="small-card-action">
            <button className="invite-friends-button">Invite friends</button>
          </div>
          {inviteCardExpanded && (
            <div className="small-card-details">
              <div className="features-separator"></div>
              <ul className="features-list">
                {inviteFriendsDetails.map((feature, index) => (
                  <li key={index}>
                    <svg className="checkmark" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Usage;
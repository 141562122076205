// Tastemaker.js
import React, { useState, useEffect, useRef } from 'react';
import './Tastemaker.css';
import { FaPlus, FaEye, FaXmark, FaCheck, FaPaperPlane, FaBars } from 'react-icons/fa6';
import { useAuth } from "@clerk/clerk-react";
import {ReactPhotoCollage} from 'react-photo-collage'; // Import react-photo-collage

function Tastemaker() {
    const [tasteFeeds, setTasteFeeds] = useState([]);
    const [newFeedTitle, setNewFeedTitle] = useState('');
    const [isAddingFeed, setIsAddingFeed] = useState(false);
    const [imageList, setImageList] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
    const [testQuery, setTestQuery] = useState('');
    const [isTesting, setIsTesting] = useState(false);
    const [testSuccess, setTestSuccess] = useState(false);
    const [testError, setTestError] = useState(false);
    const testButtonTimer = useRef(null);
    const [generatedImages, setGeneratedImages] = useState([]);
    const { userId } = useAuth();
    const [activeBlockId, setActiveBlockId] = useState('defaultBlockId');
    const collageRef = useRef(null);
    const [isTeachActive, setIsTeachActive] = useState(false);
    const [isLearning, setIsLearning] = useState(false);
    const [activeBlockTitle, setActiveBlockTitle] = useState('');
    const [isTestActive, setIsTestActive] = useState(false);

    useEffect(() => {
        const fetchTasteFeeds = async () => {
            try {
                const response = await fetch(`https://embed.axv.ai/api/feed?feed_type=taste`);
                if (response.ok) {
                    const data = await response.json();
                    setTasteFeeds(data.feedList || []);
                    if (data.feedList && data.feedList.length > 0) {
                        setActiveBlockId(data.feedList[0]._id);
                        setActiveBlockTitle(data.feedList[0].title);
                    }
                } else {
                    console.error('Failed to fetch taste feeds:', response.status);
                }
            } catch (error) {
                console.error('Error fetching taste feeds:', error);
            }
        };

        fetchTasteFeeds();
    }, []);

    useEffect(() => {
        const fetchImages = async () => {
            if (activeBlockId && activeBlockId !== 'defaultBlockId') {
                try {
                    const response = await fetch(`https://embed.axv.ai/api/image?block_id=${activeBlockId}`);
                    if (response.ok) {
                        const data = await response.json();
                        const formattedImages = data.imageList.map(img => ({
                            source: img.file_url,
                            _id: img._id
                        }));
                        setImageList(formattedImages);
                    } else {
                        console.error('Failed to fetch images:', response.status);
                        setImageList([]);
                    }
                } catch (error) {
                    console.error('Error fetching images:', error);
                    setImageList([]);
                }
            } else {
                setImageList([]);
            }
        };

        fetchImages();
    }, [activeBlockId]);

    useEffect(() => {
        const fetchGeneratedImages = async () => {
            try {
                const response = await fetch(`https://embed.axv.ai/api/image?mode=generated`);
                if (response.ok) {
                    const data = await response.json();
                    setGeneratedImages(data.imageList || []);
                } else {
                    console.error('Failed to fetch generated images:', response.status);
                }
            } catch (error) {
                console.error('Error fetching generated images:', error);
            }
        };

        fetchGeneratedImages();
    }, []);

    const handleAddFeed = async () => {
        if (!newFeedTitle.trim()) return;
        setIsAddingFeed(true);
        try {
            const response = await fetch('https://embed.axv.ai/api/feed', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ title: newFeedTitle, feed_type: 'taste' }),
            });
            if (response.ok) {
                const data = await response.json();
                setTasteFeeds([...tasteFeeds, data.feed]);
                setNewFeedTitle('');
            } else {
                console.error('Failed to add feed:', response.status);
                alert('Failed to add feed.');
            }
        } catch (error) {
            console.error('Error adding feed:', error);
            alert('Error adding feed.');
        } finally {
            setIsAddingFeed(false);
        }
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        setIsDraggingOver(false);
        const files = Array.from(event.dataTransfer.files);
        setSelectedFiles(files);
    };

    const handleFileInputChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };

    const handleDragLeave = () => {
        setIsDraggingOver(false);
    };

    const handleConfirmUpload = async () => {
        if (selectedFiles.length === 0) {
            alert('Please select or drop at least one file.');
            return;
        }
        if (activeBlockId === 'defaultBlockId') {
            alert('Please select a Block before confirming upload.');
            return;
        }

        setUploading(true);
        setUploadSuccess(false);
        setUploadError(false);

        try {
            for (const file of selectedFiles) {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('block_id', activeBlockId);
                formData.append('mode', 'taste');
                formData.append('user_id', userId || '00');

                // Note: Removed file type checking as per the requirement to treat all as images
                formData.append('category', 'image');

                const response = await fetch('https://embed.axv.ai/api/image/upload', {
                    method: 'POST',
                    body: formData,
                });

                if (!response.ok) {
                    setUploadError(true);
                    console.error('Upload failed for file:', file.name, response.statusText);
                    throw new Error(`Upload failed for ${file.name}: ${response.statusText}`);
                }
            }

            setUploadSuccess(true);
            setSelectedFiles([]);
            try {
                const response = await fetch(`https://embed.axv.ai/api/image?block_id=${activeBlockId}`);
                if (response.ok) {
                    const data = await response.json();
                    const formattedImages = data.imageList.map(img => ({
                        source: img.file_url,
                        _id: img._id
                    }));
                    setImageList(formattedImages);
                }
            } catch (fetchError) {
                console.error('Error refetching images after upload:', fetchError);
            }

        } catch (error) {
            setUploadError(true);
            console.error('Error during upload process:', error);
        } finally {
            setUploading(false);
            setTimeout(() => setUploadSuccess(false), 3000);
        }
    };

    const handleImagePreview = (image) => {
        setPreviewImage(image.source);
        setIsPreviewModalVisible(true);
    };

    const handleClosePreviewModal = () => {
        setIsPreviewModalVisible(false);
        setPreviewImage(null);
    };

    const handleDeleteImage = async (imageId) => {
        try {
            const response = await fetch(`https://embed.axv.ai/api/image/${imageId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setImageList(imageList.filter(img => img._id !== imageId));
            } else {
                console.error('Failed to delete image:', response.status);
                alert('Failed to delete image.');
            }
        } catch (error) {
            console.error('Error deleting image:', error);
            alert('Error deleting image.');
        }
    };

    const handleTestQueryChange = (e) => {
        setTestQuery(e.target.value);
    };

    const handleTestSend = async () => {
        if (!testQuery.trim()) return;
        setIsTesting(true);
        setTestSuccess(false);
        setTestError(false);

        try {
            const response = await fetch('https://embed.axv.ai/api/block/agent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ query: testQuery, block_id: activeBlockId, user_id: userId || '00' })
            });

            if (response.ok) {
                setTestSuccess(true);
                setTestQuery('');

                testButtonTimer.current = setTimeout(() => {
                    setTestSuccess(false);
                }, 2000);

            } else {
                console.error('API request failed', response.status);
                setTestError(true);
            }
        } catch (error) {
            console.error('Error sending query:', error);
            setTestError(true);
        } finally {
            setIsTesting(false);
        }
    };

    const handleAutopilotClick = () => {
        alert("Autopilot feature is not yet implemented.");
    };

    const handleTeachButtonClick = () => {
        setIsTeachActive(!isTeachActive);
        setIsLearning(!isLearning);
    };

    const handleTestButtonClick = () => {
        setIsTestActive(!isTestActive);
    };

    const cancelAddFeed = () => {
        setIsAddingFeed(false);
        setNewFeedTitle('');
    };

    const handleFeedItemClick = (event) => {
        const selectedFeedId = event.target.value;
        const selectedFeed = tasteFeeds.find(feed => feed._id === selectedFeedId);
        if (selectedFeed) {
            setActiveBlockId(selectedFeed._id);
            setActiveBlockTitle(selectedFeed.title);
            setIsTeachActive(false);
            setIsLearning(false);
        } else {
            setActiveBlockId('defaultBlockId');
            setActiveBlockTitle('');
        }
    };

    const handleCancelLearning = (e) => {
        e.stopPropagation();
        setIsLearning(false);
        setIsTeachActive(false);
        console.log("Learning Cancelled");
    }

    const setting = {
        width: '100%',
        height: ['500px', '500px'], // Adjust heights for responsiveness
        layout: [3, 4, 2, 3], // Example layout, adjust as needed
        photos: imageList.map(img => ({ source: img.source })),
        showNumOfRemainingPhotos: false,
        direction: 'row'
    };

    return (
        <div className="tastemaker-page">
            <div className="tastemaker-content" style={{ paddingTop: '20px' }}>
                <div className="collage-header">
                    <div className="active-block-header">
                        <div className="active-block-title-area">
                            {activeBlockTitle || "No Block Selected"}
                        </div>
                        <button className={`teach-button ${isTeachActive ? 'active' : ''} ${isLearning ? 'learning' : ''}`} onClick={handleTeachButtonClick} disabled={isLearning}>
                            {isLearning ? (<><span className="button-loader"></span> Learning <FaXmark className="cancel-learning-icon" onClick={handleCancelLearning} /></>) : 'Teach'}
                        </button>
                    </div>

                    <div className="blocks-dropdown-container">
                        <select
                            className="blocks-dropdown"
                            value={activeBlockId}
                            onChange={handleFeedItemClick}
                        >
                            <option value="defaultBlockId">Select Block</option>
                            {tasteFeeds.map(feed => (
                                <option key={feed._id} value={feed._id}>{feed.title}</option>
                            ))}
                        </select>
                        <button className="add-feed-button-header" onClick={() => setIsAddingFeed(true)} disabled={isAddingFeed}>
                            {isAddingFeed ? <FaXmark onClick={cancelAddFeed} /> : <FaPlus />}
                        </button>
                        {isAddingFeed && (
                            <div className="new-feed-input-container-header">
                                <input
                                    type="text"
                                    placeholder="Enter Block Title"
                                    value={newFeedTitle}
                                    onChange={(e) => setNewFeedTitle(e.target.value)}
                                    className="new-feed-input-header"
                                />
                                <button className="add-button-header" onClick={handleAddFeed} disabled={uploading}>
                                    {isAddingFeed ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        )}
                    </div>


                    {imageList.length > 0 && (
                        <button className="test-button" onClick={handleTestButtonClick}>Test</button>
                    )}
                    {isTestActive && imageList.length > 0 && (
                        <div className="test-input-area">
                            <input
                                type="text"
                                className="test-query-input"
                                placeholder="Enter test query"
                                value={testQuery}
                                onChange={handleTestQueryChange}
                            />
                            <button className="send-test-button" onClick={handleTestSend} disabled={isTesting}>
                                {isTesting ? (
                                    <div className="custom-loader send-button-icon"></div>
                                ) : testSuccess ? (
                                    <FaCheck color="green" size={16} className="send-button-icon" />
                                ) : (
                                        <FaPaperPlane className="send-button-icon" />
                                    )}
                            </button>
                        </div>
                    )}
                    {selectedFiles.length > 0 && (
                        <button className="confirm-button" onClick={handleConfirmUpload} disabled={uploading || activeBlockId === 'defaultBlockId'}>
                            {uploading ? 'Uploading...' : 'Confirm'}
                        </button>
                    )}
                </div>

                {isTeachActive ? (
                    <div
                        className={`upload-area dragging-enabled ${isDraggingOver ? 'dragging-over' : ''}`}
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                    >
                        <p>Drag and drop files here or <label htmlFor="fileInput" className="upload-link">click to upload</label></p>
                        <input
                            id="fileInput"
                            type="file"
                            multiple
                            style={{ display: 'none' }}
                            onChange={handleFileInputChange}
                        />
                        {uploadError && <p className="upload-error-message">Upload failed. Please try again.</p>}
                        {uploadSuccess && <p className="upload-success-message">Upload successful!</p>}
                        {selectedFiles.length > 0 && (
                            <div className="selected-files-preview">
                                {selectedFiles.map((file, index) => (
                                    <img key={index} src={URL.createObjectURL(file)} alt={`Preview ${index}`} className="file-preview-image" />
                                ))}
                            </div>
                        )}
                    </div>
                ) : (
                    imageList.length > 0 ? (
                        <div className="collage-container" ref={collageRef}>
                            <ReactPhotoCollage {...setting} />
                            <div className="image-actions-container">
                            {imageList.map((image, index) => (
                                 <div key={index} className="collage-image-actions" style={setting.layout[index] ? {gridColumn: `span ${setting.layout[index]}` , gridRow: `span 1`} : {}}>
                                    <button className="image-preview-button" onClick={() => handleImagePreview(image)}><FaEye /></button>
                                    <button className="image-delete-button" onClick={() => handleDeleteImage(image._id)}><FaXmark /></button>
                                </div>
                            ))}
                            </div>
                        </div>
                    ) : (
                        <div className="no-results-card">
                            <h3 className="no-results-title">Tastemakers</h3>
                            <p className="no-results-description">Tastemakers allows you to influence how the model looks at design and what looks or feels good.</p>
                        </div>
                    )
                )}
            </div>

            {isPreviewModalVisible && (
                <div className="preview-modal-overlay" onClick={handleClosePreviewModal}>
                    <div className="preview-modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="modal-close-button" onClick={handleClosePreviewModal}><FaXmark /></button>
                        <img src={previewImage} alt="Preview" className="preview-image-full" />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Tastemaker;
// Explore.js
import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Explore.css";
import mapboxgl from "mapbox-gl";
import {
  FaDrawPolygon,
  FaFont,
  FaCaretDown,
  FaSave,
  FaSearch,
  FaArrowLeft,
} from "react-icons/fa";
import MarkerCard from "./MarkerCard";
import Legend from "./Legend";
import Draw from "./Draw";
import Text from "./Text";
import SaveMapModal from "./SaveMapModal";
import { mapStyles } from "./MapStyle";
import "mapbox-gl/dist/mapbox-gl.css";
import { SignedIn, useAuth } from "@clerk/clerk-react";

mapboxgl.accessToken =
  "pk.eyJ1Ijoib2xtYWkiLCJhIjoiY2xmcXl3MHVpMDNidjNzcG85aWJ5M2w3NCJ9.PKf8J7x_Pu7Psbuh-n0y8w";

const Explore = () => {
  const { isLoaded, userId } = useAuth();
  const navigate = useNavigate();
  const [isPolygonDropdownOpen, setIsPolygonDropdownOpen] = useState(false);
  const [isTextDropdownOpen, setIsTextDropdownOpen] = useState(false);
  const [isMapTypeDropdownOpen, setIsMapTypeDropdownOpen] = useState(false);
  const [currentMapStyle, setCurrentMapStyle] = useState(mapStyles.standard);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [legendItems, setLegendItems] = useState([]);
  const [legendMinimized, setLegendMinimized] = useState(false);
  const [drawMode, setDrawMode] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(true);
  const polygonDropdownRef = useRef(null);
  const textDropdownRef = useRef(null);
  const mapTypeDropdownRef = useRef(null);
  const mapRef = useRef(null);
  const drawRef = useRef(null);
  const { map_id } = useParams();

  // Initialize map
  useEffect(() => {
    if (!isLoaded || !userId) return;

    const map = new mapboxgl.Map({
      container: "map-container",
      style: currentMapStyle,
      attributionControl: false,
      center: [-74.006, 40.7128], // New York City
      zoom: 9,
      pitch: 60, // 3D view
    });

    map.addControl(
      new mapboxgl.AttributionControl({
        compact: true,
      }),
      "bottom-left"
    );

    map.on("load", () => {
      setIsLoading(false);
      if (map_id) {
        fetchMapDataById(map_id);
      }
    });

    map.on("style.load", () => {
      setIsLoading(false);
      if (!mapRef.current) return;

      legendItems.forEach((item) => {
        addSourceAndLayersFromLegend(item);
      });
    });

    mapRef.current = map;

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [isLoaded, userId, map_id]);

  useEffect(() => {
    if (!userId || !mapRef.current) return;

    if (currentMapStyle) {
      setIsLoading(true);
      mapRef.current.setStyle(currentMapStyle);
    }
  }, [currentMapStyle, userId]);

  useEffect(() => {
    if (!isLoaded) return;

    if (!userId) {
      navigate("/signin");
    }
  }, [isLoaded, userId, navigate]);

  const fetchMapDataById = async (mapId) => {
    if (!userId) return;
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://embed.axv.ai/api/block/map/${mapId}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const mapData = result.mapData.mapData;
      const map = mapRef.current;
      if (!map) return;

      let newMapStyle = mapStyles.dark;
      if (mapStyles[mapData.style]) {
        newMapStyle = mapStyles[mapData.style];
      }

      if (currentMapStyle !== newMapStyle) {
        setCurrentMapStyle(newMapStyle);
      } else {
        mapData.sources.forEach((source) => {
          const sourceId = source.id;
          if (!map.getSource(sourceId)) {
            map.addSource(sourceId, {
              type: source.type,
              data: source.data,
            });

            mapData.layers
              .filter((layer) => layer.source === sourceId)
              .forEach((layer) => {
                const newLayerId = `${layer.id}-${sourceId}`;
                if (!map.getLayer(newLayerId)) {
                  map.addLayer({
                    ...layer,
                    id: newLayerId,
                    source: sourceId,
                  });

                  map.on("mouseenter", newLayerId, (e) => {
                    map.getCanvas().style.cursor = "pointer";
                    const feature = e.features[0];
                    if (
                      feature.properties.name ||
                      feature.properties.event_id
                    ) {
                      setHoveredFeature(feature);
                    }
                  });

                  map.on("mouseleave", newLayerId, () => {
                    map.getCanvas().style.cursor = "";
                    setHoveredFeature(null);
                  });
                }
              });
          }
        });
      }

      const newLegendItems = mapData.sources.map((source) => ({
        id: source.id,
        query: "",
        legendName: result.mapData.name,
        description: result.mapData.description,
        color: "#ccc",
        visible: true,
        center: mapData.center,
        source: {
          type: source.type,
          data: source.data,
        },
        layers: mapData.layers.filter((layer) => layer.source === source.id),
      }));

      setLegendItems(newLegendItems);

      map.setCenter([mapData.center.lng, mapData.center.lat]);
      map.setZoom(mapData.zoom);
    } catch (error) {
      console.error("Error fetching map data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePolygonMouseEnter = () => {
    setIsPolygonDropdownOpen(true);
  };

  const handlePolygonMouseLeave = () => {
    setIsPolygonDropdownOpen(false);
  };

  const handleTextMouseEnter = () => {
    setIsTextDropdownOpen(true);
  };

  const handleTextMouseLeave = () => {
    setIsTextDropdownOpen(false);
  };

  const handleMapTypeMouseEnter = () => {
    setIsMapTypeDropdownOpen(true);
  };

  const handleMapTypeMouseLeave = () => {
    setIsMapTypeDropdownOpen(false);
  };

  const handleMapTypeChange = (newStyle) => {
    setCurrentMapStyle(newStyle);
  };

  const addSourceAndLayersFromLegend = (item) => {
    const map = mapRef.current;
    if (!map) return;

    if (map && item.source && item.layers && !map.getSource(item.id)) {
      map.addSource(item.id, item.source);
      item.layers.forEach((layer) => {
        const newLayerId = `${layer.id}-${item.id}`;
        if (!map.getLayer(newLayerId)) {
          map.addLayer({ ...layer, id: newLayerId, source: item.id });

          map.on("mouseenter", newLayerId, (e) => {
            map.getCanvas().style.cursor = "pointer";
            const feature = e.features[0];
            if (
              feature.properties.name ||
              feature.properties.event_id
            ) {
              setHoveredFeature(feature);
            }
          });

          map.on("mouseleave", newLayerId, () => {
            map.getCanvas().style.cursor = "";
            setHoveredFeature(null);
          });
        }
      });
    }
  };

  const fetchCrimeData = async (query) => {
    if (!userId) return;

    setIsLoading(true);
    try {
      const response = await fetch("https://embed.axv.ai/api/block/map", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const map = mapRef.current;
      if (!map) return;

      const sourceId = `crime-data-${Date.now()}`;

      if (map.getSource(sourceId)) {
        map.getStyle().layers.forEach((layer) => {
          if (layer.source === sourceId) {
            map.removeLayer(layer.id);
          }
        });
        map.removeSource(sourceId);
      }

      // Load and resize images from the features
      const loadImagePromises = result.data.visualization.source.data.features.map(feature => {
        return new Promise((resolve, reject) => {
          if (feature.properties.icon && !map.hasImage(feature.properties.icon)) {
            map.loadImage(feature.properties.icon, (error, originalImage) => {
              if (error) {
                console.error("Error loading image:", error);
                reject(error);
              } else {
                // Resize the image to 64x64
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = 64;
                canvas.height = 64;
                ctx.drawImage(originalImage, 0, 0, 64, 64);

                const resizedImage = new Image();
                resizedImage.src = canvas.toDataURL();

                resizedImage.onload = () => {
                  map.addImage(feature.properties.icon, resizedImage);
                  resolve();
                };
              }
            });
          } else {
            resolve();
          }
        });
      });

      await Promise.all(loadImagePromises);

      map.addSource(sourceId, result.data.visualization.source);
      result.data.visualization.layers.forEach((layer) => {
        const newLayerId = `${layer.id}-${sourceId}`;
        // Use the loaded image in the layer
        const updatedLayer = {
          ...layer,
          id: newLayerId,
          source: sourceId,
          layout: {
            ...layer.layout,
            "icon-image": ["case", ["has", "icon"], ["get", "icon"], "marker-15"],
            "icon-size": ["case", ["has", "icon"], 1, 0.75],
            // Reduce text size
            "text-size": 10,
            "text-offset": [0, 1.5], // Adjust text offset to accommodate smaller text
          },
        };
        map.addLayer(updatedLayer);

        map.on("mouseenter", newLayerId, (e) => {
          map.getCanvas().style.cursor = "pointer";
          const feature = e.features[0];
          if (
            feature.properties.name ||
            feature.properties.event_id
          ) {
            setHoveredFeature(feature);
          }
        });

        map.on("mouseleave", newLayerId, () => {
          map.getCanvas().style.cursor = "";
          setHoveredFeature(null);
        });
      });

      setLegendItems((prevItems) => {
        const existingItemIndex = prevItems.findIndex(
          (item) => item.id === sourceId
        );
        if (existingItemIndex > -1) {
          return prevItems.map((item, index) =>
            index === existingItemIndex
              ? {
                  ...item,
                  query,
                  legendName: result.title,
                  description: result.description,
                  center: result.data.center,
                  source: result.data.visualization.source,
                  layers: result.data.visualization.layers,
                }
              : item
          );
        } else {
          return [
            ...prevItems,
            {
              id: sourceId,
              query,
              legendName: result.title,
              description: result.description,
              color: "#ccc",
              visible: true,
              center: result.data.center,
              source: result.data.visualization.source,
              layers: result.data.visualization.layers,
            },
          ];
        }
      });

      if (result.data.center) {
        map.setCenter(result.data.center);
        map.setZoom(result.data.zoom);
      }
    } catch (error) {
      console.error("Error fetching crime data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLegendItemVisibilityChange = (itemId) => {
    setLegendItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === itemId) {
          const newVisibility = !item.visible;
          const map = mapRef.current;
          if (!map) return item;

          if (map) {
            map.getStyle().layers.forEach((layer) => {
              if (layer.source === itemId) {
                map.setLayoutProperty(
                  layer.id,
                  "visibility",
                  newVisibility ? "visible" : "none"
                );
              }
            });
          }
          return { ...item, visible: newVisibility };
        }
        return item;
      })
    );
  };

  const handleLegendItemRemove = (itemId) => {
    const map = mapRef.current;
    if (!map) return;

    if (map.getSource(itemId)) {
      map.getStyle().layers.forEach((layer) => {
        if (layer.source === itemId) {
          map.removeLayer(layer.id);
        }
      });
      map.removeSource(itemId);
    }

    setLegendItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const handleLegendMinimize = (isMinimized) => {
    setLegendMinimized(isMinimized);
  };

  const handleCenterItem = (center) => {
    if (!mapRef.current) return;
    mapRef.current.easeTo({
      center: center,
      zoom: 10,
      duration: 2000,
    });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    fetchCrimeData(searchQuery);
  };

  const handleDraw = (mode) => {
    if (!mapRef.current) return;

    if (drawMode === mode) {
      setDrawMode(null);
    } else {
      setDrawMode(mode);
    }

    if (drawRef.current) {
      if (mode === "polygon") {
        drawRef.current.changeMode("draw_polygon");
      } else if (mode === "line") {
        drawRef.current.changeMode("draw_line_string");
      } else if (mode === "point") {
        drawRef.current.changeMode("draw_point");
      } else if (mode === "circle") {
        drawRef.current.changeMode("draw_circle");
      } else {
        drawRef.current.changeMode("simple_select");
      }
    }
  };

  const handleSaveMap = () => {
    setIsSaveModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSaveModalOpen(false);
  };

  const toggleSearch = () => {
    setIsSearchActive(!isSearchActive);
    console.log("Search toggled:", !isSearchActive);
  };

  const handleMapStyleChange = (newStyle) => {
    setCurrentMapStyle(newStyle);
    if (!mapRef.current) return;

    mapRef.current.setStyle(newStyle);
  };

  return (
    <SignedIn>
      <div className="explore-container">
        <div className="main-content" style={{ marginLeft: "0px" }}>
            {/* Top Left "360" and Back Arrow */}

          <div className="map-container-wrapper">
            {isLoading && (
              <div className="loading-container">
                <div className="loading-spinner"></div>
              </div>
            )}
            <div
              id="map-container"
              className="map-container"
              style={{ visibility: isLoading ? "hidden" : "visible" }}
            >
               <div className="top-left-branding">
                              <div className="back-arrow" onClick={() => window.location.href = '/'}>
                                  < FaArrowLeft size={20} color="grey" />
                              </div>
                              <div className="brand-text">360</div>
                          </div>
              <Draw
                map={mapRef.current}
                drawRef={drawRef}
                isSearchActive={isSearchActive}
                toggleSearch={toggleSearch}
                currentMapStyle={currentMapStyle}
                onMapStyleChange={handleMapStyleChange}
              >
                <div className="toolbox bottom-right"> {/* Changed to bottom-right */}
                  <div
                    className="dropdown-icon"
                    ref={polygonDropdownRef}
                    onMouseEnter={handlePolygonMouseEnter}
                    onMouseLeave={handlePolygonMouseLeave}
                  >
                    <div className="icon-with-arrow">
                      <FaDrawPolygon className="icon" />
                      <FaCaretDown className="icon dropdown-arrow" />
                    </div>
                    {isPolygonDropdownOpen && (
                      <ul className="dropdown-menu">
                        <li onClick={() => handleDraw("polygon")}>Polygon</li>
                        <li onClick={() => handleDraw("line")}>Line</li>
                        <li onClick={() => handleDraw("point")}>Point</li>
                        <li onClick={() => handleDraw("circle")}>Circle</li>
                      </ul>
                    )}
                  </div>

                  <div
                    className="dropdown-icon"
                    ref={textDropdownRef}
                    onMouseEnter={handleTextMouseEnter}
                    onMouseLeave={handleTextMouseLeave}
                  >
                    <div className="icon-with-arrow">
                      <FaFont className="icon" />
                      <FaCaretDown className="icon dropdown-arrow" />
                    </div>
                    {isTextDropdownOpen && (
                      <ul className="dropdown-menu">
                        <li>
                          <Text map={mapRef.current} />
                        </li>
                      </ul>
                    )}
                  </div>

                  {legendItems.length > 0 && (
                    <FaSave
                      className="icon save-icon"
                      style={{ color: "green" }}
                      onClick={handleSaveMap}
                    />
                  )}
                </div>
              </Draw>

              <div className="map-overlay-container">
                {isSearchActive && (
                  <form
                    onSubmit={handleSearchSubmit}
                    className="map-overlay top-center"
                  >
                    <input
                      type="text"
                      className="search-bar"
                      placeholder="Discover..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </form>
                )}
              </div>

              {hoveredFeature && <MarkerCard feature={hoveredFeature} />}
              {legendItems.length > 0 && (
                <Legend
                  items={legendItems}
                  onItemVisibilityChange={handleLegendItemVisibilityChange}
                  onItemRemove={handleLegendItemRemove}
                  onCenterItem={handleCenterItem}
                  onMinimize={handleLegendMinimize}
                  isMinimized={legendMinimized}
                />
              )}
            </div>
            <SaveMapModal
              isOpen={isSaveModalOpen}
              onClose={handleCloseModal}
              mapRef={mapRef}
              legendItems={legendItems}
            />
          </div>
        </div>
      </div>
    </SignedIn>
  );
};

export default Explore;
import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Home.css';
import VisitorContent from '../components/VisitorContent';
import './VisitorContent.css';
import 'animate.css';
import { useNavigate, Link } from 'react-router-dom';
import Onboard from './Onboard';
import Header from '../components/Header'; // Import Header Component

import { FaEarthAmericas, FaLock, FaPaperclip, FaCalendarCheck, FaPaperPlane, FaCheck, FaXmark, FaBrush, FaFilePdf, FaFileCsv, FaPlay, FaDownload, FaChevronUp, FaChevronDown, FaShare, FaChevronRight, FaExpand, FaPause, FaGear, FaRotateRight, FaLink } from 'react-icons/fa6';
import { useAuth, useUser } from "@clerk/clerk-react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Motion, spring } from 'react-motion';
import JSZip from 'jszip';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
library.add(faPlay, faPause);


function Home({ openComputeModal }) {
    const navigate = useNavigate();

    const capitalizeFirstLetter = (name) => {
        if (!name) return "Account";
        return name.charAt(0).toUpperCase() + name.slice(1);
    };

    const [showDropdown, setShowDropdown] = useState(false);
    const typingSpeed = 50;
    const textAreaRef = useRef(null);
    const sendButtonTimer = useRef(null);
    const { user } = useUser();
    const { userId, isSignedIn } = useAuth();
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [roomsState, setRoomsState] = useState(false);
    const fileInputRef = useRef(null);
    const [attachedFileInfos, setAttachedFileInfos] = useState([]);

    const [isExpandedModalOpen, setIsExpandedModalOpen] = useState(isHomePage => false);
    const [expandedModalAsset, setExpandedModalAsset] = useState(null);

    const [isAttachButtonActive, setIsAttachButtonActive] = useState(false);


    const handleExpandModalOpen = (asset) => {
        setExpandedModalAsset(asset);
        setIsExpandedModalOpen(true);
    };

    const handleExpandModalClose = () => {
        setIsExpandedModalOpen(false);
        setExpandedModalAsset(null);
    };


    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [uploadPurpose, setUploadPurpose] = useState('tabcontent');
    const [uploadType, setUploadType] = useState('single');
    const [selectedFileState, setSelectedFileState] = useState(null);
    const [selectedFileNameState, setSelectedFileNameState] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [lastUploadedFileInfo, setLastUploadedFileInfo] = useState(null);

    const handleUploadModalOpen = (e, purpose = 'tabcontent') => {
        e.stopPropagation();
        if (!isSignedIn && purpose === 'attach') {
            setShowSignInModal(true);
            return;
        }
        console.log("handleUploadModalOpen called with purpose:", purpose);
        setUploadModalVisible(true);
        setUploadType('single');
        setUploadPurpose(purpose);
        setSelectedCategory('');
        setSelectedFileNameState('');
        setSelectedFileState(null);
        setUploadSuccess(false);
        setUploadError(false);
    };

    const handleUploadModalClose = () => {
        setUploadModalVisible(false);
        setSelectedFileState(null);
        setSelectedFileNameState('');
        setSelectedCategory('');
        setUploading(false);
        setUploadSuccess(false);
        setUploadError(false);
        setUploadPurpose('tabcontent');
    };

    const handleUploadTypeChange = (type) => {
        setUploadType(type);
    };

    const handleFileSelect = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFileState(file);
            setSelectedFileNameState(file.name);
        }
    };


    const handleFileDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const file = event.dataTransfer.files[0];
        if (file) {
            const fileType = file.type;
            const isImage = fileType.startsWith('image/');
            const isAudio = fileType === 'audio/mpeg';
            const isPDF = fileType === 'application/pdf';
            const isVideo = fileType.startsWith('video/');

            if (selectedCategory === 'UI' && !isImage) {
                alert('Please select an image file for UI category.');
                return;
            }
            if (
                (selectedCategory === 'Sound' || selectedCategory === 'Voice') &&
                !isAudio
            ) {
                alert('Please select an MP3 file for Sound/Voice category.');
                return;
            }
            if (selectedCategory === 'PDF') {
                alert('Please select a PDF file for PDF category.');
                return;
            }
            if (selectedCategory === 'template' && !isVideo && !isImage) {
                alert('Please select a video or image file for template category.');
                return;
            }


            setSelectedFileState(file);
            setSelectedFileNameState(file.name);
        }
    };

    const handleFileDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleFileDragLeave = (event) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const handleUpload = async ({ assetName }) => {
        console.log("handleUpload called", { assetName, selectedFileState, selectedCategory, uploadPurpose });

        if (!selectedFileState) {
            alert('Please select a file to upload.');
            return;
        }

        if (uploadPurpose === 'tabcontent') {
            if (!assetName) {
                alert('Please enter a name for the asset.');
                return;
            }

            if (!selectedCategory) {
                alert('Please select a category for the asset.');
                return;
            }
        }


        let type = '';
        if (selectedCategory === 'UI') {
            type = 'image';
        } else if (selectedCategory === 'Sound' || selectedCategory === 'Voice') {
            type = 'audio';
        } else if (selectedCategory === 'PDF') {
            type = 'pdf';
        } else if (selectedCategory === 'CSV') {
            type = 'csv';
        } else if (selectedCategory === 'template') {
            type = 'video';
        } else if (selectedCategory === 'presentation') {
            type = 'presentation';
        }


        const formData = new FormData();
        formData.append('file', selectedFileState);
        formData.append('user_id', userId || '00');
        if (uploadPurpose === 'tabcontent') {
            formData.append('name', assetName);
            formData.append('category', selectedCategory);
            formData.append('type', type);
        } else if (uploadPurpose === 'attach') {
            formData.append('category', 'general');
            formData.append('type', 'file');
        }


        setUploading(true);
        setUploadSuccess(false);
        setUploadError(false);

        try {
            const response = await fetch('https://api.neocu.com/api/image/upload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                setSelectedFileState(null);
                setSelectedFileNameState('');
                setSelectedCategory('');
                setUploading(false);
                setUploadSuccess(true);

                if (uploadPurpose === 'tabcontent') {
                    setSelectedCategory('');
                    setSelectedFileNameState('');
                    setLastUploadedFileInfo(data);
                } else if (uploadPurpose === 'attach') {
                    setAttachedFileInfos(prevInfos => [...prevInfos, data.data]);
                }


            } else {
                setUploadError(true);
                console.error('Upload failed:', response.statusText);
            }
        } catch (error) {
            setUploadError(true);
            console.error('Error during upload:', error);
        } finally {
            setUploading(false);
            setTimeout(() => setUploadSuccess(false), 3000);
        }
    };


    const handleRemoveAttachedFile = (fileInfoToRemove) => {
        setAttachedFileInfos(prevInfos => prevInfos.filter(info => info._id !== fileInfoToRemove._id));
    };

    const handleAttachAsset = (assetInfo) => {
        if (!assetInfo) {
            console.error('Asset info is undefined, cannot attach.');
            alert('Failed to attach asset.');
            return;
        }
        setAttachedFileInfos(prevInfos => {
            if (prevInfos.some(info => info._id === assetInfo._id)) {
                return prevInfos;
            }
            return [...prevInfos, assetInfo];
        });
    };


    const fetchSuggestions = useCallback(async () => {
        if (!userId) return;
        try {
            const response = await fetch(`https://api.neocu.com/api/highlight?user_id=${userId}&category=suggestions&status=pending&limit=5`);
            if (response.ok) {
                const data = await response.json();
                // setSuggestions(data.taskList); // No suggestions anymore
            } else {
                console.error('Failed to fetch suggestions:', response.status);
                // setSuggestions([]);
            }
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            // setSuggestions([]);
        }
    }, [userId]);


    const fetchImages = useCallback(async (category, currentSearchQuery = '', currentPage = 1) => {
        return () => { };
    }, []);

    const fetchTasks = useCallback(async () => {
        return () => { };
    }, []);

    const pollSandboxImages = useCallback(async () => {
        return () => { };
    }, []);


    const closeSignInModal = () => {
        setShowSignInModal(false);
    };


    const handleShare = (itemId) => {
    };


    const [assetSettingsModalVisible, setAssetSettingsModalVisible] = useState(false);
    const [selectedAssetForSettings, setSelectedAssetForSettings] = useState(null);
    const [assetBeingPlayed, setAssetBeingPlayed] = useState(null);
    const audioRef = useRef(null);
    const videoRef = useRef(null);
    const video3DRef = useRef(null);


    const handleGearClick = (e, item) => {
        e.stopPropagation();
        setSelectedAssetForSettings(item);
        setAssetSettingsModalVisible(true);
    };

    const handleAssetSettingsModalOpen = (e, item) => {
        e.stopPropagation();
        setSelectedAssetForSettings(item);
        setAssetSettingsModalVisible(true);
    };

    const handleAssetSettingsModalClose = () => {
        setAssetSettingsModalVisible(false);
        setSelectedAssetForSettings(null);
        stopPlayback();
    };

    const handleAssetSettingsSave = async (updatedName, updatedPrivacy) => {
        if (!selectedAssetForSettings) return;
    };

    const handlePlayMedia = (item) => {
        handleExpandModalOpen(item);
        setAssetBeingPlayed(item);
    };

    const stopPlayback = () => {
        setAssetBeingPlayed(null);
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
        if (video3DRef.current) {
            video3DRef.current.pause();
            video3DRef.current.currentTime = 0;
        }
    };

    const handleRoomsToggle = () => {
        setRoomsState(!roomsState);
    };

    const [taskId, setTaskId] = useState(null);
    const [taskStatus, setTaskStatus] = useState('pending');
    const [taskMessages, setTaskMessages] = useState([]);
    const [taskAssets, setTaskAssets] = useState([]);
    const pollingIntervalRef = useRef(null);
    const [isMessageExpanded, setIsMessageExpanded] = useState(false);
    const [activeMessageIndex, setActiveMessageIndex] = useState(-1);
    const [hasInitialGeneratedMessage, setHasInitialGeneratedMessage] = useState(false);
    const [suggestions, setSuggestions] = useState([]); // No suggestions anymore
    const [clickedSuggestionId, setClickedSuggestionId] = useState(null); // No suggestions anymore
    const [suggestionPopoverText, setSuggestionPopoverText] = useState(null); // No suggestions anymore
    const [suggestionPopoverVisible, setSuggestionPopoverVisible] = useState(false); // No suggestions anymore
    const [suggestionPopoverPosition, setSuggestionPopoverPosition] = useState({ top: 0, left: 0 }); // No suggestions anymore
    const [isLoading, setIsLoading] = useState(true);
    const [copiedMessageVisible, setCopiedMessageVisible] = useState(false); // FIX: Initialize to false
    const [isOnboarding, setIsOnboarding] = useState(false);
    const [mapAsset, setMapAsset] = useState(null);
    const [isMapLoading, setIsMapLoading] = useState(false);
    const [asset360, setAsset360] = useState(null);
    const [is360Loading, setIs360Loading] = useState(false);
    const [appAsset, setAppAsset] = useState(null);
    const [isAppLoading, setIsAppLoading] = useState(false);
    const [isHistoryDropdownVisible, setIsHistoryDropdownVisible] = useState(false);
    const [typingMessageIndex, setTypingMessageIndex] = useState(-1);
    const defaultMapEmbedUrl = "https://embed.neocu.com/map/3434";
    const [homeMapEmbedUrl, setHomeMapEmbedUrl] = useState(defaultMapEmbedUrl);
    const homeMapIframeRef = useRef(null);
    const [embedType, setEmbedType] = useState('map');
    const [personalizeData, setPersonalizeData] = useState(null);

    // Audio Reports State and Refs for Home Component
    const [isPlayingConsumerAudioHome, setIsPlayingConsumerAudioHome] = useState(false);
    const [isPlayingBusinessAudioHome, setIsPlayingBusinessAudioHome] = useState(false);
    const consumerAudioRefHome = useRef(null);
    const businessAudioRefHome = useRef(null);
    const [isPlayingCrimeReportHome, setIsPlayingCrimeReportHome] = useState(false);
    const [isPlayingLiveReportHome, setIsPlayingLiveReportHome] = useState(false);
    const [isPlayingRevenueReportHome, setIsPlayingRevenueReportHome] = useState(false);
    const [isPlayingEventsReportHome, setIsPlayingEventsReportHome] = useState(false);

    // New States for 3D/2D and Live Mode Switches
    const [is3DReport, setIs3DReport] = useState(true); // Default to 3D
    const [isLiveMode, setIsLiveMode] = useState(false);
    const [liveButtonHover, setLiveButtonHover] = useState(false);
    const [report3DButtonHover, setReport3DButtonHover] = useState(false);


    const startTaskPolling = useCallback((currentTaskId) => { // Removed polling logic
        return () => {};
    }, []);


    useEffect(() => {
        return () => {
            if (pollingIntervalRef.current) {
                clearInterval(pollingIntervalRef.current);
            }
            // Pause audio when Home component unmounts
            if (consumerAudioRefHome.current) {
                consumerAudioRefHome.current.pause();
            }
            if (businessAudioRefHome.current) {
                businessAudioRefHome.current.pause();
            }
        };
    }, []);

    useEffect(() => {
        if (isSignedIn) {
            fetchSuggestions();
        } else {
            // setSuggestions([]); // No suggestions anymore
        }
    }, [userId, isSignedIn, fetchSuggestions]);

    useEffect(() => {
        const fetchGreetingAndOnboarding = async () => {
            console.log("fetchGreetingAndOnboarding called. isSignedIn:", isSignedIn, "userId:", userId);

            if (isSignedIn) {
                console.log("User is signed in, fetching greeting.");
                try {
                    const greetingResponse = await fetch(`https://api.neocu.com/api/highlight?user_id=${userId}&category=greeting&status=pending&count=1`);
                    if (greetingResponse.ok) {
                        const greetingData = await greetingResponse.json();
                        let greetingText = greetingData.taskList[0]?.text || `${capitalizeFirstLetter(user?.fullName || user?.firstName || user?.username || "Account")}, How can I help you?`;
                        const currentFirstName = capitalizeFirstLetter(user?.fullName || user?.firstName || user?.username || "Account");
                        greetingText = greetingText.replace("<firstName>", currentFirstName);
                        // setGreetingText(greetingText); // Removed Greeting Message
                        // setFullHeadingText(greetingText); // Removed Greeting Message
                        // setHeadingText(""); // Removed Greeting Message
                    } else {
                        console.error('Failed to fetch greeting:', greetingResponse.status);
                        // const defaultGreeting = `${capitalizeFirstLetter(user?.fullName || user?.firstName || user?.username || "Account")}, How can I help you?`; // Removed Greeting Message
                        // setGreetingText(defaultGreeting); // Removed Greeting Message
                        // setFullHeadingText(defaultGreeting); // Removed Greeting Message
                        // setHeadingText(defaultGreeting); // Removed Greeting Message
                    }
                } catch (error) {
                    console.error('Error fetching greeting:', error);
                    // const defaultGreeting = `${capitalizeFirstLetter(user?.fullName || user?.firstName || user?.username || "Account")}, How can I help you?`; // Removed Greeting Message
                        // setGreetingText(defaultGreeting); // Removed Greeting Message
                        // setFullHeadingText(defaultGreeting); // Removed Greeting Message
                        // setHeadingText(defaultGreeting); // Removed Greeting Message
                    } finally {
                        setIsLoading(false);
                    }

                    try {
                        const consumerResponse = await fetch(`https://api.neocu.com/api/consumer/${userId}`);
                        if (consumerResponse.ok) {
                            const consumerData = await consumerResponse.json();
                            if (consumerData.data && consumerData.data.onboard === false) {
                                setIsOnboarding(true);
                            }
                        } else {
                            console.error('Failed to fetch consumer data for onboarding check:', consumerResponse.status);
                        }
                    } catch (error) {
                        console.error('Error checking onboarding status:', error);
                    }
                }
            };

            const fetchPersonalizeData = async () => {
                console.log("fetchPersonalizeData called. isSignedIn:", isSignedIn);
                try {
                    const ipResponse = await fetch('https://api.ipify.org?format=json');
                    if (!ipResponse.ok) {
                        console.error('Failed to fetch IP address:', ipResponse.status);
                        // setFullHeadingText("Your Personal Designer"); // Removed Greeting Message
                        // setHeadingText("Your Personal Designer"); // Removed Greeting Message
                        setIsLoading(false);
                        return;
                    }
                    const ipData = await ipResponse.json();
                    const ipAddress = ipData.ip;

                    console.log("Fetched IP Address:", ipAddress);

                    const personalizeResponse = await fetch(`https://api.neocu.com/api/block/personalize?ip=${ipAddress}`);
                    console.log("Personalize API Request sent to:", `https://api.neocu.com/api/block/personalize?ip=${ipAddress}`);
                    if (!personalizeResponse.ok) {
                        console.error('Failed to fetch personalization data:', personalizeResponse.status);
                        // setFullHeadingText("Your Personal Designer"); // Removed Greeting Message
                        // setHeadingText("Your Personal Designer"); // Removed Greeting Message
                        setIsLoading(false);
                        return;
                    }
                    const personalizeDataResponse = await personalizeResponse.json();
                    console.log("Personalize API Response:", personalizeDataResponse);
                    if (personalizeDataResponse.isSuccess && personalizeDataResponse.data) {
                        setLocationData(personalizeDataResponse.data);
                        setDynamicMapEmbedUrl(personalizeDataResponse.data.map);
                        setHomeMapEmbedUrl(personalizeDataResponse.data.map);
                        setPersonalizeData(personalizeDataResponse.data); // Store personalizeData
                        const cities = personalizeDataResponse.data.surroundingCities || [];
                        // setDynamicTitleParts([ // Removed Greeting Message
                        //     personalizeDataResponse.data.city, // Removed Greeting Message
                        //     personalizeDataResponse.data.region, // Removed Greeting Message
                        //     personalizeDataResponse.data.country, // Removed Greeting Message
                        //     "the World", // Removed Greeting Message
                        //     ...cities // Include surrounding cities in dynamic titles // Removed Greeting Message
                        // ]); // Removed Greeting Message
                    } else {
                        console.error('Personalize API returned unsuccessful response:', personalizeDataResponse);
                    }

                    // setFullHeadingText("Your Personal Designer"); // Removed Greeting Message
                    // setHeadingText("Your Personal Designer"); // Removed Greeting Message


                } catch (error) {
                    console.error('Error fetching IP or personalization data:', error);
                    // setFullHeadingText("Your Personal Designer"); // Removed Greeting Message
                    // setHeadingText("Your Personal Designer"); // Removed Greeting Message
                } finally {
                    setIsLoading(false);
                }
                setIsOnboarding(false);
            };


            if (isSignedIn) {
                fetchGreetingAndOnboarding();
            } else {
                fetchPersonalizeData();
            }


        }, [userId, isSignedIn, user, navigate]);


    const handleHomeSendQuery = async (query, setHeaderSendButtonState, setHeaderQuery, setHeaderAutocompleteSuggestions, setHeaderShowAutocompleteDropdown, setHeaderIsInputFocused, setHeaderAttachedFileInfos, sendButtonTimer, setHomeMapEmbedUrlFromHeader) => {
        console.log("handleHomeSendQuery in Home.js called", { query }); // ADDED - Still here but not used. Logic moved to Header.js
    };


    const resetTaskResponse = () => {
        setTaskAssets([]);
        setTaskMessages([]);
        setTaskId(null);
        setTaskStatus('pending');
        setHasInitialGeneratedMessage(false);
        setIsMessageExpanded(false);
        setActiveMessageIndex(-1);
        setMapAsset(null);
        setIsMapLoading(false);
        setAsset360(null);
        setIs360Loading(false);
        setAppAsset(null);
        setIsAppLoading(false);
        setIsHistoryDropdownVisible(false);
        setEmbedType('map');
        setIsPlayingConsumerAudioHome(false); // Reset audio states on reset
        setIsPlayingBusinessAudioHome(false); // Reset audio states on reset
        setIsPlayingCrimeReportHome(false);
        setIsPlayingLiveReportHome(false);
        setIsPlayingRevenueReportHome(false);
        setIsPlayingEventsReportHome(false);
        if (consumerAudioRefHome.current) consumerAudioRefHome.current.pause();
        if (businessAudioRefHome.current) businessAudioRefHome.current.pause();

        if (isSignedIn && userId) {
            fetchSuggestions();
        } else {
            // setSuggestions([]); // No suggestions anymore
        }
    };

    const handleDownloadAllAssets = () => {
        if (taskAssets && taskAssets.length > 0) {
            const zip = new JSZip();
            const assetsFolder = zip.folder("assets");
            const filePromises = [];

            taskAssets.forEach(asset => {
                const promise = new Promise(async (resolve) => {
                    try {
                        const response = await fetch(asset.file_url);
                        if (!response.ok) {
                            console.error(`Failed to fetch asset: ${asset.name} - Status: ${response.status}`);
                            resolve();
                            return;
                        }
                        const blob = await response.blob();

                        let fileExtension = '';

                        if (blob.type) {
                            const contentType = blob.type;
                            if (contentType === 'image/png') fileExtension = 'png';
                            else if (contentType === 'image/jpeg' || contentType === 'image/jpg') fileExtension = 'jpg';
                            else if (contentType === 'audio/mpeg') fileExtension = 'mp3';
                            else if (contentType === 'video/mp4') fileExtension = 'mp4';
                        }

                        const fileNameInZip = fileExtension ? `${asset.name}.${fileExtension}` : `${asset.name}.dat`;

                        assetsFolder.file(fileNameInZip, blob);

                        console.log(`Added asset to zip: ${asset.name}, type: ${blob.type}, size: ${blob.size}, extension: ${fileExtension || 'dat'}, fileNameInZip: ${fileNameInZip}`);


                    } catch (error) {
                        console.error(`Error processing asset: ${asset.name}`, error);
                    } finally {
                        resolve();
                    }
                });
                filePromises.push(promise);
            });

            Promise.all(filePromises).then(() => {
                zip.generateAsync({ type: "blob" }).then(content => {
                    saveAs(content, "neocu_assets.zip");
                });
            });
        }
    };

    const handleShareIconClick = async () => {
        if (taskId) {
            const shareLink = `${window.location.origin}/share/${taskId}`;
            try {
                await navigator.clipboard.writeText(shareLink);
                setCopiedMessageVisible(true);
                setTimeout(() => setCopiedMessageVisible(false), 3000);
            } catch (err) {
                console.error('Failed to copy share link:', err);
            }
        }
    };

    const toggleHistoryDropdown = () => {
        setIsHistoryDropdownVisible(!isHistoryDropdownVisible);
        console.log("History dropdown toggled, isHistoryDropdownVisible:", !isHistoryDropdownVisible);
    };

    const closeHistoryDropdown = () => {
        setIsHistoryDropdownVisible(false);
        console.log("History dropdown closed, isHistoryDropdownVisible:", false);
    };


    const [locationData, setLocationData] = useState(null);
    const [dynamicTitleParts, setDynamicTitleParts] = useState([]);
    const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
    const [dynamicMapEmbedUrl, setDynamicMapEmbedUrl] = useState('');

    const staticHeadingPrefixes = [
        "3D Simulation of ",
        "Live Map of",
        "Manufacture for",
        "AI Video Tour of",
        "3D Generation of",
        "Reimagine",
        "Predict the Future of",
        "History of",
        "Optimize Sales from"
    ];

    const lastTitleIndex = useRef(0);


    useEffect(() => {
        if (locationData) {
            const cities = locationData.surroundingCities || [];
            setDynamicTitleParts([
                locationData.city,
                locationData.region,
                locationData.country,
                "the World",
                locationData.city,
                locationData.region,
                locationData.country,
                "the World",
                ...cities
            ]);
        } else {
            setDynamicTitleParts(["the World"]);
        }
    }, [locationData]);


    const handleSuggestionClick = async (suggestion) => {
        // setQuery(suggestion.text); // Query now handled in Header
        setClickedSuggestionId(suggestion._id);
        // handleSend(); // Send now handled in Header
    };

    const handleSuggestionMouseOver = (event, suggestion) => {
        console.log("Suggestion reason:", suggestion.reason);
        setSuggestionPopoverText(suggestion.reason);
        setSuggestionPopoverVisible(true);
        const suggestionItemRect = event.currentTarget.getBoundingClientRect();
        setSuggestionPopoverPosition({
            top: suggestionItemRect.top - 35,
            left: suggestionItemRect.left + (suggestionItemRect.width / 2) - 75
        });
    };

    const handleSuggestionMouseOut = () => {
        setSuggestionPopoverVisible(false);
    };

    // Audio Report Handlers for Home Component
    const handleConsumerAudioToggleHome = () => {
        if (consumerAudioRefHome.current) {
            if (isPlayingConsumerAudioHome) {
                consumerAudioRefHome.current.pause();
                setIsPlayingConsumerAudioHome(false);
            } else {
                if (isPlayingBusinessAudioHome && businessAudioRefHome.current) {
                    businessAudioRefHome.current.pause();
                    setIsPlayingBusinessAudioHome(false);
                }
                consumerAudioRefHome.current.play();
                setIsPlayingConsumerAudioHome(true);
            }
        }
    };

    const handleBusinessAudioToggleHome = () => {
        if (businessAudioRefHome.current) {
            if (isPlayingBusinessAudioHome) {
                businessAudioRefHome.current.pause();
                setIsPlayingBusinessAudioHome(false);
            } else {
                if (isPlayingConsumerAudioHome && consumerAudioRefHome.current) {
                    consumerAudioRefHome.current.pause();
                    setIsPlayingConsumerAudioHome(false);
                }
                businessAudioRefHome.current.play();
                setIsPlayingBusinessAudioHome(true);
            }
        }
    };

    // New Report Buttons Handlers
    const handleCrimeReportClickHome = () => {
        setIsPlayingCrimeReportHome(!isPlayingCrimeReportHome);
        setIsPlayingLiveReportHome(false);
        setIsPlayingRevenueReportHome(false);
        setIsPlayingEventsReportHome(false);
        setIsPlayingConsumerAudioHome(false);
        setIsPlayingBusinessAudioHome(false);
        if (consumerAudioRefHome.current) consumerAudioRefHome.current.pause();
        if (businessAudioRefHome.current) businessAudioRefHome.current.pause();
    };

    const handleLiveReportClickHome = () => {
        setIsPlayingLiveReportHome(!isPlayingLiveReportHome);
        setIsPlayingCrimeReportHome(false);
        setIsPlayingRevenueReportHome(false);
        setIsPlayingEventsReportHome(false);
        setIsPlayingConsumerAudioHome(false);
        setIsPlayingBusinessAudioHome(false);
        setIsLiveMode(!isLiveMode);
        if (consumerAudioRefHome.current) consumerAudioRefHome.current.pause();
        if (businessAudioRefHome.current) businessAudioRefHome.current.pause();
    };

    const handleRevenueReportClickHome = () => {
        setIsPlayingRevenueReportHome(!isPlayingRevenueReportHome);
        setIsPlayingLiveReportHome(false);
        setIsPlayingCrimeReportHome(false);
        setIsPlayingEventsReportHome(false);
        setIsPlayingConsumerAudioHome(false);
        setIsPlayingBusinessAudioHome(false);
        if (consumerAudioRefHome.current) consumerAudioRefHome.current.pause();
        if (businessAudioRefHome.current) businessAudioRefHome.current.pause();
    };

    const handleEventsReportClickHome = () => {
        setIsPlayingEventsReportHome(!isPlayingEventsReportHome);
        setIsPlayingLiveReportHome(false);
        setIsPlayingCrimeReportHome(false);
        setIsPlayingRevenueReportHome(false);
        setIsPlayingConsumerAudioHome(false);
        setIsPlayingBusinessAudioHome(false);
        if (consumerAudioRefHome.current) consumerAudioRefHome.current.pause();
        if (businessAudioRefHome.current) businessAudioRefHome.current.pause();
    };

    // New Handler for 3D/2D Report Button
    const handle3DReportClickHome = () => {
        setIs3DReport(!is3DReport);
    };

    const handleRefreshEmbed = () => {
        setHomeMapEmbedUrl(prevUrl => prevUrl + "?r=" + Math.random());
    };

    const handleShareEmbed = async () => {
        if (homeMapEmbedUrl) {
            try {
                await navigator.clipboard.writeText(homeMapEmbedUrl);
                setCopiedMessageVisible(true);
                setTimeout(() => setCopiedMessageVisible(false), 3000);
            } catch (err) {
                console.error('Failed to copy embed link:', err);
            }
        }
    };


    useEffect(() => {
        const consumerAudioPlayerHome = consumerAudioRefHome.current;
        const businessAudioPlayerHome = businessAudioRefHome.current;

        const handleConsumerAudioEndHome = () => {
            setIsPlayingConsumerAudioHome(false);
        };
        const handleBusinessAudioEndHome = () => {
            setIsPlayingBusinessAudioHome(false);
        };

        if (consumerAudioPlayerHome) {
            consumerAudioPlayerHome.addEventListener('ended', handleConsumerAudioEndHome);
        }
        if (businessAudioPlayerHome) {
            businessAudioPlayerHome.addEventListener('ended', handleBusinessAudioEndHome);
        }

        return () => {
            if (consumerAudioPlayerHome) {
                consumerAudioPlayerHome.removeEventListener('ended', handleConsumerAudioEndHome);
            }
            if (businessAudioPlayerHome) {
                businessAudioPlayerHome.removeEventListener('ended', handleBusinessAudioEndHome);
            }
        };
    }, []);


    useEffect(() => {
        if (taskAssets) {
            const foundMapAsset = taskAssets.find(asset => asset.media_type === 'map');
            const found360Asset = taskAssets.find(asset => asset.media_type === '360');
            const foundGameAsset = taskAssets.find(asset => asset.media_type === 'game');
            const found3dSimAsset = taskAssets.find(asset => asset.media_type === '3d_sim');
            let embedUrl = dynamicMapEmbedUrl || defaultMapEmbedUrl;
            let newEmbedUrl = embedUrl + (is3DReport ? 'shape=3d' : '') + (isLiveMode ? '&live=true' : '');


            if (foundMapAsset) {
                newEmbedUrl = foundMapAsset.embed + (is3DReport ? '&shape=3d' : '') + (isLiveMode ? '&live=true' : '');
                setEmbedType('map');
            } else if (found360Asset) {
                newEmbedUrl = found360Asset.embed + (!is3DReport ? '&shape=2d' : '') + (isLiveMode ? '&live=true' : '');
                setEmbedType('360');
            } else if (foundGameAsset) {
                newEmbedUrl = foundGameAsset.embed + (!is3DReport ? '&shape=2d' : '') + (isLiveMode ? '&live=true' : '');
                setEmbedType('game');
            } else if (found3dSimAsset) {
                newEmbedUrl = found3dSimAsset.embed + (!is3DReport ? '&shape=2d' : '') + (isLiveMode ? '&live=true' : '');
                setEmbedType('3d_sim');
            } else {
                setEmbedType('map'); // Default to map if no special embed type
            }
            setHomeMapEmbedUrl(newEmbedUrl);


            setMapAsset(foundMapAsset || null);
            setAsset360(found360Asset || null);
            setAppAsset(foundGameAsset || found3dSimAsset || null); // Reusing appAsset for game and 3d_sim
            setIsMapLoading(false);
            setIs360Loading(false);
            setIsAppLoading(false);


        }
    }, [taskAssets, dynamicMapEmbedUrl, defaultMapEmbedUrl, is3DReport, isLiveMode]);


    const handleExpandMap = () => {
        if (mapAsset && mapAsset.embed) {
            window.open(mapAsset.embed + (!is3DReport ? '&shape=2d' : '') + (isLiveMode ? '&live=true' : ''), '_blank');
        }
    };

    const handleExpand360 = () => {
        if (asset360 && asset360.embed) {
            window.open(asset360.embed + (!is3DReport ? '&shape=2d' : '') + (isLiveMode ? '&live=true' : ''), '_blank');
        }
    };

    const handleExpandApp = () => {
        if (appAsset && appAsset.embed) {
            window.open(appAsset.embed + (!is3DReport ? '&shape=2d' : '') + (isLiveMode ? '&live=true' : ''), '_blank');
        }
    };

    const handleExitEmbed = () => {
        let embedUrl = dynamicMapEmbedUrl || defaultMapEmbedUrl;
        if (!is3DReport) embedUrl += '&shape=2d'; // Send 2d if 3D report is off
        if (isLiveMode) embedUrl += '&live=true';
        setHomeMapEmbedUrl(embedUrl);
        setEmbedType('map'); // Revert to map view
    };

    useEffect(() => {
        console.log("homeMapEmbedUrl in Home.js changed:", homeMapEmbedUrl); // Debugging useEffect
    }, [homeMapEmbedUrl]);


    // Define onFileDragLeave here, it was likely missing or not correctly passed.
    const onFileDragLeave = (event) => {
        event.preventDefault();
        setIsDragging(false);
    };


    return (
        isLoading ? (
            <div className="loading-screen">
                <div className="custom-loader loading-spinner"></div>
            </div>
        ) : isOnboarding ? (
            <Onboard userId={userId} setIsOnboarding={setIsOnboarding} />
        ) : (
            <div className={`home-container-home ${isSignedIn ? 'signed-in' : ''}`} onClick={() => { setShowDropdown(false); setUploadModalVisible(false); setAssetSettingsModalVisible(false); handleExpandModalClose(); stopPlayback(); setSuggestionPopoverVisible(false); handleExpandModalClose(); setIsHistoryDropdownVisible(false);  }}>

                <Header
                    isComputeModalOpen={false} // Example values, adjust as needed
                    openComputeModal={openComputeModal}
                    closeComputeModal={() => {}} // Example no-op close function
                    attachedFileInfos={attachedFileInfos}
                    setAttachedFileInfos={setAttachedFileInfos}
                    setHomeMapEmbedUrl={setHomeMapEmbedUrl} // Pass setHomeMapEmbedUrl here
                />
                {/* New container to wrap content below the header */}
                <div className="home-content-container">
                    {isSignedIn && ( // Conditionally render the entire reports-container when signed in
                        <div className="reports-container"> {/* Reports Container is here */}

                            {(personalizeData && personalizeData.consumer_audio) && (
                                <div className="audio-report-button-container">
                                    <button className="report-button light-blue-button" onClick={handleConsumerAudioToggleHome}>
                                        {isPlayingConsumerAudioHome ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />} Local Report
                                    </button>
                                    <audio ref={consumerAudioRefHome} src={personalizeData.consumer_audio} />
                                </div>
                            )}
                            {(personalizeData && personalizeData.business_audio) && (
                                <div className="audio-report-button-container">
                                    <button className="report-button light-blue-button" onClick={handleBusinessAudioToggleHome}>
                                        {isPlayingBusinessAudioHome ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />} Business Report
                                    </button>
                                    <audio ref={businessAudioRefHome} src={personalizeData.business_audio} />
                                </div>
                            )}


                            <>
                                <button
                                    className={`report-button new-report-button live-report-button ${isLiveMode ? 'report-button-on' : 'report-button-off'}`}
                                    onClick={handleLiveReportClickHome}
                                    onMouseEnter={() => setLiveButtonHover(true)}
                                    onMouseLeave={() => setLiveButtonHover(false)}
                                >
                                    Live
                                    <span className="report-button-spacer"></span>
                                    {isLiveMode ? (
                                        <FaCheck className="report-button-icon" />
                                    ) : liveButtonHover ? (
                                        <FaCheck className="report-button-icon" />
                                    ) : (
                                        <FaXmark className="report-button-icon" />
                                    )}
                                </button>

                                <button
                                    className={`report-button new-report-button report-3d-button ${is3DReport ? 'report-button-on' : 'report-button-off'}`}
                                    onClick={handle3DReportClickHome}
                                    onMouseEnter={() => setReport3DButtonHover(true)}
                                    onMouseLeave={() => setReport3DButtonHover(false)}
                                >
                                    {is3DReport ? '3D' : '2D'}
                                    <span className="report-button-spacer"></span>
                                    {is3DReport ? (
                                        <FaCheck className="report-button-icon" />
                                    ) : report3DButtonHover ? (
                                        <FaCheck className="report-button-icon" />
                                    ) : (
                                        <FaXmark className="report-button-icon" />
                                    )}
                                </button>

                                <button className="report-button new-report-button" onClick={handleRevenueReportClickHome} style={{ backgroundColor: '#171717', color: '#fff' }}>
                                    Revenue
                                </button>
                                <button className="report-button new-report-button" onClick={handleEventsReportClickHome} style={{ backgroundColor: '#171717', color: '#fff' }}>
                                    Events
                                </button>

                                <button className="report-button icon-only-button" onClick={() => { }} style={{ backgroundColor: '#171717', color: '#fff' }}><FaGear /></button>
                                <button className="report-button icon-only-button" onClick={handleRefreshEmbed} style={{ backgroundColor: '#171717', color: '#fff' }}><FaRotateRight /></button>
                                <button className="report-button icon-only-button" onClick={handleShareEmbed} style={{ backgroundColor: '#171717', color: '#fff' }}><FaLink /></button>
                            </>

                        </div>
                    )}


                    {attachedFileInfos.length > 0 && (
                        <div className="attached-files-container">
                            {attachedFileInfos.map((fileInfo, index) => (
                                <AttachedFileThumbnail
                                    key={fileInfo._id}
                                    fileInfo={fileInfo}
                                    onRemove={handleRemoveAttachedFile}
                                    handleVideoModalOpen={handleExpandModalOpen}
                                />
                            ))}
                        </div>
                    )}

                    {/* Embed Section - Conditionally render based on embedType */}
                    {isSignedIn && embedType === 'map' && (
                        <div className="home-embed-section">
                            <div className="home-embed-container">
                                <iframe
                                    ref={homeMapIframeRef}
                                    title="Personalized Map Embed"
                                    width="100%"
                                    height="70vh" // Adjusted height to 70vh
                                    src={homeMapEmbedUrl || defaultMapEmbedUrl}
                                    frameBorder="0"
                                    allowFullScreen
                                    key={homeMapEmbedUrl} // Added key prop to force iframe re-render
                                ></iframe>
                            </div>
                        </div>
                    )}
                    {isSignedIn && embedType === '360' && asset360 && (
                        <div className="home-embed-section embed-360-container">
                            <div className="home-embed-container">
                                <iframe
                                    title="360 Embed"
                                    width="100%"
                                    height="70vh" // Adjusted height for better 360 view to 70vh
                                    src={homeMapEmbedUrl} // Using homeMapEmbedUrl which is now set to 360 embed
                                    frameBorder="0"
                                    allowFullScreen
                                    key={homeMapEmbedUrl} // Added key prop to force iframe re-render
                                ></iframe>
                                <button className="exit-embed-button" onClick={handleExitEmbed}><FaXmark /></button>
                            </div>
                        </div>
                    )}
                    {isSignedIn && embedType === 'game' && appAsset && (
                        <div className="home-embed-section embed-game-container">
                            <div className="home-embed-container">
                                <iframe
                                    title="Game Embed"
                                    width="100%"
                                    height="70vh" // Adjusted height for game view to 70vh
                                    src={homeMapEmbedUrl} // Using homeMapEmbedUrl which is now set to game embed
                                    frameBorder="0"
                                    allowFullScreen
                                    key={homeMapEmbedUrl} // Added key prop to force iframe re-render
                                ></iframe>
                                <button className="exit-embed-button" onClick={handleExitEmbed}><FaXmark /></button>
                            </div>
                        </div>
                    )}
                    {isSignedIn && embedType === '3d_sim' && appAsset && (
                        <div className="home-embed-section embed-3dsim-container">
                            <div className="home-embed-container">
                                <iframe
                                    title="3D Sim Embed"
                                    width="100%"
                                    height="70vh" // Adjusted height for 3d sim view to 70vh
                                    src={homeMapEmbedUrl} // Using homeMapEmbedUrl which is now set to 3d sim embed
                                    frameBorder="0"
                                    allowFullScreen
                                    key={homeMapEmbedUrl} // Added key prop to force iframe re-render
                                ></iframe>
                                <button className="exit-embed-button" onClick={handleExitEmbed}><FaXmark /></button>
                            </div>
                        </div>
                    )}


                    {copiedMessageVisible && <div className="copied-message visible">Embed URL Copied!</div>}


                    {!isSignedIn && (
                        <VisitorContent dynamicMapEmbedUrl={dynamicMapEmbedUrl || defaultMapEmbedUrl} personalizeData={personalizeData} />
                    )}


                    {showSignInModal && (
                        <SignInModal onClose={closeSignInModal} />
                    )}


                    {uploadModalVisible && (
                        <UploadModal
                            onClose={handleUploadModalClose}
                            uploadType={uploadType}
                            onUploadTypeChange={handleUploadTypeChange}
                            onFileSelect={handleFileSelect}
                            onFileDrop={handleFileDrop}
                            onFileDragOver={handleFileDragOver}
                            onFileDragLeave={onFileDragLeave} // Ensure onFileDragLeave is passed correctly
                            onCategoryChange={handleCategoryChange}
                            onUpload={handleUpload}
                            selectedFileName={selectedFileNameState}
                            selectedCategory={selectedCategory}
                            uploading={uploading}
                            uploadSuccess={uploadSuccess}
                            uploadError={uploadError}
                            isDragging={isDragging}
                            selectedFile={selectedFileState}
                            setLastUploadedFileId={setLastUploadedFileInfo}
                            lastUploadedFileId={lastUploadedFileInfo}
                            uploadPurpose={uploadPurpose}
                        />
                    )}


                    {assetSettingsModalVisible && selectedAssetForSettings && (
                        <AssetSettingsModal
                            asset={selectedAssetForSettings}
                            onClose={handleAssetSettingsModalClose}
                            onSave={handleAssetSettingsSave}
                            onShare={() => { }}
                            assetBeingPlayed={assetBeingPlayed}
                            onPlayMedia={handlePlayMedia}
                            stopPlayback={stopPlayback}
                            audioRef={audioRef}
                            videoRef={videoRef}
                            video3DRef={video3DRef}
                        />
                    )}


                    {isExpandedModalOpen && expandedModalAsset && (
                        <ExpandedAssetModal asset={expandedModalAsset} onClose={handleExpandModalClose} handleVideoModalOpen={handleExpandModalOpen} handleAudioModalOpen={handleExpandModalOpen} />
                    )}
                </div> {/* Closing home-content-container */}
            </div>
        )
    );
}


const TypingIndicator = () => (
    <div className="typing-indicator">
        <span className="ellipsis"></span>
        <span className="ellipsis"></span>
        <span className="ellipsis"></span>
    </div>
);


const ExpandedAssetModal = ({ asset, onClose, handleVideoModalOpen, handleAudioModalOpen }) => {
    return (
        <div className="expanded-modal-overlay" onClick={onClose}>
            <div className="expanded-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="expanded-modal-close-button" onClick={onClose}><FaXmark /></button>
                <h2 className="expanded-modal-title">{asset.name}</h2>

                <div className="expanded-asset-container">
                    {asset.media_type === 'image' ? (
                        <img src={asset.file_url} alt={asset.name} className="expanded-asset-media" />
                    ) : asset.media_type === 'video' || asset.media_type === '3d' ? (
                        <video src={asset.file_url} controls className="expanded-asset-media" autoPlay />
                    ) : (asset.media_type === 'sound' || asset.media_type === 'voice') ? (
                        <audio src={asset.file_url} controls className="expanded-asset-media" autoPlay />
                    ) : asset.media_type === 'pdf' ? (
                        <iframe src={asset.file_url} className="expanded-asset-media pdf-viewer" title={asset.name} />
                    ) : asset.media_type === 'presentation' ? (
                        <iframe
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(asset.file_url)}`}
                            className="expanded-asset-media powerpoint-viewer"
                            frameBorder="0"
                            title={asset.name}
                        ></iframe>
                    ) : asset.media_type === 'map' || asset.media_type === '360' || asset.media_type === 'app' || asset.media_type === 'game' || asset.media_type === '3d_sim' ? (
                        <iframe src={asset.embed} className="expanded-asset-media expanded-iframe" frameBorder="0" />
                    ) : (
                        <p>File preview not available.</p>
                    )}
                </div>
                <div className="expanded-modal-actions">
                    <a href={asset.file_url} download={asset.name} className="expanded-download-button">
                        <FaDownload /> Download
                    </a>
                </div>
            </div>
        </div>
    );
};


const AttachedFileThumbnail = ({ fileInfo, onRemove, handleVideoModalOpen }) => {

    if (!fileInfo) {
        return <div>Loading file info...</div>;
    }

    let thumbnailContent;
    if (fileInfo.category === 'PDF') {
        thumbnailContent = <FaFilePdf size={24} color="#F44336" />;
    } else if (fileInfo.category === 'CSV') {
        thumbnailContent = <FaFileCsv size={24} color="#4CAF50" />;
    } else if (fileInfo.media_type === 'image') {
        console.log("Thumbnail URL being used for image:", fileInfo.thumbnail);
        thumbnailContent = <img src={fileInfo.thumbnail} alt={fileInfo.name} className="attached-thumbnail-image" />;
    } else if (fileInfo.media_type === 'video' || fileInfo.media_type === 'presentation') {
        thumbnailContent = <img src={fileInfo.thumbnail || '/video_thumbnail_fallback.png'} alt={fileInfo.name} className="attached-thumbnail-image" />;
    } else if (fileInfo.media_type === '3d') {
        thumbnailContent = (
            <video
                src={fileInfo.thumbnail}
                className="attached-thumbnail-video"
                autoPlay
                loop
                muted
                onClick={() => handleVideoModalOpen(fileInfo.file_url)}
            />
        );
    }
    else if (fileInfo.media_type === 'sound' || fileInfo.media_type === 'voice') {
        thumbnailContent = <img src={fileInfo.thumbnail || '/audio_thumbnail_fallback.png'} alt={fileInfo.name} className="attached-thumbnail-image" />;
    }
    else {
        thumbnailContent = <div>{fileInfo.name}</div>;
    }


    return (
        <div className="attached-file-item">
            {thumbnailContent}
            <span className="attached-file-name" title={fileInfo.name}>{fileInfo.name}</span>
            <button className="remove-attached-file" onClick={() => onRemove(fileInfo)}><FaXmark /></button>
        </div>
    );
};


const UploadModal = ({ onClose, uploadType, onUploadTypeChange, onFileSelect, onFileDrop, onFileDragOver, onFileDragLeave, onCategoryChange, onUpload, selectedFileName, selectedCategory, uploading, uploadSuccess, uploadError, isDragging, selectedFile, setLastUploadedFileId, lastUploadedFileInfo, uploadPurpose }) => {
    const [assetName, setAssetName] = useState(selectedFileName);
    const [filePreviewUrl, setFilePreviewUrl] = useState(null);

    useEffect(() => {
        setAssetName(selectedFileName);
    }, [selectedFileName]);

    useEffect(() => {
        if (selectedFile) {
            console.log("Selected File in useEffect:", selectedFile);
            if (selectedFile && selectedFile.type && (selectedFile.type.startsWith('image/') || selectedFile.type.startsWith('video/') || selectedFile.type === 'model/gltf-binary')) {
                const url = URL.createObjectURL(selectedFile);
                setFilePreviewUrl(url);
                return () => URL.revokeObjectURL(url);
            } else {
                setFilePreviewUrl(null);
            }
        } else {
            setFilePreviewUrl(null);
        }
    }, [selectedFile]);


    const handleAssetNameChange = (e) => {
        setAssetName(e.target.value);
    };


    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="upload-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close-button" onClick={onClose}><FaXmark /></button>
                <h2 className="modal-title">{uploadPurpose === 'attach' ? 'Attach File' : 'Upload Asset'}</h2>

                <div className="upload-form">
                    {uploadPurpose === 'tabcontent' && (
                        <>
                            <div className="input-row">
                                <input
                                    type="text"
                                    className="upload-input asset-name-input"
                                    placeholder="Asset Name"
                                    value={assetName}
                                    onChange={handleAssetNameChange}
                                />

                                <select
                                    className="upload-select category-select"
                                    value={selectedCategory}
                                    onChange={(e) => onCategoryChange(e.target.value)}
                                >
                                    <option value="">Select Category</option>
                                    <option value="UI">UI</option>
                                    <option value="Sound">Sound</option>
                                    <option value="Voice">Voice</option>
                                    <option value="PDF">PDF</option>
                                    <option value="template">Template</option>
                                    <option value="community">Community</option>
                                    <option value="CSV">CSV</option>
                                    <option value="presentation">Presentation</option>
                                </select>
                            </div>
                        </>
                    )}


                    {filePreviewUrl && (
                        <div className="file-preview-container">
                            {selectedFile && selectedFile.type && selectedFile.type.startsWith('image/') ? (
                                <img src={filePreviewUrl} alt="File Preview" className="file-preview-image" />
                            ) : selectedFile && selectedFile.type && (selectedFile.type.startsWith('video/') || selectedFile.type === 'model/gltf-binary') ? (
                                <video src={filePreviewUrl} controls className="file-preview-video" />
                            ) : null}
                        </div>
                    )}


                    <div
                        className={`upload-drop-area ${isDragging ? 'dragging' : ''}`}
                        onDrop={onFileDrop}
                        onDragOver={onFileDragOver}
                        onFileDragLeave={onFileDragLeave}
                    >
                        <p>Drag and drop file here</p>
                        <input type="file" id="fileInput" className="file-input" onChange={onFileSelect} />
                        <label htmlFor="fileInput" className="file-input-label">Or browse files</label>
                        {selectedFile && <p className="selected-file-name">Selected file: {selectedFile.name}</p>}
                    </div>

                    {uploadError && <p className="upload-error">Upload failed. Please try again.</p>}
                    {uploadSuccess && <p className="upload-success">Upload successful!</p>}


                    <div className="modal-actions">
                        <button
                            className="modal-confirm-button"
                            onClick={() => onUpload({ assetName })}
                            disabled={uploading || !selectedFile || (uploadPurpose === 'tabcontent' && (!assetName || !selectedCategory))}
                        >
                            {uploading ? 'Uploading...' : 'Upload'}
                        </button>
                        <button className="modal-cancel-button" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};



const SignInModal = ({ onClose }) => {
    return (
        <div className="signin-modal-overlay" onClick={onClose}>
            <div className="signin-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="signin-modal-close-button" onClick={onClose}><FaXmark /></button>
                <h2 className="signin-modal-title">Sign in to continue</h2>
                <p className="signin-modal-subtitle">Create an account or sign in to send messages</p>
                <div className="signin-modal-buttons">
                    <Link to="/signin" className="signin-modal-signin-button">Sign In</Link>
                    <Link to="/signup" className="signin-modal-signup-button">Sign up for free</Link>
                </div>
            </div>
        </div>
    );
};


const AssetSettingsModal = ({ asset, onClose, onSave, onShare, assetBeingPlayed, onPlayMedia, stopPlayback, audioRef, videoRef, video3DRef }) => {
    const [assetName, setAssetName] = useState(asset.name);
    const [privacyCategory, setPrivacyCategory] = useState(asset.category);

    const handleSave = () => {
        onSave(assetName, privacyCategory);
    };

    const handlePlayButtonClick = () => {
        onPlayMedia(asset);
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="asset-settings-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close-button" onClick={onClose}><FaXmark /></button>
                <h2 className="modal-title">Asset Settings</h2>
                <div className="asset-preview">
                    {asset.media_type === 'image' ? (
                        <img src={asset.file_url} alt="Asset Preview" className="settings-asset-media" />
                    ) : asset.media_type === 'video' ? (
                        <video src={asset.file_url} className="settings-asset-media" controls ref={videoRef} />
                    ) : (asset.media_type === 'sound' || asset.media_type === 'voice') ? (
                        <div className="audio-preview-container">
                            <img src={asset.thumbnail || '/audio_thumbnail_fallback.png'} alt="Asset Preview" className="settings-asset-media audio-settings-media" />
                            <audio controls ref={audioRef} src={asset.file_url} className="settings-audio-element" />
                        </div>
                    ) : asset.media_type === '3d' ? (
                        <video
                            ref={video3DRef}
                            src={asset.thumbnail || '/video_thumbnail_fallback.png'}
                            className="settings-asset-media"
                            controls
                            loop
                        />
                    ) : asset.media_type === 'presentation' ? (
                        <iframe
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(asset.file_url)}`}
                            className="settings-asset-media powerpoint-viewer"
                            frameBorder="0"
                            title={asset.name}
                        ></iframe>
                    ) : null}
                </div>
                <div className="settings-form">
                    <div className="settings-input-group">
                        <label className="settings-label">Asset Name:</label>
                        <input type="text" className="upload-input settings-input" value={assetName} onChange={(e) => setAssetName(e.target.value)} />
                    </div>
                    <div className="settings-input-group">
                        <label className="settings-label">Privacy:</label>
                        <select className="upload-select settings-select" value={privacyCategory} onChange={(e) => setPrivacyCategory(e.target.value)}>
                            <option value="public">Public</option>
                            <option value="private">Private</option>
                            <option value="email">Email</option>
                        </select>
                    </div>
                </div>
                <div className="modal-actions">
                    <button className="modal-confirm-button" onClick={handleSave}>Save</button>
                    <button className="modal-cancel-button" onClick={onClose}>Close</button>
                    <button className="modal-share-button" onClick={onShare}>Share</button>
                </div>
            </div>
        </div>
    );
};


export default Home;
// Neo.js
import React, { useState, useEffect } from 'react';
import './Neo.css';
import NeoAsset from './NeoAsset'; // Import NeoAsset component
import { FaSearch, FaBed, FaMapPin, FaPaperclip, FaPhone, FaEnvelope, FaImage, FaVideo, FaVolumeUp, FaComment, FaBell } from 'react-icons/fa'; // Replaced FaQuestionCircle with FaBell
import { AiOutlinePaperClip, AiOutlineCamera } from 'react-icons/ai'; // Example additional icons
import { FiSend } from 'react-icons/fi';

function Neo() {
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [isAssetView, setIsAssetView] = useState(false); // State to manage asset view
  const [selectedTaskId, setSelectedTaskId] = useState(null); // State to store selected task ID

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSubmit = async () => {
    if (!query.trim()) return;
    setIsLoading(true);
    try {
      const response = await fetch('https://embed.axv.ai/api/block/agent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: query }),
      });

      if (response.ok) {
        console.log('Query submitted successfully');
        setQuery('');
      } else {
        console.error('Failed to submit query', response.status);
      }
    } catch (error) {
      console.error('Error submitting query:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await fetch('https://embed.axv.ai/api/task?mode=agent&limit=7');
      if (!response.ok) {
        console.error('Failed to fetch tasks', response.status);
        return;
      }
      const data = await response.json();
      if (data && data.taskList) {
        setTasks(data.taskList.slice(0, 7));
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  useEffect(() => {
    fetchTasks();

    const intervalId = setInterval(fetchTasks, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const iconMap = {
    image: FaImage,
    video: FaVideo,
    sound: FaVolumeUp,
    chat: FaComment,
    string: FaPaperclip,
  };

  const handleTaskIconClick = (taskId) => {
    setSelectedTaskId(taskId);
    setIsAssetView(true); // Show asset view
  };

  const handleAssetViewClose = () => {
    setIsAssetView(false); // Hide asset view and return to main view
    setSelectedTaskId(null); // Clear selected task ID
  };

  if (isAssetView && selectedTaskId) {
    return <NeoAsset taskId={selectedTaskId} onClose={handleAssetViewClose} />;
  }

  return (
    <div className="neo-container">
      <div className="neo-card">
        <div className="neo-bottom-icons">
          <div className="neo-notification-icon-container">
            <FaBell className="neo-bottom-icon" aria-label="Notifications" />
          </div>
        </div>
        <div className="neo-header">
          <span className="neo-brand">Neo</span>
          <p className="neo-title">How can I help? </p>
        </div>

        <div className="neo-chat-bot-container">
          <div className="neo-chat-options">
            <div className="neo-chat">
              <div className="neo-chat-bot">
                <textarea
                  placeholder="Ask a question..."
                  className="neo-chat-bot textarea"
                  value={query}
                  onChange={handleInputChange}
                />
              </div>
              <div className="neo-options">
                <div className="neo-btns-add">
                  <button aria-label="Add attachment">
                    <AiOutlinePaperClip />
                  </button>
                  <button aria-label="Add image">
                    <AiOutlineCamera />
                  </button>
                </div>
                <button
                  className="neo-btn-submit"
                  aria-label="Submit question"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="neo-loader"></div>
                  ) : (
                    <i>
                      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 12C2 12 7 3 13 3C19 3 22 12 22 12C22 12 19 21 13 21C7 21 2 12 2 12Z" stroke="#8B8B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M13 9V15" stroke="#8B8B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                      </svg>
                    </i>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="neo-tags">
          {tasks.map((task, index) => {
            const TaskIcon = iconMap[task.icon] || FaBell;
            const statusClass = task.status === 'generating' ? 'neo-task-generating' : task.status === 'completed' ? 'neo-task-completed' : '';

            return (
              <span
                key={index}
                className={`neo-task-item ${statusClass}`}
                title={task.name}
                onClick={() => handleTaskIconClick(task._id)} // Handle click to show assets
                style={{cursor: 'pointer'}} // Make it clear it's clickable
              >
                <TaskIcon />
              </span>
            );
          })}
        </div>

      </div>
    </div>
  );
}

export default Neo;
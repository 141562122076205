import React from 'react';

const PricingPage = () => {
  return (
    <div style={{ backgroundColor: '#f8f8f8', fontFamily: 'Arial, sans-serif', color: '#333' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          // backgroundColor: '#5cb85c', // Removed green background
          color: 'black', // Make title color black for better contrast
        }}
      >
        <span style={{ fontWeight: 'bold', padding:'8px 16px', border:'1px solid black', borderRadius:'20px', marginRight:'20px' }}>
            Pricing
        </span>
        <h1 style={{ fontSize: '36px', fontWeight: 'bold', textAlign: 'center' }}> {/* Reduced title font size */}
          Healthcare Solutions,
          <br />
          <span style={{ fontStyle: 'italic', color: '#666', fontSize: '30px' }}> {/* Adjusted font size and color */}
            wherever you are
          </span>
        </h1>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', padding: '40px 20px' }}>
        {/* Essential Card */}
        <div
          style={{
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '10px',
            margin: '0 10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            border: '1px solid #000', // Added black border
            width: '25%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <h2 style={{ color: '#0d6efd' }}>Essential</h2>
            <p style={{ fontSize: '48px', fontWeight: 'bold' }}>
              $99 <span style={{ fontSize: '16px', fontWeight: 'normal' }}>/month</span>
            </p>
            <p style={{ fontSize: '14px', color: '#777' }}>
              For small clinics and healthcare providers looking to streamline operations with
              secure, reliable tools.
            </p>
            <hr style={{ margin: '20px 0' }} />
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Patient Records</span>
                <br />
                Up to 500
              </li>
              <li style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Analytics</span>
                <br />
                Basic analytics and reporting
              </li>
              <li style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Support</span>
                <br />
                Email support
              </li>
            </ul>
          </div>
          <button
            style={{
              backgroundColor: '#fff',
              color: '#333',
              padding: '10px 20px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '20px',
            }}
          >
            Request access →
          </button>
        </div>

        {/* Advanced Card */}
        <div
          style={{
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '10px',
            margin: '0 10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            border: '1px solid #000', // Added black border
            width: '25%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <h2 style={{ color: '#0d6efd' }}>Advanced</h2>
            <p style={{ fontSize: '48px', fontWeight: 'bold' }}>
              $249 <span style={{ fontSize: '16px', fontWeight: 'normal' }}>/month</span>
            </p>
            <p style={{ fontSize: '14px', color: '#777' }}>
              For growing practices looking for enhanced capabilities to streamline operations.
            </p>
            <hr style={{ margin: '20px 0' }} />
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Patient Records</span>
                <br />
                Up to 5000
              </li>
              <li style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Analytics</span>
                <br />
                Advanced analytics
              </li>
              <li style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Support</span>
                <br />
                Priority support
              </li>
            </ul>
          </div>
          <button
            style={{
              backgroundColor: '#0d0d0d',
              color: '#fff',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '20px',
            }}
          >
            Request access →
          </button>
        </div>

        {/* Enterprise Card */}
        <div
          style={{
            backgroundColor: '#0d6efd',
            color: '#fff',
            padding: '30px',
            borderRadius: '10px',
            margin: '0 10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            border: '1px solid #000', // Added black border
            width: '25%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <h2 style={{color: '#fff'}}>Enterprise</h2>
            <p style={{ fontSize: '36px', fontWeight: 'bold' }}>Get in touch</p>
            <p style={{ fontSize: '14px' }}>
              Tailored for large hospital systems or healthcare networks needing scalability and
              full customization.
            </p>
            <hr style={{ margin: '20px 0' }} />
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Patient Records</span>
                <br />
                Unlimited
              </li>
              <li style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Analytics</span>
                <br />
                Full data insights
              </li>
              <li style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Support</span>
                <br />
                Dedicated account manager
              </li>
            </ul>
          </div>
          <button
            style={{
              backgroundColor: '#fff',
              color: '#0d6efd',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '20px',
            }}
          >
            Talk to us →
          </button>
        </div>
        {/* Premium Card */}
        <div
          style={{
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '10px',
            margin: '0 10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            border: '1px solid #000', // Added black border
            width: '25%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <h2 style={{ color: '#0d6efd' }}>Premium</h2>
            <p style={{ fontSize: '48px', fontWeight: 'bold' }}>
              $449 <span style={{ fontSize: '16px', fontWeight: 'normal' }}>/month</span>
            </p>
            <p style={{ fontSize: '14px', color: '#777' }}>
              For established practices seeking advanced analytics and dedicated support.
            </p>
            <hr style={{ margin: '20px 0' }} />
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Patient Records</span>
                <br />
                Up to 10000
              </li>
              <li style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Analytics</span>
                <br />
                Premium analytics
              </li>
              <li style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Support</span>
                <br />
                24/7 support
              </li>
            </ul>
          </div>
          <button
            style={{
              backgroundColor: '#fff',
              color: '#333',
              padding: '10px 20px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '20px',
            }}
          >
            Request access →
          </button>
        </div>
      </div>
      {/* Horizontal Card */}
      <div style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            backgroundColor: '#0d6efd',
            color: '#fff',
            padding: '30px',
            borderRadius: '10px',
            margin: '20px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            border: '1px solid #000', // Added black border
            width: '80%',
            textAlign: 'center'
          }}
        >
          <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>Custom Solutions</h2>
          <p style={{ fontSize: '16px', marginBottom: '20px' }}>
            Need something different? We offer custom solutions tailored to your specific needs.
          </p>
          <button
            style={{
              backgroundColor: '#fff',
              color: '#0d6efd',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Contact us →
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
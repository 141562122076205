import React, { useState, useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";

const Text = ({ map }) => {
  const [isAddingText, setIsAddingText] = useState(false);
  const [currentMarker, setCurrentMarker] = useState(null);
  const textAreaRef = useRef(null);

  const handleMapClick = (event) => {
    if (!isAddingText) return;

    const coords = event.lngLat;

    if (currentMarker) {
      currentMarker.remove();
    }

    const marker = new mapboxgl.Marker({
      element: createMarkerElement(),
      draggable: true,
      anchor: "bottom",
    })
      .setLngLat(coords)
      .addTo(map);

    setCurrentMarker(marker);
    setIsAddingText(false);
  };

  const createMarkerElement = () => {
    const element = document.createElement("div");
    element.className = "custom-marker";

    const textArea = document.createElement("textarea");
    textArea.className = "marker-text-area";
    textArea.placeholder = "Enter text...";
    textAreaRef.current = textArea;

    element.appendChild(textArea);

    return element;
  };

  const toggleAddText = () => {
    setIsAddingText(!isAddingText);
    if (currentMarker) {
      currentMarker.remove();
      setCurrentMarker(null);
    }
    map.getCanvas().style.cursor = isAddingText ? "" : "crosshair";
  };

  useEffect(() => {
    if (map) {
      map.on("click", handleMapClick);
    }

    return () => {
      if (map) {
        map.off("click", handleMapClick);
      }
    };
  }, [map, isAddingText]);

  return (
    <div>
      <button onClick={toggleAddText}>
        {isAddingText ? "Cancel" : "Add Text"}
      </button>
    </div>
  );
};

export default Text;
import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { UserButton, useUser, useClerk } from '@clerk/clerk-react';
import './Sidebar.css';
import {
  FaHistory,
  FaCog,
  FaCompass,
  FaUserPlus,
  FaGlobe,
  FaPlus,
  FaBars,
  FaChevronRight,
  FaFlask,
  FaCode,
  FaPlay,
  FaExternalLinkAlt,
} from 'react-icons/fa';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const { user } = useUser();
  const { signOut } = useClerk();

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <div className="sidebar-header">
        <div className="brand-logo">
          <img src="/dendrite_logo.png" alt="Neocu Logo" />
          {isOpen && <span>Dendric</span>}
        </div>
        {isOpen && (
          <button className="toggle-button" onClick={toggleSidebar}>
            <FaBars />
          </button>
        )}
      </div>

      {isOpen && (
        <>
          <ul>
            <li className={location.pathname === '/workspace/explore' ? 'active' : ''}>
              <Link to="/workspace/explore" className="sidebar-link">
                <FaCompass className="icon" />
                <span>Explore</span>
              </Link>
            </li>
            <li className={location.pathname === '/workspace' ? 'active' : ''}>
              <Link to="/workspace" className="sidebar-link">
                <FaHistory className="icon" />
                <span>Maps</span>
              </Link>
            </li>
            <li className={location.pathname === '/workspace/settings' ? 'active' : ''}>
              <Link to="/workspace/settings" className="sidebar-link">
                <FaCog className="icon" />
                <span>Settings</span>
              </Link>
            </li>
            <li className={location.pathname === '/workspace/sandbox' ? 'active' : ''}>
              <Link to="/workspace/sandbox" className="sidebar-link">
                <FaFlask className="icon" />
                <span>Sandbox</span>
              </Link>
            </li>
            <li className={location.pathname === '/workspace/embeds' ? 'active' : ''}>
              <Link to="/workspace/embeds" className="sidebar-link">
                <FaExternalLinkAlt className="icon" />
                <span>Embeds</span>
              </Link>
            </li>
            <li className={location.pathname === '/workspace/developers' ? 'active' : ''}>
              <Link to="/workspace/developers" className="sidebar-link">
                <FaCode className="icon" />
                <span>Developers</span>
              </Link>
            </li>
            <li className={location.pathname === '/workspace/simulations' ? 'active' : ''}>
              <Link to="/workspace/simulations" className="sidebar-link">
                <FaPlay className="icon" />
                <span>Simulations</span>
              </Link>
            </li>
            <li>
              <Link to="/invite" className="sidebar-link">
                <FaUserPlus className="icon" />
                <span>Invite members</span>
              </Link>
            </li>
          </ul>
          <hr />
          <h4>Projects</h4>
          <ul>
            <li>
              <Link to="/general" className="sidebar-link">
                <FaGlobe className="icon" />
                <span>General</span>
              </Link>
            </li>
            <li className="add-project">
              <Link to="/create-project" className="sidebar-link">
                <FaPlus className="icon" />
                <span>Create project</span>
              </Link>
            </li>
          </ul>

          {/* UserButton Container */}
          <div className="user-button-container">
            <UserButton
              userProfileMode="navigation"
              userProfileUrl="/account"
            />
            {user && (
              <span className="user-name">
                {user.firstName || user.username}
              </span>
            )}
          </div>
        </>
      )}

      {!isOpen && (
        <div className="closed-content">
          <ul className="closed-icons">
            <li>
              <Link to="/workspace/explore" className="sidebar-link">
                <FaCompass className="icon" />
                <span className="icon-label">Explore</span>
              </Link>
            </li>
            <li>
              <Link to="/workspace" className="sidebar-link">
                <FaHistory className="icon" />
                <span className="icon-label">Recents</span>
              </Link>
            </li>
            <li>
              <Link to="/workspace/settings" className="sidebar-link">
                <FaCog className="icon" />
                <span className="icon-label">Settings</span>
              </Link>
            </li>
            <li>
              <Link to="/workspace/sandbox" className="sidebar-link">
                <FaFlask className="icon" />
                <span className="icon-label">Sandbox</span>
              </Link>
            </li>
            <li>
              <Link to="/workspace/embeds" className="sidebar-link">
                <FaExternalLinkAlt className="icon" />
                <span className="icon-label">Embeds</span>
              </Link>
            </li>
            <li>
              <Link to="/workspace/developers" className="sidebar-link">
                <FaCode className="icon" />
                <span className="icon-label">Developers</span>
              </Link>
            </li>
            <li>
              <Link to="/workspace/simulations" className="sidebar-link">
                <FaPlay className="icon" />
                <span className="icon-label">Simulations</span>
              </Link>
            </li>
            <li>
              <Link to="/invite" className="sidebar-link">
                <FaUserPlus className="icon" />
                <span className="icon-label">Invite</span>
              </Link>
            </li>
            <li>
              <Link to="/general" className="sidebar-link">
                <FaGlobe className="icon" />
                <span className="icon-label">General</span>
              </Link>
            </li>
            <li>
              <Link to="/create-project" className="sidebar-link">
                <FaPlus className="icon" />
                <span className="icon-label">Create</span>
              </Link>
            </li>
          </ul>
          {/* Open Sidebar Arrow */}
          <button className="open-sidebar-button" onClick={toggleSidebar}>
            <FaChevronRight className="icon" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
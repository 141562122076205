import React, { useState, useEffect } from 'react';
import './Generate.css'; // Updated CSS import
import { FaSearch, FaTimesCircle } from 'react-icons/fa';

function Generate() { // Renamed component to Generate
    const [searchQuery, setSearchQuery] = useState('');
    const [dataId, setDataId] = useState(null);
    const [mediaItems, setMediaItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [generatingTaskId, setGeneratingTaskId] = useState(null);
    const [taskStatus, setTaskStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [mode, setMode] = useState('3d');
    const [isSearching, setIsSearching] = useState(false);

    const apiBaseUrl = 'https://embed.axv.ai/api';

    useEffect(() => {
        const checkGeneratingTask = async () => {
            try {
                const response = await fetch(`${apiBaseUrl}/task?status=generating`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const tasksResponse = await response.json();
                if (tasksResponse.isSuccess && tasksResponse.task) {
                    const generatingTask = tasksResponse.task;
                    setGeneratingTaskId(generatingTask._id);
                    setDataId(generatingTask.data_id);
                    setLoading(true);
                    setTaskStatus('generating');
                    setIsSearching(true);
                    pollTaskStatus(generatingTask.data_id);
                }
            } catch (error) {
                console.error("Error checking for generating task:", error);
                setErrorMessage("Failed to check for existing generating task.");
            }
        };

        checkGeneratingTask();
    }, []);

    useEffect(() => {
        if (dataId && loading) {
            pollTaskStatus(dataId);
        }
    }, [dataId, loading]);


    const pollTaskStatus = async (currentDataId) => {
        const intervalId = setInterval(async () => {
            try {
                const response = await fetch(`${apiBaseUrl}/task/${currentDataId}`);
                if (!response.ok) {
                    console.error("Task API Error Response:", await response.text());
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const taskResponse = await response.json();
                console.log('Task API Response:', taskResponse);
                if (taskResponse.isSuccess && taskResponse.task) {
                    const task = taskResponse.task;
                    setTaskStatus(task.status);
                    if (task.status === 'completed') { // Changed status to 'completed'
                        clearInterval(intervalId);
                        fetchMediaItems(currentDataId);
                    } else if (task.status === 'failed' || task.status === 'cancelled') {
                        clearInterval(intervalId);
                        setLoading(false);
                        setIsSearching(false);
                        setErrorMessage(`Task ${task.status}. Please try again.`);
                    }
                } else {
                    clearInterval(intervalId);
                    setLoading(false);
                    setIsSearching(false);
                    setErrorMessage("Error fetching task status: Invalid response format.");
                }
            } catch (error) {
                console.error("Error polling task status:", error);
                clearInterval(intervalId);
                setLoading(false);
                setIsSearching(false);
                setErrorMessage("Failed to poll task status.");
            }
        }, 3000);
    };

    const fetchMediaItems = async (currentDataId) => {
        try {
            const response = await fetch(`${apiBaseUrl}/image?data_id=${currentDataId}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const imageData = await response.json();
            if (imageData && imageData.imageList) {
                const processedMedia = imageData.imageList.map(item => ({
                    type: item.media_type === '3d' ? 'video' : 'image',
                    url: item.media_type === '3d' ? item.video : item.file_url,
                }));
                setMediaItems(processedMedia);
                setLoading(false);
                setIsSearching(false);
                setErrorMessage(null);
            } else {
                setLoading(false);
                setIsSearching(false);
                setErrorMessage("No images/videos found for this query.");
                setMediaItems([]);
            }
        } catch (error) {
            console.error("Error fetching media items:", error);
            setLoading(false);
            setIsSearching(false);
            setErrorMessage("Failed to fetch media items.");
            setMediaItems([]);
        }
    };


    const handleSearchSubmit = async (event) => {
        event.preventDefault();
        if (!searchQuery.trim()) return;

        setLoading(true);
        setIsSearching(true);
        setMediaItems([]);
        setErrorMessage(null);
        setDataId(null);
        setTaskStatus('pending');

        try {
            const response = await fetch(`${apiBaseUrl}/block/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: searchQuery, mode: mode }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            if (responseData.data_id) {
                setDataId(responseData.data_id);
                setTaskStatus('generating');
                setGeneratingTaskId(null);
            } else {
                setLoading(false);
                setIsSearching(false);
                setErrorMessage("Failed to initiate generation. Data ID not received.");
            }
        } catch (error) {
            console.error("Search submission error:", error);
            setLoading(false);
            setIsSearching(false);
            setErrorMessage("Failed to submit search query.");
        }
    };

    const handleCancelGeneration = async () => {
        if (generatingTaskId) {
            setLoading(true);
            setIsSearching(true);
            try {
                const response = await fetch(`${apiBaseUrl}/task/${generatingTaskId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ status: 'cancelled' }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                setLoading(false);
                setIsSearching(false);
                setTaskStatus('cancelled');
                setErrorMessage("Generation cancelled by user.");
                setGeneratingTaskId(null);
            } catch (error) {
                console.error("Error cancelling generation:", error);
                setLoading(false);
                setIsSearching(false);
                setErrorMessage("Failed to cancel generation.");
            }
        } else if (dataId) {
            setLoading(true);
            setIsSearching(true);
            try {
                const response = await fetch(`${apiBaseUrl}/task/cancel-by-dataid/${dataId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ status: 'cancelled' }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                setLoading(false);
                setIsSearching(false);
                setTaskStatus('cancelled');
                setErrorMessage("Generation cancelled by user.");

            } catch (error) {
                console.error("Error cancelling generation by dataId:", error);
                setLoading(false);
                setIsSearching(false);
                setErrorMessage("Failed to cancel generation.");
            }
        }
    };


    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleModeChange = (selectedMode) => {
        setMode(selectedMode);
    };

    const Loader = () => (
        <div className="loader">
            <div className="spinner"></div>
        </div>
    );


    return (
        <div className="hub-container">
            {!isSearching && <h1 className="hub-title">Imagine</h1>}

            <div className={`search-bar-container ${isSearching ? 'search-bar-top' : ''}`}>
                <div className="mode-buttons">
                    <button
                        className={`mode-button ${mode === '3d' ? 'active' : ''}`}
                        onClick={() => handleModeChange('3d')}
                    >
                        3D
                    </button>
                    <button
                        className={`mode-button ${mode === '2d' ? 'active' : ''}`}
                        onClick={() => handleModeChange('2d')}
                    >
                        2D
                    </button>
                </div>
                <form onSubmit={handleSearchSubmit} className="search-form">
                    <input
                        type="text"
                        className="search-input-gen"
                        placeholder="Enter your search query"
                        value={searchQuery}
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                </form>
            </div>

            {errorMessage && <p className="error-message">{errorMessage}</p>}

            {isSearching && loading && taskStatus === 'generating' && (
                <div className="loading-container loading-container-centered">
                    <Loader />
                    <p className="loading-message">Query Generating in {mode.toUpperCase()}...</p>
                    <button className="cancel-button" onClick={handleCancelGeneration} disabled={taskStatus !== 'generating'}>
                        Cancel <FaTimesCircle />
                    </button>
                </div>
            )}
             {isSearching && loading && taskStatus === 'pending' && (
                <div className="loading-container loading-container-centered">
                    <Loader />
                    <p className="loading-message">Initiating Search in {mode.toUpperCase()}...</p>
                </div>
            )}
            {isSearching && loading && taskStatus === 'cancelled' && (
                <div className="loading-container loading-container-centered">
                    <p className="loading-message">Generation Cancelled.</p>
                </div>
            )}


            <div className="media-grid">
                {mediaItems.map((item, index) => (
                    <div key={index} className="media-item">
                        {item.type === 'image' ? (
                            <img src={item.url} alt={`media-${index}`} className="media-content" />
                        ) : (
                            <video src={item.url} className="media-content" controls loop autoPlay muted playsInline />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Generate; // Export renamed component
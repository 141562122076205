// src/components/LandingPage.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import './LandingPage.css';
import { BiSearchAlt2, BiCodeAlt, BiBarChartAlt2, BiMessageSquareDots } from 'react-icons/bi';

function LandingPage() {
    const featureCards = [
        {
            id: 1,
            icon: <BiSearchAlt2 />,
            title: 'Search',
            description: 'Find answers from SideMap, Slack, Google Drive & more',
            image: '/image_1.png',
        },
        {
            id: 2,
            icon: <BiCodeAlt />,
            title: 'Generate',
            description: 'Create & edit maps in your own style',
            image: '/image_2.png',
        },
        {
            id: 3,
            icon: <BiBarChartAlt2 />,
            title: 'Analyze',
            description: 'Get insights from PDFs & images',
            image: '/image_3.png',
        },
        {
            id: 4,
            icon: <BiMessageSquareDots />,
            title: 'Chat',
            description: 'Access knowledge from GPT-4 & Claude',
            image: '/image_4.png',
        },
    ];

    const [activeCard, setActiveCard] = useState(featureCards[0].id);

    const handleCardClick = (cardId) => {
        setActiveCard(cardId);
    };

    const activeFeatureCard = activeCard ? featureCards.find(card => card.id === activeCard) : null;

    return (
        <main className="landing-page">

             <Header />
            <div className="hero-section">
                <div className="hero-text">
                    <h1>Meet the new <br /> SideMap AI</h1>
                    <p>One tool that does it all. Search, generate, analyze, and chat — right inside SideMap.</p>
                    <div className="hero-buttons">
                        <Link to="/signup" className="get-started-button">Get SideMap Free</Link>
                        <button className="request-demo-button">Request a demo</button>
                    </div>
                    <div className="trusted-by">
                        <p>Trusted by teams at</p>
                        <div className="company-logos">
                            <span className="company-logo">TOYOTA</span>
                            <span className="company-logo">Vercel</span>
                            <span className="company-logo">1Password</span>
                        </div>
                    </div>
                </div>
                <div className="hero-image">
                    <img src="/world.png" alt="Illustration" />
                </div>
            </div>
            <div className="features-section">
                <div className="feature-cards-container">
                    {featureCards.map(card => (
                        <div
                            key={card.id}
                            className={`feature-card ${activeCard === card.id ? 'active' : 'inactive'}`}
                            onClick={() => handleCardClick(card.id)}
                        >
                            <div className="card-content">
                                <span className="card-icon">{card.icon}</span>
                                <h3>{card.title}</h3>
                                <p>{card.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {activeFeatureCard && (
                    <div className="active-card-image">
                        <img src={activeFeatureCard.image} alt={activeFeatureCard.title} />
                    </div>
                )}
            </div>
            <div className="mockup-section">
                <h2>This is the Mockup Section</h2>
                <p>Details about our product will be here.</p>
            </div>
        </main>
    );
}

export default LandingPage;
// components/DocsPage.js
import React from 'react';

const DocsPage = () => {
    const sectionStyle = {
        padding: '20px',
        marginBottom: '20px',
        borderBottom: '1px solid #e0e0e0',
    };

    const subsectionStyle = {
        paddingLeft: '20px',
        marginBottom: '15px',
    };

    const titleStyle = {
        fontWeight: 'bold',
        marginBottom: '10px',
        fontSize: '1.5em',
    };

    const subtitleStyle = {
        fontWeight: 'bold',
        marginBottom: '5px',
        fontSize: '1.2em',
    };

    const codeStyle = {
        backgroundColor: '#f0f0f0',
        padding: '10px',
        borderRadius: '5px',
        fontFamily: 'monospace',
        overflowX: 'auto',
        marginBottom: '10px',
    };

    const useCasesStyle = {
        listStyleType: 'disc',
        paddingLeft: '20px',
    };

    const imageStyle = {
        maxWidth: '80%',
        height: 'auto',
        marginBottom: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
    };

    return (
        <div style={{ fontFamily: 'Arial, sans-serif', color: '#333', lineHeight: '1.6' }}>
            <div style={sectionStyle}>
                <h2 style={titleStyle}>Documentation</h2>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Introducing Neocu</h3>
                    <p>Welcome to Neocu, the unified AI platform that empowers you to generate and edit diverse modalities—images, videos, sounds, voices, and 3D models—all within a single, intuitive interface. Unleash your creativity and streamline your workflow with Neocu's powerful AI tools.</p>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Getting Started</h3>
                    <p>To begin your journey with Neocu, simply sign up for an account and choose a pricing plan that suits your needs. Once logged in, you'll gain access to the workspace where you can start generating and editing content across all supported modalities.</p>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Working with Neocu</h3>

                    <div style={{ paddingLeft: '20px' }}>
                        <h4 style={subtitleStyle}>Knowledge</h4>
                        <p>Neocu leverages vast amounts of knowledge to understand and execute your creative prompts. This allows for nuanced and contextually relevant generation across all modalities.</p>

                        <h4 style={subtitleStyle}>AI Memory</h4>
                        <p>Neocu's AI memory feature allows you to reference previously generated assets in new prompts. This enables consistency and iterative creation, building upon your past work seamlessly.</p>

                        <h4 style={subtitleStyle}>Sandbox</h4>
                        <p>The Sandbox environment provides a safe and flexible space to experiment with different prompts and parameters without immediate real-world consequences. It's the perfect place to explore Neocu's capabilities and refine your creative vision.</p>
                    </div>
                </div>
            </div>

            <div style={sectionStyle}>
                <h2 style={titleStyle}>Images</h2>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Create</h3>
                    <p>Generate stunning and diverse images with simple text prompts. Neocu's image generation capabilities allow for a wide range of styles, subjects, and artistic interpretations.</p>
                    <p><b>Example Prompt:</b> "A futuristic cityscape at sunset, cyberpunk style"</p>
                    <img src="/image_1.png" alt="Image Create Example" style={imageStyle} />
                    <p><b>Variance:</b> Experiment with different art styles (photorealistic, cartoonish, abstract), perspectives (aerial view, close-up), lighting conditions (morning light, night scene), and subjects to achieve a vast array of image outputs.</p>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Edit</h3>
                    <p>Effortlessly edit existing images using text prompts. Modify colors, add or remove objects, change styles, and more, all through natural language instructions.</p>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Use Cases</h3>
                    <ul style={useCasesStyle}>
                        <li>Social Media Content Creation</li>
                        <li>Marketing and Advertising Visuals</li>
                        <li>Website and Blog Graphics</li>
                        <li>Concept Art and Storyboarding</li>
                        <li>Product Visualization</li>
                        <li>Personalized Wallpapers and Backgrounds</li>
                        <li>Educational Materials</li>
                        <li>Meme Generation</li>
                        <li>Photo Enhancement and Restoration</li>
                        <li>Creating Unique Profile Pictures</li>
                    </ul>
                </div>
            </div>

            <div style={sectionStyle}>
                <h2 style={titleStyle}>Video</h2>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Create</h3>
                    <p>Bring dynamic scenes to life with Neocu's video generation. Describe the video you envision, and Neocu will generate short, engaging video clips.</p>
                    <p><b>Example Prompt:</b> "A cat playing piano in a jazz club, animated style"</p>
                    <img src="/image_2.png" alt="Video Create Example" style={imageStyle} />
                    <p><b>Variance:</b> Explore different genres (animation, realistic, sci-fi), camera movements (panning, zooming), character styles, and scene settings to generate diverse video content.</p>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Edit</h3>
                    <p>Edit video content with text prompts. Change scenes, modify characters, adjust pacing, and more, all through simple instructions.</p>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Use Cases</h3>
                    <ul style={useCasesStyle}>
                        <li>Social Media Video Shorts</li>
                        <li>Explainer Videos</li>
                        <li>Marketing Video Ads</li>
                        <li>Animated Greeting Cards</li>
                        <li>Educational Video Content</li>
                        <li>Personalized Video Messages</li>
                        <li>Storytelling and Narrative Videos</li>
                        <li>Product Demo Videos</li>
                        <li>Music Visualizers</li>
                        <li>Creating Looping Background Videos</li>
                    </ul>
                </div>
            </div>

            <div style={sectionStyle}>
                <h2 style={titleStyle}>Sound</h2>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Create</h3>
                    <p>Generate unique soundscapes and musical pieces with text prompts. Create sound effects, background music, or entire compositions.</p>
                    <p><b>Example Prompt:</b> "Ambient forest sounds with birds chirping and a gentle stream"</p>
                    <img src="/image_3.png" alt="Sound Create Example" style={imageStyle} />
                    <p><b>Variance:</b> Experiment with different genres (classical, electronic, nature sounds), instruments, moods (calm, energetic, mysterious), and sound textures to produce a wide range of audio experiences.</p>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Edit</h3>
                    <p>Edit existing audio files with text commands. Adjust volume, add effects, change tempo, and more, using natural language instructions.</p>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Use Cases</h3>
                    <ul style={useCasesStyle}>
                        <li>Background Music for Videos</li>
                        <li>Sound Effects for Games and Apps</li>
                        <li>Creating Unique Ringtones</li>
                        <li>Ambient Sounds for Relaxation and Focus</li>
                        <li>Audio Branding and Jingles</li>
                        <li>Sound Design for Podcasts</li>
                        <li>Generating Musical Loops</li>
                        <li>Creating Soundscapes for Virtual Environments</li>
                        <li>Audio for Meditations and Guided Practices</li>
                        <li>Personalized Audio Messages</li>
                    </ul>
                </div>
            </div>

            <div style={sectionStyle}>
                <h2 style={titleStyle}>Voice</h2>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Create</h3>
                    <p>Generate realistic and expressive voices with text prompts. Create voiceovers, character voices, and personalized vocalizations in various tones and styles.</p>
                    <p><b>Example Prompt:</b> "A deep, authoritative male voice reading a news report"</p>
                    <img src="/image_4.png" alt="Voice Create Example" style={imageStyle} />
                    <p><b>Variance:</b> Explore different accents, ages, genders, emotions (happy, sad, angry), and speaking styles (conversational, formal, dramatic) to generate diverse voice outputs.</p>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Edit</h3>
                    <p>Edit existing voice recordings with text prompts. Change tone, adjust speed, add emphasis, and more, using text commands.</p>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Use Cases</h3>
                    <ul style={useCasesStyle}>
                        <li>Voiceovers for Videos and Presentations</li>
                        <li>Character Voices for Games and Animations</li>
                        <li>Text-to-Speech for Accessibility</li>
                        <li>Creating Audiobooks and Podcasts</li>
                        <li>Voice Assistants and Chatbots</li>
                        <li>Personalized Voice Messages</li>
                        <li>Language Learning Tools</li>
                        <li>Automated Customer Service Scripts</li>
                        <li>Voice Cloning for Content Creation</li>
                        <li>Generating Unique Vocal Sounds and Effects</li>
                    </ul>
                </div>
            </div>

            <div style={sectionStyle}>
                <h2 style={titleStyle}>3D</h2>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Create</h3>
                    <p>Generate 3D models from text prompts. Create objects, characters, scenes, and architectural elements in 3D.</p>
                    <p><b>Example Prompt:</b> "A futuristic sports car, sleek and aerodynamic"</p>
                    <p><b>Variance:</b> Experiment with different styles (realistic, stylized, low-poly), materials (metal, glass, wood), complexities (simple shapes, intricate designs), and subjects to generate a variety of 3D models.</p>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Edit</h3>
                    <p>Edit existing 3D models using text prompts. Modify shapes, textures, colors, and details through natural language instructions.</p>
                </div>
            </div>

            <div style={sectionStyle}>
                <h2 style={titleStyle}>Reconstruction</h2>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Simulate</h3>
                    <p>Simulate real-world scenarios and environments using AI. Create virtual simulations for training, testing, and visualization purposes.</p>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Reimagine</h3>
                    <p>Reimagine existing content in new and creative ways. Transform images, videos, sounds, and voices into entirely new forms and styles.</p>
                </div>
            </div>

            <div style={sectionStyle}>
                <h2 style={titleStyle}>uComputer</h2>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Creation Engine in Browser</h3>
                    <p>Neocu's uComputer feature allows you to generate content directly in your browser using a simple URL structure. Just append your query to the base URL and see your creations come to life instantly.</p>
                    <p><b>Example:</b> Access <a href="https://u.computer/create%20an%20image%20of%20a%20dog" target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'none' }}>https://u.computer/create an image of a dog</a> in your browser to generate an image of a dog.</p>
                    <img src="/image_4.png" alt="uComputer Example" style={imageStyle} />
                    <p>You can replace "create an image of a dog" with any prompt to generate images, sounds, videos, voices, and 3D models directly through your browser's address bar.</p>
                </div>
            </div>

            <div style={sectionStyle}>
                <h2 style={titleStyle}>API Reference</h2>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Generation</h3>
                    <p><b>Endpoint:</b> <code>https://api.u.computer</code></p>
                    <p><b>Method:</b> POST</p>
                    <p><b>Request Body (JSON):</b></p>
                    <div style={codeStyle}>
                        <pre>{`{
    "api_key": "YOUR_API_KEY",
    "prompt": "Generate a photorealistic image of a mountain lake",
    "attach": [] // Array for future attachments if needed
}`}
                        </pre>
                    </div>
                    <p><b>Response (JSON):</b></p>
                    <div style={codeStyle}>
                        <pre>{`{
    "job_id": "unique_job_identifier_123",
    "status": "pending",
    "message": "Generation job submitted successfully."
}`}
                        </pre>
                    </div>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Jobs</h3>

                    <div style={{ paddingLeft: '20px' }}>
                        <h4 style={subtitleStyle}>List Jobs</h4>
                        <p><b>Endpoint:</b> <code>https://api.u.computer/job</code></p>
                        <p><b>Method:</b> GET</p>
                        <p><b>Response (JSON):</b></p>
                        <div style={codeStyle}>
                            <pre>{`[
    {
        "_id": "job_id_1",
        "status": "completed",
        "prompt": "Generate a sunset",
        "result_url": "https://cdn.u.computer/results/job_id_1.png"
    },
    {
        "_id": "job_id_2",
        "status": "pending",
        "prompt": "Create a sound of rain",
        "result_url": null
    }
]`}
                            </pre>
                        </div>

                        <h4 style={subtitleStyle}>Create Job</h4>
                        <p><b>Endpoint:</b> <code>https://api.u.computer/job</code></p>
                        <p><b>Method:</b> POST</p>
                        <p><b>Request Body (JSON):</b></p>
                        <div style={codeStyle}>
                            <pre>{`{
    "api_key": "YOUR_API_KEY",
    "prompt": "Generate a 3D model of a chair",
    "options": {
        "modality": "3d",
        "style": "modern"
    }
}`}
                            </pre>
                        </div>
                        <p><b>Response (JSON):</b> (Same as Generation Response)</p>


                        <h4 style={subtitleStyle}>Get/Update/Delete Job</h4>
                        <p><b>Endpoint:</b> <code>https://api.u.computer/job/:_id</code> (Replace <code>:_id</code> with the actual Job ID)</p>
                        <p><b>Methods:</b> GET, POST (Update), DELETE</p>
                        <p><b>GET Response (JSON):</b></p>
                        <div style={codeStyle}>
                            <pre>{`{
    "_id": "job_id_1",
    "status": "completed",
    "prompt": "Generate a sunset",
    "result_url": "https://cdn.u.computer/results/job_id_1.png",
    "options": {
        "modality": "image",
        "style": "photorealistic"
    }
}`}
                            </pre>
                        </div>
                        <p><b>POST (Update) Request Body (JSON - for example, updating the prompt):</b></p>
                        <div style={codeStyle}>
                            <pre>{`{
    "api_key": "YOUR_API_KEY",
    "prompt": "Generate a more vibrant sunset",
    "options": {
        "style": "vibrant-photorealistic"
    }
}`}
                            </pre>
                        </div>
                        <p><b>DELETE Response (JSON):</b></p>
                        <div style={codeStyle}>
                            <pre>{`{
    "message": "Job job_id_1 successfully deleted."
}`}
                            </pre>
                        </div>
                    </div>
                </div>

                <div style={subsectionStyle}>
                    <h3 style={subtitleStyle}>Compute</h3>
                    <p><b>Endpoint:</b> <code>https://api.u.computer/compute</code></p>
                    <p><b>Method:</b> GET</p>
                    <p><b>Description:</b> (Please provide more details about what this endpoint does so I can document it properly.  Is it for checking compute status, getting system info, etc.? For now I will assume it's for checking system status.)</p>
                    <p><b>Response (JSON - Example System Status):</b></p>
                    <div style={codeStyle}>
                        <pre>{`{
    "status": "online",
    "active_jobs": 25,
    "server_load": 0.65,
    "message": "System is operational."
}`}
                        </pre>
                    </div>
                </div>
            </div>

            <div style={sectionStyle}>
                <h2 style={titleStyle}>Release Notes</h2>
                <p>Coming soon! Release notes and updates will be posted here.</p>
            </div>
        </div>
    );
};

export default DocsPage;
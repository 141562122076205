import React, { useState, useEffect } from 'react';
import './Onboard.css';
import { useNavigate } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa6';

function Onboard({ userId, setIsOnboarding }) {
    const [step, setStep] = useState(1);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [industry, setIndustry] = useState('');
    const [jobRole, setJobRole] = useState('');
    const [language, setLanguage] = useState('');
    const [use, setUse] = useState('');
    const [interest, setInterest] = useState([]); // New state for interests
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionLoading, setSuggestionLoading] = useState(false);
    const industryOptions = ["Ecommerce", "Creator", "Real Estate", "Interior Design", "Geography", "Student", "Sales", "Researcher", "Game Developer", "Hobbyist"];
    const roleOptions = ["Owner", "Manager", "Analyst", "Freelancer", "Studying", "Designer", "Fun"];
    const languageOptions = [
        "English", "Spanish", "French", "German", "Chinese", "Japanese", "Korean", "Russian", "Arabic", "Portuguese",
        "Hindi", "Italian", "Dutch", "Swedish", "Polish", "Turkish", "Vietnamese", "Thai", "Indonesian", "Malay"
    ];
    const interestOptions = [
        { label: "Create & edit images", icon: "🖼️" },
        { label: "Create & edit videos", icon: "🎬" },
        { label: "Build & analyze maps", icon: "🗺️" },
        { label: "Build & read charts", icon: "📊" },
        { label: "Ecommerce marketing", icon: "🛍️" },
        { label: "Get data insights", icon: "💡" },
        { label: "Create data visualizations", icon: "📈" },
        { label: "Other", icon: "🛠️" }
    ];


    const fetchSuggestions = async () => {
        setSuggestionLoading(true);
        try {
            await fetch('https://embed.axv.ai/api/highlight/suggestion', { // Assuming userId is enough context for backend
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json' // Specify content type as JSON
                },
                body: JSON.stringify({ // Stringify the body as JSON
                    user_id: userId // Assuming userId is a variable available in this scope
                })
            });
            // Added limit=5 to the API call to limit suggestions to 5
            const suggestionsResponse = await fetch(`https://embed.axv.ai/api/highlight?user_id=${userId}&category=suggestions&limit=5`);
            if (suggestionsResponse.ok) {
                const suggestionsData = await suggestionsResponse.json();
                if (suggestionsData.taskList) {
                    setSuggestions(suggestionsData.taskList);
                }
            } else {
                console.error('Failed to fetch suggestions:', suggestionsResponse.status);
            }
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        } finally {
            setSuggestionLoading(false);
        }
    };


    useEffect(() => {
        const getOnboardStep = async () => {
            try {
                const response = await fetch(`https://embed.axv.ai/api/consumer/${userId}`);
                if (response.ok) {
                    const consumerData = await response.json();
                    if (consumerData.data && consumerData.data.onboard_step) {
                        setStep(consumerData.data.onboard_step);
                        setIndustry(consumerData.data.industry || ''); // Initialize from existing data
                        setJobRole(consumerData.data.job_role || ''); // Initialize from existing data
                        setLanguage(consumerData.data.language || ''); // Initialize from existing data
                        setUse(consumerData.data.use || ''); // Initialize 'use' from existing data
                        setInterest(consumerData.data.interest || []); // Initialize 'interest' from existing data
                    }
                } else {
                    console.error('Failed to fetch consumer data for onboard step:', response.status);
                }
            } catch (error) {
                console.error('Error fetching onboard step:', error);
            }
        };

        getOnboardStep();
    }, [userId]);

    useEffect(() => {
        if (step === 4) {
            fetchSuggestions();
        }
    }, [step, userId]);


    const nextStep = async () => {
        setLoading(true);
        let updatePayload = { onboard_step: step + 1 };
        if (step === 2) {
            updatePayload = { ...updatePayload, use };
        } else if (step === 3) {
            updatePayload = { ...updatePayload, interest }; // Send interests for step 3
        }

        try {
            const response = await fetch(`https://embed.axv.ai/api/consumer/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatePayload),
            });

            if (response.ok) {
                setStep(step + 1);
            } else {
                console.error('Failed to update onboard step:', response.status);
                alert('Failed to proceed to next step. Please try again.');
            }
        } catch (error) {
            console.error('Error updating onboard step:', error);
            alert('Error proceeding to next step. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const completeOnboarding = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://embed.axv.ai/api/consumer/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ onboard: true, onboard_step: 5 }), // Set onboard to true and step to beyond last step (now 5)
            });

            if (response.ok) {
                setIsOnboarding(false);
                navigate('/');
            } else {
                console.error('Failed to complete onboarding:', response.status);
                alert('Failed to complete onboarding. Please try again.');
            }
        } catch (error) {
            console.error('Error completing onboarding:', error);
            alert('Error completing onboarding. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const skipGoalStep = () => {
        setStep(5); // Directly jump to last step (step 5 now after removing step 5)
    };

    const handleInterestChange = (optionLabel) => {
        setInterest(currentInterests => {
            if (currentInterests.includes(optionLabel)) {
                return currentInterests.filter(item => item !== optionLabel);
            } else {
                return [...currentInterests, optionLabel];
            }
        });
    };


    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <div className="onboard-slide onboard-slide-dark">
                        <img src="/logo_r.png" alt="Neocu Logo" className="onboard-logo" />
                        <h2>Welcome to Neocu</h2>
                        <p>Neocu is a purpose-built system to be your design and data partner. Streamline media creating, editing and collaborating</p>
                        <button className="onboard-button onboard-button-orange" onClick={nextStep} disabled={loading}>
                            {loading ? 'Loading...' : 'Get started'}
                        </button>
                    </div>
                );
            case 2:
                return (
                    <div className="onboard-slide">
                        <h2>How do you plan to use Julius?</h2>
                        <p>We'll use this to personalize your experience.</p>
                        <div className="onboard-options">
                            <button
                                className={`onboard-option ${use === 'work' ? 'selected' : ''}`}
                                onClick={() => setUse('work')}
                            >
                                <span className="option-icon">💼</span> For work
                            </button>
                            <button
                                className={`onboard-option ${use === 'personal' ? 'selected' : ''}`}
                                onClick={() => setUse('personal')}
                            >
                                <span className="option-icon">🏠</span> For personal use
                            </button>
                            <button
                                className={`onboard-option ${use === 'education' ? 'selected' : ''}`}
                                onClick={() => setUse('education')}
                            >
                                <span className="option-icon">📚</span> For education
                            </button>
                        </div>
                        <button className="onboard-button continue-button" onClick={nextStep} disabled={loading || !use}>
                            {loading ? 'Loading...' : 'Continue'}
                        </button>
                    </div>
                );
            case 3:
                return (
                    <div className="onboard-slide">
                        <h2>What do you plan to do with Julius?</h2>
                        <div className="onboard-options-grid">
                            {interestOptions.map(option => (
                                <button
                                    key={option.label}
                                    className={`onboard-option-grid ${interest.includes(option.label) ? 'selected' : ''}`}
                                    onClick={() => handleInterestChange(option.label)}
                                >
                                    <span className="option-icon-grid">{option.icon}</span>
                                    {option.label}
                                    <span className="option-selector">
                                        {interest.includes(option.label) && <FaCheck className="check-icon" />}
                                    </span>
                                </button>
                            ))}
                        </div>
                        <div className="onboard-buttons-flex">
                            <button className="onboard-button onboard-button-back" onClick={() => setStep(step - 1)} disabled={loading}>
                                Back
                            </button>
                            <button className="onboard-button continue-button" onClick={nextStep} disabled={loading || interest.length === 0}>
                                {loading ? 'Loading...' : 'Continue'}
                            </button>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="onboard-slide">
                        <h2>Neo Engine</h2>
                        <p>Neocu works to be your design partner around your goals. As you upload more data and generate content, Neocu assesses opportunities to get closer to your goals.</p>
                        {suggestionLoading ? (
                            <div className="loading-spinner orange-spinner"></div>
                        ) : (
                            <div className="suggestion-cards-container">
                                {suggestions.map((suggestion, index) => (
                                    <div key={index} className="suggestion-card suggestion-card-black">
                                        <div className="suggestion-card-content">
                                            <p>{suggestion.text}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <button className="onboard-button" onClick={nextStep} disabled={suggestionLoading || loading}>
                            {suggestionLoading ? 'Loading...' : 'Continue'}
                        </button>
                    </div>
                );
            case 5:
                return (
                    <div className="onboard-slide">
                        <h2>You're good to go</h2>
                        <p>Go ahead and explore the possibilities with Neocu.</p>
                        <div className="onboarding-features">
                            <div className="feature-item">
                                <h3><span role="img" aria-label="team">👥</span> Tell your team</h3>
                                <p>Make sure to invite your team members.</p>
                            </div>
                            <div className="feature-item">
                                <h3><span role="img" aria-label="upload">⬆️</span> Upload your assets</h3>
                                <p>Start uploading your assets or use one of integrations to allow Neocu the power to build suggestions for your ideas</p>
                            </div>
                            <div className="feature-item">
                                <h3><span role="img" aria-label="demo">🗓️</span> Book a demo <button className="book-demo-button" onClick={() => window.open('https://guide.neocu.com/', '_blank')}>Book</button></h3>
                                <p>Book a demo today to learn how Neocu can help you move faster.</p>
                            </div>
                        </div>
                        <button className="onboard-button onboard-button-last" onClick={completeOnboarding} disabled={loading}>
                            {loading ? 'Loading...' : 'Open Neocu'}
                        </button>
                    </div>
                );
            default:
                return (
                    <div className="onboard-slide">
                        <h2>Onboarding Complete</h2>
                        <p>You have completed the onboarding process.</p>
                        <button className="onboard-button" onClick={completeOnboarding} disabled={loading}>
                            {loading ? 'Loading...' : 'Go to App'}
                        </button>
                    </div>
                );
        }
    };

    return (
        <div className="onboard-container">
            {renderStepContent()}
            <div className="onboard-steps-indicator">
                {Array.from({ length: 5 }).map((_, index) => ( // Adjusted length to 5 steps
                    <span key={index} className={`step-dot ${index + 1 === step ? 'active' : ''}`}></span>
                ))}
            </div>
        </div>
    );
}

export default Onboard;
// Share.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './Share.css';
import { FaPlayCircle } from 'react-icons/fa';

function Share() {
    const { id } = useParams();
    const [imageData, setImageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        const fetchImageData = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch(`https://embed.axv.ai/api/image/${id}`);
                if (!response.ok) {
                    if (response.status === 404) {
                        setImageData(null); // Set imageData to null for "Not Found" scenario
                    } else {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                } else {
                    const data = await response.json();
                    setImageData(data.imageData);
                }
            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
        };

        fetchImageData();
    }, [id]);

    const handlePlayAudio = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    if (loading) {
        return <div className="share-container"><p>Loading asset...</p></div>;
    }

    if (error) {
        return <div className="share-container"><p>Error loading asset.</p></div>;
    }

    if (!imageData) {
        return (
            <div className="share-container">
                <div className="no-assets-card">
                    <h2 className="no-assets-title">No Assets Available</h2>
                    <p className="no-assets-description">No assets were found. Please check with the person who shared it.</p>
                </div>
            </div>
        );
    }

    let mediaContent;
    switch (imageData.media_type) {
        case 'image':
            mediaContent = (
                <img src={imageData.file_url} alt={imageData.name} className="share-image" />
            );
            break;
        case 'video':
            mediaContent = (
                <video src={imageData.file_url} controls className="share-video" />
            );
            break;
        case 'sound':
        case 'voice':
            mediaContent = (
                <div className="audio-container">
                    <div className="audio-thumbnail-overlay" onClick={handlePlayAudio}>
                        <img src={imageData.thumbnail} alt={imageData.name} className="share-audio-thumbnail" />
                        <div className="play-button-overlay">
                            <div className="play-button-circle">
                                <FaPlayCircle className="play-icon" />
                            </div>
                        </div>
                    </div>
                    <audio ref={audioRef} src={imageData.file_url} className="share-audio-player"  onPlay={() => setIsPlaying(true)} onPause={() => setIsPlaying(false)} onEnded={() => setIsPlaying(false)}/>
                </div>
            );
            break;
        case 'glb':
            mediaContent = (
                <video src={imageData.thumbnail} controls className="share-glb-video" />
            );
            break;
        case '360':
        case 'game':
            mediaContent = (
                <iframe
                    src={imageData.embed}
                    title="Embedded Content"
                    className="share-embed"
                    allowFullScreen
                ></iframe>
            );
            break;
        default:
            mediaContent = <p>Unsupported media type: {imageData.media_type}</p>;
    }

    return (
        <div className="share-container">
            {mediaContent}
        </div>
    );
}

export default Share;
import React, { useState } from 'react';
import './Chat.css';

const Chat = () => {
    const [activeChat, setActiveChat] = useState('Edwin Johnson');
    const [activeTab, setActiveTab] = useState('General');
     const [messages, setMessages] = useState({
        'Edwin Johnson': [
            { sender: 'Edwin Johnson', text: "Hi! I'm interested in the apartment listing I saw online. Is it still available for next weekend?", time: '8:35' },
            { sender: 'You', text: "Hi there! Yes, it's available on those dates. Just to confirm, will it be just you, or are you traveling with others?", time: '8:51' },
            { sender: 'Edwin Johnson', text: "We're looking for a place with a nice view or a cozy balcony, if possible.", time: '8:54' },
            { sender: 'Edwin Johnson', text: "It'll be me and one friend.", time: '8:55' },
           { sender: 'You', text: "Great! The apartment has a small balcony with a view of the sea - perfect for morning coffee!", time: '8:57' },
           { sender: 'Edwin Johnson', text: "Cool! Is there a coffee machine or kettle in the kitchen?", time: '9:01' }

        ],
        'Albert Flores': [
            { sender: 'Albert Flores', text: "Hi, I'm confirming your check-in...", time: '10:37' },
        ],
        'Annette Black': [
            { sender: 'Annette Black', text: "I'm arriving tomorrow afternoon...", time: '9:15' },
        ],
         'Jerome Bell': [
            { sender: 'Jerome Bell', text: "I've received your booking request...", time: 'Thu' },
        ],
         'Darrell Steward': [
            { sender: 'Darrell Steward', text: "Hello! Just a reminder that check...", time: 'Thu' },
        ],
         'Steven Jordan': [
            { sender: 'Steven Jordan', text: "Sounds good! Could you confirm...", time: 'Wed' },
        ],
         'Wanda Hall': [
            { sender: 'Wanda Hall', text: "Thanks for the update! Just to d...", time: 'Wed' },
        ],
         'Victor Olson': [
            { sender: 'Victor Olson', text: "Hi, just letting you know that the...", time: 'Wed' },
        ],
    });
    const [newMessage, setNewMessage] = useState('');

   const handleSendMessage = () => {
        if (newMessage.trim() !== '') {
        const updatedMessages = { ...messages };
        updatedMessages[activeChat] = [
            ...(updatedMessages[activeChat] || []),
            { sender: 'You', text: newMessage, time: 'Now' },
        ];
        setMessages(updatedMessages);
        setNewMessage('');
            setTimeout(() => {
                const response = 'Got it! Thanks!';
            const updatedMessagesWithResponse = { ...messages };
            updatedMessagesWithResponse[activeChat] = [
                    ...(updatedMessagesWithResponse[activeChat] || []),
                { sender: activeChat, text: response, time: 'Now' },
              ];
                setMessages(updatedMessagesWithResponse);
           }, 800);
        }
    };
     const handleKeyDown = (e) => {
         if (e.key === 'Enter') {
             handleSendMessage();
         }
     };
       const handleTabSwitch = (tab) => {
         setActiveTab(tab);
    };

    const filteredMessages = () => {
        if (activeTab === "General") {
            return Object.keys(messages);
        }
       return [];
    }

    return (
      <div className="chat-container">
            <div className="chat-list">
                <div className="chat-header">
                  <h2 style={{ color: 'white' }}>Messages</h2>
                  <div className="chat-tabs">
                    <button
                         className={activeTab === 'General' ? 'active-tab' : ''}
                         onClick={() => handleTabSwitch('General')}
                        style={{ color: '#fff',  backgroundColor: activeTab === 'General' ? '#2e8b57' : 'transparent'  }} >General 6</button>
                    <button
                         className={activeTab === 'Archive' ? 'active-tab' : ''}
                           onClick={() => handleTabSwitch('Archive')}
                         style={{ color: 'white',  backgroundColor: activeTab === 'Archive' ? '#2e8b57' : 'transparent'   }} >Archive 2</button>
                  </div>

                  <div className="search-box">
                     <input type="text" placeholder="Search..." />
                  </div>


                </div>
                 {filteredMessages().map((name) => (
                    <div
                        key={name}
                        className={`chat-item ${activeChat === name ? 'active' : ''}`}
                        onClick={() => setActiveChat(name)}
                    >
                       <div className="chat-item-left">
                            <div className="user-avatar"></div>
                         </div>

                        <div className="chat-item-right">
                           <div style={{display: 'flex', justifyContent: 'space-between'}}>
                              <h3 style={{color: 'white'}}>{name}</h3>
                               <span className="chat-time" >10:37</span>
                           </div>
                            <p style={{color: 'grey', fontSize: '15px'}}>{messages[name][messages[name].length - 1].text.substring(0,25)}...</p>
                        </div>


                    </div>
                ))}
            </div>
            <div className="chat-window">
                <div className="chat-window-header">
                     <div className="chat-user-info">
                           <div className="user-avatar"></div>
                             <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <h3 style={{color: 'white', textAlign: 'center'}}>{activeChat}</h3>
                                <span style={{color: 'grey'}} >1-Bedroom Apartment, 45 m² $80/night </span>
                            </div>
                        </div>
                        <div className='call-button'>
                          <img src="https://static-00.iconduck.com/assets.00/phone-icon-2048x2048-b12h2f1x.png" style={{ width: '20px',  height: '20px', filter: 'invert(1)' }} />
                            <img src="https://cdn.icon-icons.com/icons2/1947/PNG/512/dots_more_vertical_icon_124919.png" style={{ width: '20px',  height: '20px', filter: 'invert(1)' }}  />
                       </div>
                </div>

                <div className="chat-messages">
                    {messages[activeChat]?.map((msg, index) => (
                        <div key={index} className={`message ${msg.sender === 'You' ? 'sent' : 'received'}`}>
                            <div className="message-text" style={{ background: msg.sender === 'You' ? 'linear-gradient(to right, #2e8b57, #3cb371)' : '#545454' , color: 'white'}} >
                                {msg.text}
                                 <span className="message-time" style={{ fontSize: '10px', marginLeft: '3px', color: '#ddd' }} >{msg.time}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="chat-input">
                    <input
                        type="text"
                        placeholder="Your message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                         onKeyDown={handleKeyDown}
                    />
                     <button onClick={handleSendMessage} className='send-button'>
                         <img src="https://cdn-icons-png.flaticon.com/512/2798/2798274.png" style={{ width: '20px',  height: '20px', filter: 'invert(1)' }} />
                     </button>
                </div>
            </div>
        </div>
    );
};

export default Chat;
// MapStyle.js
export const mapStyles = {
  standard: "mapbox://styles/mapbox/standard",
  standardSatellite: "mapbox://styles/mapbox/standard-satellite",
  streets: "mapbox://styles/mapbox/streets-v12",
  outdoors: "mapbox://styles/mapbox/outdoors-v12",
  light: "mapbox://styles/mapbox/light-v11",
  dark: "mapbox://styles/mapbox/dark-v11",
  satellite: "mapbox://styles/mapbox/satellite-v9",
  satelliteStreets: "mapbox://styles/mapbox/satellite-streets-v12",
  navigationDay: "mapbox://styles/mapbox/navigation-day-v1",
  navigationNight: "mapbox://styles/mapbox/navigation-night-v1",
};

// Map style display names (for user-friendly labels)
export const mapStyleDisplayNames = {
  standard: "Standard",
  standardSatellite: "Standard Satellite",
  streets: "Streets",
  outdoors: "Outdoors",
  light: "Light",
  dark: "Dark",
  satellite: "Satellite",
  satelliteStreets: "Satellite Streets",
  navigationDay: "Navigation Day",
  navigationNight: "Navigation Night",
};
// App.js (updated and corrected)
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import PricingPage from './components/PricingPage';
import SignupPage from './components/SignupPage';
import SignIn from './components/SignIn';
import Workspace from './components/Workspace';
import Game from './components/game';
import Upload from './components/Upload';
import Explore from './components/Explore';
import Settings from './components/Settings';
import Sandbox from './components/Sandbox';
import Chat from './components/Chat';
import Hub from './components/Hub';
import './App.css';
import Generate from './components/Generate';
import { UserProfile, useUser, ClerkProvider } from "@clerk/clerk-react"; // Import ClerkProvider
import AccountPage from './components/AccountPage';
import ProductPage from './components/ProductPage';
import Usage from './components/Usage';
import Neo from './components/Neo';
import Home from './components/Home';
import Room from './components/Room';
import Tastemaker from './components/Tastemaker';
import Share from './components/Share';
import Onboard from './components/Onboard';
import DocsPage from './components/DocsPage';
import Intercom from '@intercom/messenger-js-sdk'; // Import Intercom SDK
import Engine from './components/Engine'; // Import Engine Component
import Api from './components/Api';

function App() {
    const { user, isLoaded } = useUser(); // Use Clerk's useUser hook to get user data and loading state
    const [isComputeModalOpenApp, setIsComputeModalOpenApp] = useState(false);

    const openComputeModalApp = () => {
        setIsComputeModalOpenApp(true);
    };

    const closeComputeModalApp = () => {
        setIsComputeModalOpenApp(false);
    };


    useEffect(() => {
        if (!isLoaded && !user) { // Don't initialize Intercom if user is still loading or not logged in
            return;
        }
        if (user && user.id) { // Initialize Intercom only if user data is available and user is logged in
            Intercom('update', { // Use 'update' instead of calling Intercom() again if user object changes
                app_id: 'ui2e9mxf', // Replace with your actual Intercom App ID
                user_id: user.id,
                name: user.fullName || user.firstName + (user.lastName ? ' ' + user.lastName : ''), // Use fullName if available, otherwise combine first and last name
                email: user.emailAddresses[0]?.emailAddress, // Get the first email address from Clerk's emailAddresses array
                created_at: user.createdAt ? Math.floor(user.createdAt.getTime() / 1000) : undefined, // Convert createdAt Date to Unix timestamp (seconds), handle potential null
            });
        } else {
            Intercom('shutdown'); // Shutdown Intercom if user logs out
        }
    }, [user, isLoaded]); // Re-initialize Intercom if user object or loading state changes


    return (
        <Router>
            <div className="App">
            
                <Routes>
                    <Route path="/" element={<Home openComputeModal={openComputeModalApp} />} />
                    <Route path="/pricing" element={<PricingPage />} />
                    <Route path="/signup/*" element={<SignupPage />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/workspace" element={<Workspace />} />
                    <Route path="/workspace/explore" element={<Explore />} />
                    <Route path="/map" element={<Explore />} />
                    <Route path="/api" element={<Api />} />
                    <Route path="/room" element={<Room />} />
                    <Route path="/neo" element={<Neo/>} />
                    <Route path="/game" element={<Navigate to="/" replace />} />
                    <Route path="/games" element={<Navigate to="/" replace />} />
                    <Route path="/usage" element={<Usage />} />
                    <Route path="/product" element={<ProductPage />} />
                    <Route path="/generate" element={<Navigate to="/" replace />} />
                    <Route path="/adjust" element={<Generate />} /> {/* Assuming Generate component should be rendered at /adjust */}
                    <Route path="/upload" element={<Upload/>} />
                    <Route path="/workspace/explore/:map_id" element={<Explore />} />
                    <Route path="/workspace/settings" element={<Settings />} />
                    <Route path="/workspace/sandbox" element={<Sandbox />} />
                    <Route path="/onboard" element={<Onboard />} />
                    <Route path="/hub" element={<Hub />} />
                    <Route path="/docs" element={<DocsPage />} />
                    <Route path="/chat" element={<Chat />} />
                    {/* Removed SSOCallback routes and component */}
                    <Route path="/account" element={<AccountPage />} />
                    <Route path="/tastemaker" element={<Tastemaker />} />
                    <Route path="/share/:id" element={<Share />} />
                    <Route path="/:query" element={<Engine />} /> {/* NEW ROUTE FOR ENGINE */}
                </Routes>
            </div>
        </Router>
    );
}


// Removed SSOCallback Component - No longer needed in this way

export default App;
// src/components/AccountPage.js
import React from 'react';
import { UserProfile } from "@clerk/clerk-react";
import './AccountPage.css'; // Import CSS file

function AccountPage() {
    return (
        <div className="account-page-container"> {/* Apply CSS class */}
            <UserProfile appearance="dark" /> {/* ADD appearance="dark" */}
        </div>
    );
}

export default AccountPage;
// Engine.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Engine.css'; // Make sure to use the updated Engine.css
import 'animate.css';
import { useParams, useNavigate, Link } from 'react-router-dom'; // Import Link
import { useAuth } from "@clerk/clerk-react";
import { FaPlay, FaDownload, FaExpand, FaCheck, FaXmark, FaShare, FaClipboardCheck, FaSpinner } from 'react-icons/fa6';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

// Sign In Modal Component - Reused from Home.js - Ensure this is consistent with your Home.js version
const SignInModal = ({ onClose }) => {
    return (
        <div className="engine-signin-modal-overlay" onClick={onClose}>
            <div className="engine-signin-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="engine-signin-modal-close-button" onClick={onClose}><FaXmark /></button>
                <h2 className="engine-signin-modal-title">Sign in to continue</h2>
                <p className="engine-signin-modal-subtitle">Create an account or sign in to send messages</p>
                <div className="engine-signin-modal-buttons">
                    <Link to="/signin" className="engine-signin-modal-signin-button">Sign In</Link>
                    <Link to="/signup" className="engine-signin-modal-signup-button">Sign up for free</Link>
                </div>
            </div>
        </div>
    );
};

// Asset Modal Component
const AssetModal = ({ asset, onClose }) => {
    if (!asset) return null;

    return (
        <div className="engine-asset-modal-overlay" onClick={onClose}>
            <div className="engine-asset-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="engine-asset-modal-close-button" onClick={onClose}><FaXmark /></button>
                {asset.media_type === 'image' ? (
                    <img src={asset.file_url} alt={asset.name} className="engine-asset-modal-media" />
                ) : asset.media_type === 'video' || asset.media_type === '3d' ? (
                    <video src={asset.file_url} controls className="engine-asset-modal-video" />
                ) : asset.media_type === 'sound' || asset.media_type === 'voice' ? (
                    <>
                        <img src={asset.thumbnail || '/audio_thumbnail_fallback.png'} alt={asset.name} className="engine-asset-modal-media" style={{maxWidth: '300px', maxHeight: '300px'}}/>
                        <audio src={asset.file_url} controls className="engine-asset-modal-audio" />
                    </>
                ) : (
                    <p>Unsupported asset type.</p>
                )}
            </div>
        </div>
    );
};


function Engine() {
    const { query } = useParams();
    const navigate = useNavigate();
    const { userId, isSignedIn } = useAuth();
    const [taskStatus, setTaskStatus] = useState('pending');
    const [taskAssets, setTaskAssets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const pollingIntervalRef = useRef(null);
    const [sendButtonState, setSendButtonState] = useState('send');
    const sendButtonTimer = useRef(null);
    const [showSignInModal, setShowSignInModal] = useState(false); // State for SignInModal visibility
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [taskIdForShare, setTaskIdForShare] = useState(null);
    const [assetModalOpen, setAssetModalOpen] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const truncatedQuery = query && query.length > 14 ? `${query.substring(0, 14)}...` : query;


    // Function to close Sign In Modal
    const closeSignInModal = () => {
        setShowSignInModal(false);
    };

    const closeAssetModal = () => {
        setAssetModalOpen(false);
        setSelectedAsset(null);
    };

    const startTaskPolling = useCallback((currentTaskId) => {
        setTaskStatus('processing');
        setTaskAssets([]);
        setTaskIdForShare(currentTaskId); // Set task ID for share link

        if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
        }

        pollingIntervalRef.current = setInterval(async () => {
            try {
                const response = await fetch(`https://embed.axv.ai/api/task/${currentTaskId}`);
                if (!response.ok) {
                    console.error('Task polling failed with status:', response.status);
                    setTaskStatus('error');
                    clearInterval(pollingIntervalRef.current);
                    return;
                }
                const data = await response.json();
                if (data.isSuccess && data.task) {
                    setTaskStatus(data.task.status);

                    if (data.asset) {
                        setTaskAssets(data.asset);
                    }

                    if (data.task.status === 'completed') {
                        clearInterval(pollingIntervalRef.current);
                        setSendButtonState('check');
                        sendButtonTimer.current = setTimeout(() => {
                            setSendButtonState('send');
                        }, 2000);
                    } else if (data.task.status === 'error') {
                        clearInterval(pollingIntervalRef.current);
                        setTaskStatus('error');
                    }
                } else {
                    console.error('Invalid task response format:', data);
                    setTaskStatus('error');
                    clearInterval(pollingIntervalRef.current);
                }
            } catch (error) {
                console.error('Error during task polling:', error);
                setTaskStatus('error');
                clearInterval(pollingIntervalRef.current);
            }
        }, 1000);

        return () => {
            if (pollingIntervalRef.current) {
                clearInterval(pollingIntervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        return () => {
            if (pollingIntervalRef.current) {
                clearInterval(pollingIntervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setTaskStatus('pending');
            setTaskAssets([]);

            if (!isSignedIn) {
                setIsLoading(false);
                return; // Do not proceed with API call - isSignedIn check handled in rendering now
            }

            if (!userId) {
                setIsLoading(false); // Stop loading if userId is not available but isSignedIn is true (unlikely but possible edge case)
                return;
            }


            try {
                const response = await fetch('https://embed.axv.ai/api/block/agent', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ query: query, user_id: userId })
                });

                if (response.ok) {
                    const responseData = await response.json();
                    if (responseData && responseData.data_id) {
                        startTaskPolling(responseData.data_id);
                    } else {
                        console.error('data_id not received in response:', responseData);
                        setTaskStatus('error');
                    }
                } else {
                    console.error('API request failed', response.status);
                    setTaskStatus('error');
                }
            } catch (error) {
                console.error('Error sending query:', error);
                setTaskStatus('error');
            } finally {
                setIsLoading(false);
            }
        };

        if (query && isSignedIn && userId) { // Only fetch data if signed in and userId is available
            fetchData();
        }
        // Removed isSignedIn check here, isSignedIn check now dictates rendering of auth card
    }, [query, startTaskPolling, isSignedIn, userId, navigate]);

    const handleDownloadAllAssets = () => {
        if (taskAssets && taskAssets.length > 0) {
            const zip = new JSZip();
            const assetsFolder = zip.folder("assets");
            const filePromises = [];

            taskAssets.forEach(asset => {
                const promise = new Promise(async (resolve) => {
                    try {
                        const response = await fetch(asset.file_url);
                        if (!response.ok) {
                            console.error(`Failed to fetch asset: ${asset.name} - Status: ${response.status}`);
                            resolve();
                            return;
                        }
                        const blob = await response.blob();
                        let fileExtension = '';

                        if (blob.type) {
                            const contentType = blob.type;
                            if (contentType === 'image/png') fileExtension = 'png';
                            else if (contentType === 'image/jpeg' || contentType === 'image/jpg') fileExtension = 'jpg';
                            else if (contentType === 'audio/mpeg') fileExtension = 'mp3';
                            else if (contentType === 'video/mp4') fileExtension = 'mp4';
                        }

                        const fileNameInZip = fileExtension ? `${asset.name}.${fileExtension}` : `${asset.name}.dat`;
                        assetsFolder.file(fileNameInZip, blob);

                    } catch (error) {
                        console.error(`Error processing asset: ${asset.name}`, error);
                    } finally {
                        resolve();
                    }
                });
                filePromises.push(promise);
            });

            Promise.all(filePromises).then(() => {
                zip.generateAsync({ type: "blob" }).then(content => {
                    saveAs(content, "neocu_assets.zip");
                });
            });
        }
    };

    const handleExpandModalOpen = (asset) => {
        setSelectedAsset(asset);
        setAssetModalOpen(true);
    };

    const handleShareClick = async () => {
        if (taskIdForShare) {
            const shareLink = `${window.location.origin}/asset/${taskIdForShare}`;
            try {
                await navigator.clipboard.writeText(shareLink);
                setNotificationMessage('Link copied to clipboard!');
                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 3000); // Hide after 3 seconds
            } catch (err) {
                console.error('Failed to copy link: ', err);
                setNotificationMessage('Failed to copy link.');
                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 3000);
            }
        }
    };


    let assetsGridClass = "engine-assets-grid";
    if (taskAssets.length === 1) {
        assetsGridClass = "engine-assets-grid-single";
    } else if (taskAssets.length === 2 || taskAssets.length === 3) {
        assetsGridClass = "engine-assets-grid-few";
    }


    return (
        <div className="engine-container engine-page"> {/* Added engine-page class here for specific page styling if needed */}
            <div className="engine-heading-container">
                <div className="engine-heading-left">
                    {isLoading || taskStatus === 'processing' ? (
                        <FaSpinner className="engine-status-icon animate-spin" />
                    ) : taskStatus === 'completed' ? (
                        <FaCheck className="engine-status-icon engine-status-completed" />
                    ) : null}
                    <h1 className="engine-main-heading">
                        {truncatedQuery || "Engine"}
                    </h1>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <button className="engine-share-button" onClick={handleShareClick} title="Share Link">
                        <FaShare />
                    </button>
                    <button className="engine-download-all-button" onClick={handleDownloadAllAssets} title="Download All Assets">
                        <FaDownload />
                    </button>
                </div>
            </div>

            {isLoading ? (
                <div className="engine-loading-screen">
                    <div className="engine-custom-loader engine-loading-spinner"></div> {/* Updated class names */}
                </div>
            ) : !isSignedIn || taskStatus === 'error' ? ( // Show auth/error card if not signed in or error
                <div className="engine-auth-error-card">
                    <p className="engine-auth-error-text">
                        {isSignedIn ? "Error fetching assets." : "Sign in or Sign up to View"}
                    </p>
                    {!isSignedIn && (
                        <div className="engine-auth-buttons">
                            <Link to="/signin" className="engine-auth-button">Sign In</Link>
                            <Link to="/signup" className="engine-auth-button signup">Sign Up</Link>
                        </div>
                    )}
                </div>
            ) : (
                <>
                    {/* REMOVED TASK MESSAGES SECTION */}

                    {taskAssets.length > 0 && (
                        <div className="engine-assets-container">
                            <div className={assetsGridClass}>
                                {taskAssets.map((asset, index) => {
                                    const isPending = asset.status === 'pending';
                                    const thumbnailSrc = isPending ? '/gradient_orange.webp' : asset.thumbnail || (asset.media_type === 'sound' || asset.media_type === 'voice' ? '/audio_thumbnail_fallback.png' : '/video_thumbnail_fallback.png');

                                    return (
                                        <div key={asset._id} className="engine-asset-item animate__animated animate__fadeInRight"> {/* Updated class name */}
                                            <div className="engine-asset-media-container"> {/* Updated class name */}
                                                {isPending ? (
                                                    <div className="engine-pending-thumbnail-container"> {/* Updated class name */}
                                                        <img src={thumbnailSrc} alt="Pending Asset" className="engine-asset-media engine-thumbnail engine-pending-thumbnail" />
                                                        <div className="engine-welcome-asset-loader"> {/* Updated class name */}
                                                            <div className="engine-custom-loader engine-welcome-asset-circle-loader"></div>
                                                        </div>
                                                    </div>
                                                ) : asset.media_type === 'image' ? (
                                                    <img src={asset.file_url} alt={asset.name} className="engine-asset-media engine-thumbnail" />
                                                ) : asset.media_type === 'video' ? (
                                                    <img src={thumbnailSrc} alt={asset.name} className="engine-asset-media engine-thumbnail" />
                                                ) : asset.media_type === '3d' ? (
                                                    <video
                                                        src={asset.thumbnail}
                                                        className="engine-asset-media engine-thumbnail engine-thumbnail-video"
                                                        autoPlay
                                                        loop
                                                        muted
                                                    />
                                                ) : (asset.media_type === 'sound' || asset.media_type === 'voice') ? (
                                                    <img src={thumbnailSrc} alt={asset.name} className="engine-asset-media engine-thumbnail" />
                                                ) : null}
                                                {!isPending && (
                                                    <div className="engine-asset-overlay"> {/* Updated class name */}
                                                        {asset.media_type === 'video' || asset.media_type === '3d' || asset.media_type === 'sound' || asset.media_type === 'voice' ? (
                                                            <div className="engine-video-icon-overlay" onClick={() => handleExpandModalOpen(asset)}> {/* Updated class name */}
                                                                <div className="engine-video-play-button"> {/* Updated class name */}
                                                                    <FaPlay className="engine-video-overlay-icon" /> {/* Updated class name */}
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                        <a href={asset.file_url} download={asset.name} className="engine-download-icon-overlay"> {/* Updated class name */}
                                                            <FaDownload className="engine-download-overlay-icon" /> {/* Updated class name */}
                                                        </a>
                                                        <button className="engine-expand-icon-overlay" onClick={(e) => { e.stopPropagation(); handleExpandModalOpen(asset); }}> {/* Updated class name */}
                                                            <FaExpand className="engine-expand-overlay-icon" /> {/* Updated class name */}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="engine-asset-name-overlay">{asset.name}</div> {/* Updated class name */}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {taskAssets.length === 0 && taskStatus === 'completed' && !showSignInModal && query && ( // Only show no results if no assets after completion, not showing sign-in modal and query exists
                        <div className="engine-no-results-container">
                            <p className="engine-no-results-text">No assets generated for this query.</p>
                        </div>
                    )}
                </>
            )}
            {showSignInModal && (
                <SignInModal onClose={closeSignInModal} />
            )}
            {assetModalOpen && selectedAsset && (
                <AssetModal asset={selectedAsset} onClose={closeAssetModal} />
            )}
            {showNotification && (
                <div className="engine-notification">
                    {notificationMessage} <FaClipboardCheck />
                </div>
            )}
        </div>
    );
}

export default Engine;
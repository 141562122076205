import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SignOutButton, useUser, useAuth } from "@clerk/clerk-react";
import { SignedIn, SignedOut } from "@clerk/clerk-react";
import './Header.css';
import { FaExternalLinkAlt, FaChevronDown, FaChevronRight, FaPaperclip, FaPaperPlane, FaCheck, FaMicrophone, FaCheckCircle } from 'react-icons/fa';
import { IoServer } from 'react-icons/io5';
import skysLogo from './skyss.png';
import AccountPage from './AccountPage';
import { debounce } from './utils';
import { FaEarthAmericas } from 'react-icons/fa6'; // Import Explorer Icon
import { FaXmark, FaFilePdf, FaFileCsv, FaDownload } from 'react-icons/fa6'; // Import for modals and thumbnails

function Header({ attachedFileInfos, setAttachedFileInfos, setHomeMapEmbedUrl }) {
    const location = useLocation();
    const isHomePage = location.pathname === '/';
    const isRoomOrShareRoute = location.pathname.includes('room') || location.pathname.includes('share');
    const { isSignedIn, user } = useUser();
    const { userId } = useAuth();
    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [computeValue, setComputeValue] = useState(0);
    const [query, setQuery] = useState('');
    const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
    const [showAutocompleteDropdown, setShowAutocompleteDropdown] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isAttachButtonActive, setIsAttachButtonActive] = useState(false);
    const [sendButtonState, setSendButtonState] = useState('send');
    const inputRef = useRef(null);
    const autocompleteDropdownRef = useRef(null);
    const sendButtonTimer = useRef(null);
    const [roomsState, setRoomsState] = useState(false);
    const [showSignInModalHeader, setShowSignInModalHeader] = useState(false); // State for sign-in modal in Header

    // Attachment Functionality State and Refs (Moved from Home.js)
    const fileInputRef = useRef(null);
    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [uploadPurpose, setUploadPurpose] = useState('attach'); // Default to attach from header
    const [uploadType, setUploadType] = useState('single');
    const [selectedFileState, setSelectedFileState] = useState(null);
    const [selectedFileNameState, setSelectedFileNameState] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('general'); // Default category for header attach
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [lastUploadedFileInfo, setLastUploadedFileInfo] = useState(null);


    // Modal State Management is now within Header
    const [isComputeModalOpen, setIsComputeModalOpen] = useState(false);

    const openComputeModal = () => {
        console.log("openComputeModal called from Header");
        setIsComputeModalOpen(true);
    };

    const closeComputeModal = () => {
        setIsComputeModalOpen(false);
    };


    const toggleUserDropdown = () => {
        setShowUserDropdown(!showUserDropdown);
    };

    const closeUserDropdown = () => {
        setShowUserDropdown(false);
    };

    const openAccountModal = () => {
        setShowAccountModal(true);
        closeUserDropdown();
    };

    const closeAccountModal = () => {
        setShowAccountModal(false);
    };

    const handleBillingClick = () => {
        console.log("Billing clicked in dropdown");
        openComputeModal();
        closeUserDropdown();
    };

    const fetchComputeValue = async () => {
        if (!userId) {
            console.warn('userId is not available, skipping compute value fetch.');
            setComputeValue(0);
            return;
        }
        try {
            const response = await fetch(`https://api.neocu.com/api/organization/compute?user_id=${userId}`);
            if (!response.ok) {
                console.error('Failed to fetch compute value:', response.status, response.statusText);
                setComputeValue(0);
                return;
            }
            const data = await response.json();
            setComputeValue(data.compute || 0);
        } catch (error) {
            console.error('Error fetching compute value:', error);
            setComputeValue(0);
        }
    };

    useEffect(() => {
        if (isSignedIn) {
            fetchComputeValue();

            const intervalId = setInterval(() => {
                fetchComputeValue();
            }, 5000);

            return () => clearInterval(intervalId);
        } else {
            setComputeValue(0);
        }
    }, [isSignedIn, userId]);


    const getComputeValueColor = () => {
        if (computeValue >= 100 && computeValue <= 400) {
            return 'yellow';
        } else if (computeValue >= 401) {
            return 'green';
        } else {
            return 'red';
        }
    };

    const handleSend = async (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (!isSignedIn) {
            console.log("Not signed in, opening sign-in modal");
            setShowSignInModalHeader(true); // Open sign-in modal in Header
            return;
        }

        if (!query.trim()) {
            console.log("Query is empty, not sending");
            return;
        }

        setSendButtonState('loading');
        console.log("Sending query to API:", query);

        try {
            console.log("API Request: Sending to /api/block/earth");
            const response = await fetch('https://api.neocu.com/api/block/earth', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: query,
                    rooms: roomsState,
                    files: Array.isArray(attachedFileInfos) ? attachedFileInfos.map(info => info._id) : [],
                    user_id: userId || '00',
                    suggestion_id: null
                })
            });
            console.log("API Response Received");

            if (response.ok) {
                const responseData = await response.json();
                console.log("API Response Data:", responseData);
                setSendButtonState('check');
                if (responseData && responseData.generation) {
                    setHomeMapEmbedUrl(responseData.generation);
                } else {
                    console.error('generation field not received in response:', responseData);
                }
                setTimeout(() => {
                    setSendButtonState('send');
                }, 2000);
            } else if (response.status === 402) {
                openComputeModal();
                setSendButtonState('send');
            }
            else {
                console.error('API request failed', response.status, response);
                setSendButtonState('send');
            }
        } catch (error) {
            console.error('Error sending query:', error);
            setSendButtonState('send');
        } finally {
            setQuery('');
        }
    };


    const fetchAutocomplete = useCallback(async (currentQuery) => {
        if (!isSignedIn) { // Conditional autocomplete based on sign-in
            setAutocompleteSuggestions([]);
            setShowAutocompleteDropdown(false);
            return;
        }

        if (!currentQuery.trim()) {
            setAutocompleteSuggestions([]);
            setShowAutocompleteDropdown(false);
            return;
        }

        try {
            const response = await fetch('https://api.neocu.com/api/block/autocomplete', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: currentQuery }),
            });

            if (response.ok) {
                const data = await response.json();
                setAutocompleteSuggestions(data.autocomplete || []);
                setShowAutocompleteDropdown(data.autocomplete && data.autocomplete.length > 0);
            } else {
                console.error('Failed to fetch autocomplete suggestions:', response.status);
                setAutocompleteSuggestions([]);
                setShowAutocompleteDropdown(false);
            }
        } catch (error) {
            console.error('Error fetching autocomplete suggestions:', error);
            setAutocompleteSuggestions([]);
            setShowAutocompleteDropdown(false);
        }
    }, [isSignedIn]); // isSignedIn dependency added

    const debouncedFetchAutocomplete = useCallback(
        debounce((queryText) => fetchAutocomplete(queryText), 300),
        [fetchAutocomplete]
    );

    useEffect(() => {
        debouncedFetchAutocomplete(query);
    }, [query, debouncedFetchAutocomplete]);

    const handleAutocompleteSuggestionClick = (suggestion) => {
        setQuery(suggestion);
        setShowAutocompleteDropdown(false);
        inputRef.current.focus();
        handleSend();
    };

    useEffect(() => {
        const handleClickOutsideDropdown = (event) => {
            if (autocompleteDropdownRef.current && !autocompleteDropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
                setShowAutocompleteDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDropdown);
        };
    }, []);

    const handleAttachClick = (e) => {
        e.stopPropagation();
        if (!isSignedIn) {
            setShowSignInModalHeader(true); // Open sign-in modal in Header if not signed in
            return;
        }
        setUploadModalVisible(true); // Open upload modal if signed in
        setIsAttachButtonActive(true);
        setTimeout(() => setIsAttachButtonActive(false), 300);
    };


    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend(e);
        }
    };

    const closeSignInModalHeader = () => {
        setShowSignInModalHeader(false);
    };


    // Attachment Modal Handlers (Moved from Home.js - adjusted for Header)
    const handleUploadModalOpen = (e, purpose = 'attach') => { // Purpose defaults to 'attach' in Header
        e.stopPropagation();
        if (!isSignedIn && purpose === 'attach') {
            setShowSignInModalHeader(true); // Open sign-in modal in Header
            return;
        }
        console.log("handleUploadModalOpen called from Header with purpose:", purpose);
        setUploadModalVisible(true);
        setUploadType('single');
        setUploadPurpose(purpose);
        setSelectedCategory('general'); // Default category for header attach
        setSelectedFileNameState('');
        setSelectedFileState(null);
        setUploadSuccess(false);
        setUploadError(false);
    };

    const handleUploadModalClose = () => {
        setUploadModalVisible(false);
        setSelectedFileState(null);
        setSelectedFileNameState('');
        setSelectedCategory('general'); // Reset to default category
        setUploading(false);
        setUploadSuccess(false);
        setUploadError(false);
        setUploadPurpose('attach'); // Reset to default purpose
    };

    const handleUploadTypeChange = (type) => {
        setUploadType(type);
    };

    const handleFileSelect = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFileState(file);
            setSelectedFileNameState(file.name);
        }
    };


    const handleFileDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const file = event.dataTransfer.files[0];
        if (file) {
            setSelectedFileState(file);
            setSelectedFileNameState(file.name);
        }
    };

    const handleFileDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleFileDragLeave = (event) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const handleUpload = async ({ assetName }) => {
        console.log("handleUpload called from Header", { assetName, selectedFileState, selectedCategory, uploadPurpose });

        if (!selectedFileState) {
            alert('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFileState);
        formData.append('user_id', userId || '00');
        formData.append('category', 'general'); // Always 'general' for header attach
        formData.append('type', 'file'); // Always 'file' for header attach


        setUploading(true);
        setUploadSuccess(false);
        setUploadError(false);

        try {
            const response = await fetch('https://api.neocu.com/api/image/upload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                setSelectedFileState(null);
                setSelectedFileNameState('');
                setSelectedCategory('general'); // Reset category
                setUploading(false);
                setUploadSuccess(true);

                if (uploadPurpose === 'attach') {
                    setAttachedFileInfos(prevInfos => [...prevInfos, data.data]);
                }


            } else {
                setUploadError(true);
                console.error('Upload failed:', response.statusText);
            }
        } catch (error) {
            setUploadError(true);
            console.error('Error during upload:', error);
        } finally {
            setUploading(false);
            setTimeout(() => setUploadSuccess(false), 3000);
        }
    };

    const handleRemoveAttachedFile = (fileInfoToRemove) => {
        setAttachedFileInfos(prevInfos => prevInfos.filter(info => info._id !== fileInfoToRemove._id));
    };


    return (
        <header className={`${isHomePage ? 'home-page-header' : 'default-header'} ${isRoomOrShareRoute ? 'room-share-header' : ''}`}>
            <div className="header-left">
                <strong style={{ fontSize: '1.5em', color: '#90D6FF' }}> Dendric </strong>
            </div>

             {/* Centered Input Section in Header */}
             <div className="header-middle">
                <div className="input-wrapper-header">
                    <div className="InputContainer">
                        <label htmlFor="input" className="labelforsearch">
                            <FaEarthAmericas className="searchIcon light-blue-icon" /> {/* Explorer Icon */}
                        </label>
                        <input
                            ref={inputRef}
                            type="text"
                            name="text"
                            className="input-header"
                            id="input"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            onKeyDown={handleKeyDown}
                            onFocus={() => { setIsInputFocused(true); setShowAutocompleteDropdown(isSignedIn && autocompleteSuggestions.length > 0 && query.trim() !== '') }} // Conditional autocomplete show
                            onBlur={() => setIsInputFocused(false)}
                            placeholder="Discover the world"
                            onClick={() => setShowAutocompleteDropdown(isSignedIn && autocompleteSuggestions.length > 0 && isInputFocused && query.trim() !== '')} // Conditional autocomplete show
                        />

                         <div className="input-buttons-header-inline">
                            <button
                                className="micButton header-button-style" // Added header-button-style
                                type="button"
                                onClick={() => console.log("Voice Search Clicked")}
                            >
                                <FaMicrophone className="micIcon light-blue-icon" style={{ fontSize: '1em' }} />
                            </button>
                            <button
                                className="icon-button-header header-button-style" // Added header-button-style
                                onClick={handleAttachClick}
                                type="button"
                            >
                                <FaPaperclip className="light-blue-icon" />
                            </button>
                            <button
                                className="send-button-header header-button-style" // Added header-button-style
                                onClick={handleSend}
                                disabled={sendButtonState === 'loading' || !query.trim()}
                                type="button"
                            >
                                {sendButtonState === 'loading' ? (
                                    <div className="custom-loader send-button-icon-header"></div>
                                ) : sendButtonState === 'check' ? (
                                    <FaCheck color="green" size={16} className="send-button-icon-header light-blue-icon" />
                                ) : (
                                    <FaPaperPlane className="send-button-icon-header light-blue-icon" />
                                )}
                            </button>
                        </div>
                    </div>


                    {showAutocompleteDropdown && (
                        <div className="autocomplete-dropdown-header" ref={autocompleteDropdownRef}>
                            {autocompleteSuggestions.map((suggestion, index) => (
                                <div
                                    key={index}
                                    className="autocomplete-item-header"
                                    onClick={() => handleAutocompleteSuggestionClick(suggestion)}
                                >
                                    {suggestion}
                                </div>
                            ))}
                        </div>
                    )}

                </div>
            </div>


            <div className="header-right-head">
                {isSignedIn && user ? (
                    <>
                        <div className="user-section">
                            <button className="user-button" onClick={toggleUserDropdown}>
                                {user?.fullName || user?.firstName || user?.username || "Account"} <FaChevronDown style={{ fontSize: '0.7em', marginLeft: '5px' }} />
                            </button>
                            {showUserDropdown && (
                                 <div
                                 className="user-dropdown-head"
                                 onClick={(e) => e.stopPropagation()}
                                 style={{ overflow: 'visible', overflowY: 'visible' }}
                             >
                                    <div className="dropdown-item" onClick={openAccountModal}>
                                        Profile
                                    </div>
                                    <div className="dropdown-item" onClick={handleBillingClick}>
                                        Billing
                                    </div>
                                    <div className="dropdown-item signout-dropdown-item">
                                        <SignOutButton signOutCallback={() => window.location.reload()}>
                                            Sign Out
                                        </SignOutButton>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <Link to="/signin" className="sign-in-button">Sign In</Link>
                        <Link to="/signup" className="sign-up-button">Sign Up</Link>
                    </>
                )}
            </div>
             {showAccountModal && (
                <AccountModal onClose={closeAccountModal}>
                    <AccountPage />
                </AccountModal>
            )}
            {isComputeModalOpen && (
                <ComputeModal onClose={closeComputeModal} computeValue={computeValue} />
            )}
            {showSignInModalHeader && ( // Sign-in modal in Header
                <SignInModalHeader onClose={closeSignInModalHeader} />
            )}
            {uploadModalVisible && ( // Upload Modal in Header
                <UploadModalHeader
                    onClose={handleUploadModalClose}
                    uploadType={uploadType}
                    onUploadTypeChange={handleUploadTypeChange}
                    onFileSelect={handleFileSelect}
                    onFileDrop={handleFileDrop}
                    onFileDragOver={handleFileDragOver}
                    onFileDragLeave={handleFileDragLeave}
                    onCategoryChange={handleCategoryChange}
                    onUpload={handleUpload}
                    selectedFileName={selectedFileNameState}
                    selectedCategory={selectedCategory}
                    uploading={uploading}
                    uploadSuccess={uploadSuccess}
                    uploadError={uploadError}
                    isDragging={isDragging}
                    selectedFile={selectedFileState}
                    setLastUploadedFileId={setLastUploadedFileInfo}
                    lastUploadedFileId={lastUploadedFileInfo}
                    uploadPurpose={uploadPurpose}
                />
            )}
        </header>
    );
}


const AccountModal = ({ onClose, children }) => {
    return (
        <div className="account-modal-overlay" onClick={onClose}>
            <div className="account-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close-button" onClick={onClose}>X</button>
                {children}
            </div>
        </div>
    );
};


const ComputeModal = ({ onClose, computeValue }) => {
    console.log("ComputeModal rendered in Header.js");

    const tiersData = [
        {
            tier: "Free",
            price: "$0",
            description: "Explore how AI can help with everyday tasks",
            features: [
                "Access to GPT-4o mini",
                "Real-time data from the web with search",
                "Limited access to GPT-4o and o3-mini",
                "Limited access to file uploads, data analysis, image generation, and voice mode",
                "Use custom GPTs"
            ],
            buttonLink: "https://pay.neocu.com/b/14k15dcuCb4r9a07te",
            cardClass: "free-tier" // Removed specific color class, using general card styling
        },
        {
            tier: "Pro",
            price: "$20",
            description: "Enhanced features for regular users.",
            features: [
                "Expanded access to advanced models",
                "Priority support",
                "Faster response times",
                "Increased query limits"
            ],
            buttonLink: "https://pay.neocu.com/b/5kA8xFfGO2xVdqg6pb",
            cardClass: "pro-tier" // Removed specific color class, using general card styling
        },
        {
            tier: "Explorer",
            price: "$200",
            description: "For heavy users needing extensive access.",
            features: [
                "Unlimited queries",
                "Full access to all models",
                "24/7 premium support",
                "Dedicated account manager"
            ],
            buttonLink: "https://pay.neocu.com/b/7sI15d3Y6fkHdqg6pc",
            cardClass: "explorer-tier" // Removed specific color class, using general card styling
        },
        {
            tier: "Teams",
            price: "$40",
            description: "Collaborative features for team use.",
            features: [
                "Multi-user access",
                "Team collaboration tools",
                "Shared billing and usage",
                "Customizable team dashboards"
            ],
            buttonLink: "https://pay.neocu.com/b/00g3dlgKS7Sffyo7ta",
            cardClass: "teams-tier" // Removed specific color class, using general card styling
        }
    ];

    const [selectedTier, setSelectedTier] = useState("Free");

    const handleTierSelect = (tierName) => {
        setSelectedTier(tierName);
    };

    const currentTierData = tiersData.find(tier => tier.tier === selectedTier);


    return (
        <div className="compute-modal-overlay" onClick={onClose} style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}>

            <div className="compute-modal-content compute-pricing-modal" onClick={(e) => e.stopPropagation()}>

                <button className="modal-close-button" onClick={onClose}>X</button>

                <div className="compute-pricing-body">
                    <div className="pricing-sidebar">
                        {tiersData.map((tier, index) => (
                            <div
                                key={index}
                                className={`pricing-sidebar-item ${selectedTier === tier.tier ? 'selected' : ''}`}
                                onClick={() => handleTierSelect(tier.tier)}
                            >
                                {tier.tier}
                                {selectedTier === tier.tier && <FaChevronRight className="selected-icon" />}
                            </div>
                        ))}
                    </div>
                    <div className="pricing-card-display">
                        {currentTierData && (
                            <div className={`compute-pricing-card single-card-view`}> {/* Removed cardClass from here */}
                                <h3 className="card-title">{currentTierData.tier}</h3>
                                <p className="card-description">{currentTierData.description}</p>
                                <ul className="card-features-list">
                                    {currentTierData.features.map((feature, featureIndex) => (
                                        <li key={featureIndex} className="card-feature-item">
                                            <FaCheckCircle className="feature-icon" /> {feature}
                                        </li>
                                    ))}
                                </ul>
                                <div className="card-price">{currentTierData.price} /month</div>
                                <div className="card-buttons">
                                    <a href={currentTierData.buttonLink} target="_blank" rel="noopener noreferrer" className="choose-plan-button">
                                        Get {currentTierData.tier}
                                    </a>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};


const SignInModalHeader = ({ onClose }) => {
    return (
        <div className="signin-modal-overlay" onClick={onClose}>
            <div className="signin-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="signin-modal-close-button" onClick={onClose}><FaXmark /></button>
                <h2 className="signin-modal-title">Sign in to continue</h2>
                <p className="signin-modal-subtitle">Create an account or sign in to send messages and attach files</p>
                <div className="signin-modal-buttons">
                    <Link to="/signin" className="signin-modal-signin-button">Sign In</Link>
                    <Link to="/signup" className="signin-modal-signup-button">Sign up for free</Link>
                </div>
            </div>
        </div>
    );
};


const UploadModalHeader = ({ onClose, uploadType, onUploadTypeChange, onFileSelect, onFileDrop, onFileDragOver, onFileDragLeave, onCategoryChange, onUpload, selectedFileName, selectedCategory, uploading, uploadSuccess, uploadError, isDragging, selectedFile, setLastUploadedFileId, lastUploadedFileId, uploadPurpose }) => {
    const [assetName, setAssetName] = useState(selectedFileName);
    const [filePreviewUrl, setFilePreviewUrl] = useState(null);

    useEffect(() => {
        setAssetName(selectedFileName);
    }, [selectedFileName]);

    useEffect(() => {
        if (selectedFile) {
            console.log("Selected File in useEffect:", selectedFile);
            if (selectedFile && selectedFile.type && (selectedFile.type.startsWith('image/') || selectedFile.type.startsWith('video/') )) {
                const url = URL.createObjectURL(selectedFile);
                setFilePreviewUrl(url);
                return () => URL.revokeObjectURL(url);
            } else {
                setFilePreviewUrl(null);
            }
        } else {
            setFilePreviewUrl(null);
        }
    }, [selectedFile]);


    const handleAssetNameChange = (e) => {
        setAssetName(e.target.value);
    };


    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="upload-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close-button" onClick={onClose}><FaXmark /></button>
                <h2 className="modal-title">{uploadPurpose === 'attach' ? 'Attach File' : 'Upload Asset'}</h2>

                <div className="upload-form">


                    {filePreviewUrl && (
                        <div className="file-preview-container">
                            {selectedFile && selectedFile.type && selectedFile.type.startsWith('image/') ? (
                                <img src={filePreviewUrl} alt="File Preview" className="file-preview-image" />
                            ) : selectedFile && selectedFile.type && (selectedFile.type.startsWith('video/')) ? (
                                <video src={filePreviewUrl} controls className="file-preview-video" />
                            ) : null}
                        </div>
                    )}


                    <div
                        className={`upload-drop-area ${isDragging ? 'dragging' : ''}`}
                        onDrop={onFileDrop}
                        onDragOver={onFileDragOver}
                        onFileDragLeave={onFileDragLeave}
                    >
                        <p>Drag and drop file here</p>
                        <input type="file" id="fileInput" className="file-input" onChange={onFileSelect} />
                        <label htmlFor="fileInput" className="file-input-label">Or browse files</label>
                        {selectedFile && <p className="selected-file-name">Selected file: {selectedFile.name}</p>}
                    </div>

                    {uploadError && <p className="upload-error">Upload failed. Please try again.</p>}
                    {uploadSuccess && <p className="upload-success">Upload successful!</p>}


                    <div className="modal-actions">
                        <button
                            className="modal-confirm-button"
                            onClick={() => onUpload({ assetName })}
                            disabled={uploading || !selectedFile}
                        >
                            {uploading ? 'Uploading...' : 'Upload'}
                        </button>
                        <button className="modal-cancel-button" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};


const AttachedFileThumbnailHeader = ({ fileInfo, onRemove }) => {

    if (!fileInfo) {
        return <div>Loading file info...</div>;
    }

    let thumbnailContent;
    if (fileInfo.category === 'PDF') {
        thumbnailContent = <FaFilePdf size={24} color="#F44336" />;
    } else if (fileInfo.category === 'CSV') {
        thumbnailContent = <FaFileCsv size={24} color="#4CAF50" />;
    } else if (fileInfo.media_type === 'image') {
        console.log("Thumbnail URL being used for image:", fileInfo.thumbnail);
        thumbnailContent = <img src={fileInfo.thumbnail} alt={fileInfo.name} className="attached-thumbnail-image" />;
    } else if (fileInfo.media_type === 'video' || fileInfo.media_type === 'presentation') {
        thumbnailContent = <img src={fileInfo.thumbnail || '/video_thumbnail_fallback.png'} alt={fileInfo.name} className="attached-thumbnail-image" />;
    } else if (fileInfo.media_type === '3d') {
        thumbnailContent = (
            <video
                src={fileInfo.thumbnail}
                className="attached-thumbnail-video"
                autoPlay
                loop
                muted
            />
        );
    }
    else if (fileInfo.media_type === 'sound' || fileInfo.media_type === 'voice') {
        thumbnailContent = <img src={fileInfo.thumbnail || '/audio_thumbnail_fallback.png'} alt={fileInfo.name} className="attached-thumbnail-image" />;
    }
    else {
        thumbnailContent = <div>{fileInfo.name}</div>;
    }


    return (
        <div className="attached-file-item-header">
            {thumbnailContent}
            <span className="attached-file-name" title={fileInfo.name}>{fileInfo.name}</span>
            <button className="remove-attached-file" onClick={() => onRemove(fileInfo)}><FaXmark /></button>
        </div>
    );
};


export default Header;
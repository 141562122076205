import React, { useState } from "react";
import "./SaveMap.css";

const SaveMapModal = ({ isOpen, onClose, mapRef, legendItems }) => {
  const [mapName, setMapName] = useState("");
  const [mapDescription, setMapDescription] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleNameChange = (event) => {
    setMapName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setMapDescription(event.target.value);
  };

  const handleSave = async () => {
    if (!mapName) return;

    setIsSaving(true);

    // Prepare map data for saving, handling multiple sources and layers
    const mapData = {
      name: mapName,
      description: mapDescription,
      // ... other fields (thumbnail, creator_name, etc. - fill these in)
      mapData: {
        style: mapRef.current.getStyle().name,
        center: {
          lng: mapRef.current.getCenter().lng,
          lat: mapRef.current.getCenter().lat,
        },
        zoom: mapRef.current.getZoom(),
        sources: legendItems.map((item) => item.source), // Array of sources
        layers: legendItems.flatMap((item) => item.layers), // Array of all layers
      },
    };

    try {
      const response = await fetch("https://embed.axv.ai/api/block/map/new", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mapData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log("Map saved successfully");
      onClose();
    } catch (error) {
      console.error("Error saving map:", error);
    } finally {
      setIsSaving(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>New Map</h2>
        <input
          type="text"
          placeholder="Map Name (required)"
          value={mapName}
          onChange={handleNameChange}
          required
        />
        <textarea
          placeholder="Map Description"
          value={mapDescription}
          onChange={handleDescriptionChange}
        />
        <div className="modal-actions">
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleSave} disabled={!mapName || isSaving}>
            {isSaving ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SaveMapModal;
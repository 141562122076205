// NeoAsset.js
import React, { useState, useEffect } from 'react';
import './NeoAsset.css';
import { FiDownload } from 'react-icons/fi'; // Import download icon

function NeoAsset({ taskId, onClose }) {
    const [assets, setAssets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [loadingTimeout, setLoadingTimeout] = useState(null); // State to manage timeout

    useEffect(() => {
        const fetchAssets = async () => {
            setError(null);
            try {
                const response = await fetch(`https://embed.axv.ai/api/block?data_id=${taskId}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                if (data && data.blockList) {
                    setAssets(data.blockList);
                } else {
                    setAssets([]);
                }
            } catch (e) {
                setError(e);
                console.error("Could not fetch assets:", e);
            } finally {
                // Simulate 2 second loading time after animation starts
                setLoadingTimeout(setTimeout(() => {
                    setIsLoading(false);
                }, 2000));
            }
        };

        setIsLoading(true); // Set loading to true at the start
        if (taskId) {
            fetchAssets();
        }

        return () => clearTimeout(loadingTimeout); // Clear timeout on unmount
    }, [taskId]); // Dependency array now only includes taskId - Fix for issue: Removed loadingTimeout

    const handleDownload = (fileUrl, fileName) => {
        if (!fileUrl) {
            console.error("No file URL provided for download.");
            return;
        }
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName || 'asset'; // Use fileName or default 'asset'
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <div className="neo-container">
            <div className="neo-card neo-asset-card">
                <div className="neo-asset-header">
                    <span className="neo-asset-brand">Neo</span>
                </div>

                {isLoading ? (
                    <div className="neo-asset-carousel-loading-container">
                        <div className="neo-asset-loader-container">
                            <div className="neo-asset-loader"></div>
                        </div>
                    </div>
                ) : error ? (
                    <div className="neo-asset-carousel-container">
                        <p>Error loading assets: {error.message}</p>
                    </div>
                ) : (
                    <div className="neo-asset-carousel-container">
                        <div className="neo-asset-carousel">
                            {assets.length > 0 ? (
                                assets.map((asset, index) => (
                                    <div key={index} className="neo-asset-item">
                                        <div className="neo-asset-media-container">
                                            {asset.media_type === '2d' && asset.file_url && (
                                                <img src={asset.file_url} alt={`Asset ${index}`} />
                                            )}
                                            {asset.media_type === 'video' && asset.file_url && (
                                                <video controls>
                                                    <source src={asset.file_url} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            )}
                                            {!asset.media_type && asset.file_url && (
                                                <a href={asset.file_url} target="_blank" rel="noopener noreferrer">
                                                    View File
                                                </a>
                                            )}
                                            {!asset.file_url && (
                                                <p>No content available</p>
                                            )}
                                        </div>
                                        {asset.media_type === '2d' && asset.file_url && (
                                            <a
                                                href="#" // Using href="#" to prevent page jump, functionality in onClick
                                                className="neo-asset-download-link"
                                                onClick={(e) => {
                                                    e.preventDefault(); // Prevent default link behavior
                                                    handleDownload(asset.file_url, `asset-${index}.png`);
                                                }}
                                            >
                                                Download
                                                <FiDownload />
                                            </a>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="neo-asset-item">
                                    <p>No assets available for this task.</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <div className="neo-asset-footer">
                    <button className="neo-asset-close-button" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default NeoAsset;
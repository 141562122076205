// src/components/Settings.js
import React, { useState } from 'react';
import Sidebar from './Sidebar';
import './Settings.css';

const Settings = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="settings-container">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      <div className="main-content" style={{ marginLeft: isSidebarOpen ? '250px' : '80px' }}>
        <h2>Settings</h2>
        {/* Settings form content - Placeholder for now */}
        <p>Settings content will go here...</p>
      </div>
    </div>
  );
};

export default Settings;
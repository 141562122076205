// Draw.js
import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import * as turf from "@turf/turf";
import { FaSearch, FaMap, FaTimes } from "react-icons/fa";
import ReactDOM from "react-dom/client";
import { mapStyles, mapStyleDisplayNames } from "./MapStyle";

const Draw = ({
  map,
  drawRef,
  isSearchActive,
  toggleSearch,
  onMapStyleChange,
}) => {
  const drawControlWithSearchRef = useRef(null);

  useEffect(() => {
    if (map) {
      const modes = MapboxDraw.modes;
      modes.draw_circle = {
        ...modes.draw_polygon,
        toDisplayFeatures: function (state, geojson, display) {
          display(geojson);
          if (state.currentVertexPosition === state.polygon.vertices.length) {
            let center = state.polygon.properties.center;
            let radius = turf.distance(
              center,
              geojson.geometry.coordinates[0][0]
            );
            let circle = turf.circle(center, radius);
            display(circle);
          }
        },
      };

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          line_string: true,
          point: true,
          trash: true,
        },
        modes: modes,
      });

      drawRef.current = draw;

      // Only create and add the control if it doesn't exist
      if (!drawControlWithSearchRef.current) {
        drawControlWithSearchRef.current = new DrawControlWithSearch({
          draw,
          isSearchActive,
          toggleSearch,
          map,
          onMapStyleChange: onMapStyleChange,
        });
        map.addControl(drawControlWithSearchRef.current, "bottom-right"); // Changed to bottom-right
      }

      map.on("draw.create", updateArea);
      map.on("draw.delete", updateArea);
      map.on("draw.update", updateArea);

      return () => {
        if (map && map.getStyle()) {
          map.off("draw.create", updateArea);
          map.off("draw.delete", updateArea);
          map.off("draw.update", updateArea);

          const drawInstance = drawRef.current;
          if (drawInstance) {
            const featureIds = drawInstance.getFeatureIdsAt([0, 0]);
            if (featureIds.length > 0) {
              drawInstance.delete(featureIds);
            }
          }

          if (map.hasControl(drawControlWithSearchRef.current)) {
            map.removeControl(drawControlWithSearchRef.current);
            drawControlWithSearchRef.current = null; // Reset the ref
          }

          // Correctly remove draw-related sources and layers
          const drawSourceIds = ["mapbox-gl-draw-cold", "mapbox-gl-draw-hot"];
          const drawLayerIds = [
            "gl-draw-polygon-fill-inactive.cold",
            "gl-draw-polygon-fill-inactive.hot",
            "gl-draw-polygon-stroke-inactive.cold",
            "gl-draw-polygon-stroke-inactive.hot",
            "gl-draw-line-inactive.cold",
            "gl-draw-line-inactive.hot",
            "gl-draw-point-point-stroke-inactive.cold",
            "gl-draw-point-point-stroke-inactive.hot",
            "gl-draw-polygon-and-line-vertex-stroke-inactive.cold",
            "gl-draw-polygon-and-line-vertex-stroke-inactive.hot",
            "gl-draw-point-inactive.cold",
            "gl-draw-point-inactive.hot",
            "gl-draw-polygon-fill-active.cold",
            "gl-draw-polygon-fill-active.hot",
            "gl-draw-polygon-stroke-active.cold",
            "gl-draw-polygon-stroke-active.hot",
            "gl-draw-line-active",
            "gl-draw-point-active",
            "gl-draw-polygon-and-line-vertex-active",
            "gl-draw-midpoint",
            "gl-draw-polygon-and-line-vertex-inactive",
          ];

          drawLayerIds.forEach((layerId) => {
            if (map.getLayer(layerId)) {
              map.removeLayer(layerId);
            }
          });
          drawSourceIds.forEach((sourceId) => {
            if (map.getSource(sourceId)) {
              map.removeSource(sourceId);
            }
          });
        }
      };
    }
  }, [map, isSearchActive, toggleSearch, onMapStyleChange]);

  function updateArea(e) {
    if (!drawRef.current) return;

    const data = drawRef.current.getAll();
    console.log("Drawn features:", data);
  }

  return null;
};

class DrawControlWithSearch {
  constructor({ draw, isSearchActive, toggleSearch, map, onMapStyleChange }) {
    this._draw = draw;
    this._isSearchActive = isSearchActive;
    this._toggleSearch = toggleSearch;
    this._map = map;
    this._onMapStyleChange = onMapStyleChange;
    this._isMapStyleDropdownOpen = false;
  }

  onAdd(map) {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    // Add search button
    this._searchButton = this.createButton(
      "mapbox-gl-ctrl-icon mapboxgl-ctrl-search",
      "Toggle Search",
      () => {
        this._toggleSearch();
        this.updateSearchButton();
      }
    );
    this._searchIconContainer = document.createElement("div");
    this._searchIconContainer.className = "search-icon-container";
    this._searchButton.appendChild(this._searchIconContainer);
    this._container.appendChild(this._searchButton);

    // Add map style button
    this._mapStyleButton = this.createButton(
      "mapbox-gl-ctrl-icon mapboxgl-ctrl-map-style",
      "Change Map Style",
      () => {
        this._isMapStyleDropdownOpen = !this._isMapStyleDropdownOpen;
        this.updateMapStyleDropdown();
        this.updateMapStyleIcon(); // Update icon when dropdown state changes
      }
    );
    this._mapStyleIconContainer = document.createElement("div");
    this._mapStyleIconContainer.className = "map-style-icon-container";
    this._mapStyleButton.appendChild(this._mapStyleIconContainer);
    this._container.appendChild(this._mapStyleButton);

    if (this._draw) {
      const drawToolsContainer = this._draw.onAdd(map);
      Array.from(drawToolsContainer.children).forEach((button) => {
        // Add class for styling draw tools
        button.classList.add("mapbox-gl-draw-tool");
        this._container.appendChild(button);
      });
    }

    this.updateSearchButton();
    this.updateMapStyleIcon();

    return this._container;
  }

  createButton(className, title, onClick) {
    const button = document.createElement("button");
    button.className = className;
    button.type = "button";
    button.title = title;
    button.onclick = onClick;
    return button;
  }

  updateSearchButton() {
    this._searchButton.style.color = this._isSearchActive ? "orange" : "white";

    if (this._searchIconContainer && this._searchIconContainer.firstChild) {
      this._searchIconContainer.removeChild(this._searchIconContainer.firstChild);
    }

    const iconColor = this._isSearchActive ? "orange" : "white";
    const searchIcon = <FaSearch style={{ color: iconColor }} />;

    const root = ReactDOM.createRoot(this._searchIconContainer);
    root.render(searchIcon);
  }

  updateMapStyleIcon() {
    if (this._mapStyleIconContainer && this._mapStyleIconContainer.firstChild) {
      this._mapStyleIconContainer.removeChild(
        this._mapStyleIconContainer.firstChild
      );
    }

    const iconColor = this._isMapStyleDropdownOpen ? "orange" : "white";
    const mapStyleIcon = <FaMap style={{ color: iconColor }} />;
    const root = ReactDOM.createRoot(this._mapStyleIconContainer);
    root.render(mapStyleIcon);
  }

  updateMapStyleDropdown() {
    if (this._mapStyleDropdownContainer) {
      this._mapStyleDropdownContainer.remove();
      this._mapStyleDropdownContainer = null;
    }

    if (this._isMapStyleDropdownOpen) {
      this._mapStyleDropdownContainer = document.createElement("div");
      this._mapStyleDropdownContainer.className = "map-style-dropdown-container";

      // --- Create title container separately ---
      const titleContainer = document.createElement("div");
      titleContainer.className = "map-style-title-container";

      const title = document.createElement("h3");
      title.className = "map-style-dropdown-title";
      title.textContent = "Styles";

      titleContainer.appendChild(title);
      this._mapStyleDropdownContainer.appendChild(titleContainer);
      // --- End of title container creation ---

      const closeButton = this.createButton(
        "map-style-dropdown-close",
        "Close",
        () => {
          this._isMapStyleDropdownOpen = false;
          this.updateMapStyleDropdown();
          this.updateMapStyleIcon(); // Update icon when dropdown is closed
        }
      );

      const closeIconContainer = document.createElement("div");
      closeIconContainer.className = "close-icon-container";
      closeButton.appendChild(closeIconContainer);
      const closeIcon = <FaTimes style={{ color: "black" }} />;
      const closeIconRoot = ReactDOM.createRoot(closeIconContainer);
      closeIconRoot.render(closeIcon);

      this._mapStyleDropdownContainer.appendChild(closeButton);

      // --- Content container for React-rendered elements ---
      const contentContainer = document.createElement("div");
      contentContainer.className = "map-style-content-container";
      this._mapStyleDropdownContainer.appendChild(contentContainer);
      // --- End of content container creation ---

      const currentStyle = this._map.getStyle().name;

      const dropdownContent = (
        <div className="map-style-dropdown-content">
          <div className="map-style-grid">
            {Object.entries(mapStyles).map(([key, value]) => (
              <div
                key={key}
                className={`map-style-item ${
                  currentStyle === mapStyleDisplayNames[key] ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  this._onMapStyleChange(value);
                  this._isMapStyleDropdownOpen = false;
                  this.updateMapStyleDropdown();
                  this.updateMapStyleIcon();
                }}
              >
                <img
                  src={`/${key}.png`}
                  alt={key}
                  className="map-style-image"
                />
                <span className="map-style-name">
                  {mapStyleDisplayNames[key]}
                </span>
              </div>
            ))}
          </div>
        </div>
      );

      // Render React content into the content container
      const root = ReactDOM.createRoot(contentContainer);
      root.render(dropdownContent);

      this._map.getContainer().appendChild(this._mapStyleDropdownContainer);
    }
  }

  onRemove() {
    if (this._container && this._container.parentNode) {
      this._container.parentNode.removeChild(this._container);
    }
    if (this._draw && this._map) {
      this._draw.onRemove(this._map);
    }
    this._map = null;
    this._draw = null;
  }
}

export default Draw;
// Api.js
import React, { useState, useEffect, useCallback } from 'react';
import './Api.css';
import { useAuth } from "@clerk/clerk-react";
import { FaPlus, FaEye, FaEyeSlash, FaQuestionCircle, FaMagic, FaListAlt, FaBook, FaCreditCard, FaCopy, FaImage, FaVolumeUp, FaFilm, FaCube, FaMicrophone, FaCheck, FaTimes } from 'react-icons/fa';

function Api() {
    const { userId, getToken } = useAuth();
    const [apiKeys, setApiKeys] = useState([]);
    const [newApiKey, setNewApiKey] = useState(null);
    const [showApiKeyIndex, setShowApiKeyIndex] = useState(null);
    const [historyData, setHistoryData] = useState([]);
    const [loadingApiKeys, setLoadingApiKeys] = useState(false);
    const [errorApiKeys, setErrorApiKeys] = useState(null);
    const [loadingHistory, setLoadingHistory] = useState(false);
    const [errorHistory, setErrorHistory] = useState(null);
    const [isMemoryOn, setIsMemoryOn] = useState(false);
    const [isTasteOn, setIsTasteOn] = useState(false);
    const [isRetentionOn, setIsRetentionOn] = useState(false);
    const [isMemoryPopoverVisible, setIsMemoryPopoverVisible] = useState(false);
    const [isTastePopoverVisible, setIsTastePopoverVisible] = useState(false);
    const [isRetentionPopoverVisible, setIsRetentionPopoverVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalImages, setTotalImages] = useState(0);


    const fetchApiKeys = useCallback(async () => {
        if (!userId) return;
        setLoadingApiKeys(true);
        setErrorApiKeys(null);
        try {
            const token = await getToken();
            const response = await fetch(`https://embed.axv.ai/api/organization/api/${userId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setApiKeys(data.api_key || []);
        } catch (error) {
            console.error("Could not fetch API keys:", error);
            setErrorApiKeys(error.message);
        } finally {
            setLoadingApiKeys(false);
        }
    }, [userId, getToken]);

    const generateApiKey = useCallback(async () => {
        if (!userId) return;
        setErrorApiKeys(null);
        try {
            const token = await getToken();
            const response = await fetch('https://embed.axv.ai/api/organization/api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: userId })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setNewApiKey(data.api_key);
            setApiKeys(prevKeys => [...prevKeys, data.api_key]); // Optimistically update the list
        } catch (error) {
            console.error("Could not generate API key:", error);
            setErrorApiKeys(error.message);
        }
    }, [userId, getToken]);

    const fetchHistoryData = useCallback(async (page) => {
        if (!userId) return;
        setLoadingHistory(true);
        setErrorHistory(null);
        try {
            const token = await getToken();
            const response = await fetch(`https://embed.axv.ai/api/image?user_id=${userId}&category=private&page=${page}`, { // Added page parameter
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setHistoryData(data.imageList || []);
            setCurrentPage(data.currentPage);
            setTotalPages(data.totalPages);
            setTotalImages(data.totalimages);
        } catch (error) {
            console.error("Could not fetch history data:", error);
            setErrorHistory(error.message);
        } finally {
            setLoadingHistory(false);
        }
    }, [userId, getToken]);

    useEffect(() => {
        fetchApiKeys();
        fetchHistoryData(currentPage); // Fetch initial history data for page 1
    }, [fetchApiKeys, fetchHistoryData, currentPage]); // Added currentPage to dependency array

    const handleShowApiKey = (index) => {
        setShowApiKeyIndex(index);
    };

    const handleHideApiKey = () => {
        setShowApiKeyIndex(null);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    };

    const toggleMemory = () => {
        setIsMemoryOn(!isMemoryOn);
    };

    const toggleTaste = () => {
        setIsTasteOn(!isTasteOn);
    };

    const toggleRetention = () => {
        setIsRetentionOn(!isRetentionOn);
    };

    const handleMemoryPopoverOpen = () => {
        setIsMemoryPopoverVisible(true);
    };

    const handleMemoryPopoverClose = () => {
        setIsMemoryPopoverVisible(false);
    };

    const handleTastePopoverOpen = () => {
        setIsTastePopoverVisible(true);
    };

    const handleTastePopoverClose = () => {
        setIsTastePopoverVisible(false);
    };

    const handleRetentionPopoverOpen = () => {
        setIsRetentionPopoverVisible(true);
    };

    const handleRetentionPopoverClose = () => {
        setIsRetentionPopoverVisible(false);
    };

    const handleCopyApiKey = async (apiKey) => {
        try {
            await navigator.clipboard.writeText(apiKey);
            alert('API Key copied to clipboard!'); // Simple feedback, can be improved
        } catch (err) {
            console.error('Failed to copy API key: ', err);
            alert('Failed to copy API Key, please copy manually.');
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const getFormatIcon = (mediaType) => {
        switch (mediaType) {
            case 'image': return <FaImage title="Image" />;
            case 'sound': return <FaVolumeUp title="Sound" />;
            case 'video': return <FaFilm title="Video" />;
            case '3d': return <FaCube title="3D" />;
            case 'voice': return <FaMicrophone title="Voice" />;
            default: return mediaType; // Fallback to text if no icon
        }
    };

    const getStatusIndicator = (status) => {
        switch (status) {
            case 'complete': return <FaCheck className="status-icon status-complete" title="Complete" />;
            case 'failed': return <FaTimes className="status-icon status-failed" title="Failed" />;
            case 'progress': return <span className="status-loading-spinner" title="In Progress"><span className="status-loading-spinner-inner"></span></span>;
            default: return status; // Fallback to text if no icon
        }
    };


    return (
        <div className="api-page">
            <h1 className="api-page-title">Developer Console</h1>

            <section className="api-keys-section-grid">
                <div className="api-keys-list">
                    <h2 className="api-section-title">API Keys</h2>
                    {loadingApiKeys && <p className="api-loading">Loading API Keys...</p>}
                    {errorApiKeys && <p className="api-error">Error fetching API Keys: {errorApiKeys}</p>}
                    {apiKeys.map((apiKey, index) => (
                        <div key={index} className="api-key-item">
                            <div className="api-key-display">
                                <input
                                    type={showApiKeyIndex === index ? "text" : "password"}
                                    value={showApiKeyIndex === index ? apiKey : "*******"}
                                    readOnly
                                    className="api-key-input"
                                    onMouseEnter={() => handleShowApiKey(index)}
                                    onMouseLeave={handleHideApiKey}
                                />
                                 <button
                                    className="api-key-show-button"
                                    onClick={() => showApiKeyIndex === index ? handleHideApiKey() : handleShowApiKey(index)}
                                >
                                    {showApiKeyIndex === index ? <FaEyeSlash /> : <FaEye />}
                                </button>
                                <button
                                    className="api-key-copy-button"
                                    onClick={() => handleCopyApiKey(apiKey)}
                                >
                                    <FaCopy />
                                </button>
                            </div>
                        </div>
                    ))}
                    <button className="api-generate-button" onClick={generateApiKey}>
                        <FaPlus /> Generate New API Key
                    </button>
                    {newApiKey && <p className="api-new-key">New API Key: <span>{newApiKey}</span></p>}
                </div>

                <div className="api-key-cards">
                    <a href="https://guide.neocu.com/api-reference/generate" target="_blank" rel="noopener noreferrer" className="api-card">
                        <FaMagic className="api-card-icon" />
                        <span className="api-card-text">Generate</span>
                    </a>
                    <a href="https://guide.neocu.com/api-reference/tasks" target="_blank" rel="noopener noreferrer" className="api-card">
                        <FaListAlt className="api-card-icon" />
                        <span className="api-card-text">Tasks</span>
                    </a>
                </div>
            </section>

            <section className="preferences-section-grid">
                <div className="memory-taste-section">
                    <h2 className="api-section-title">Preferences</h2>
                    <div className="preference-item">
                        <div className="preference-label">
                            Memory
                            <button
                                className="preference-info-button"
                                onMouseEnter={handleMemoryPopoverOpen}
                                onMouseLeave={handleMemoryPopoverClose}
                            >
                                <FaQuestionCircle />
                            </button>
                            {isMemoryPopoverVisible && (
                                <div className="preference-popover" onMouseEnter={handleMemoryPopoverOpen} onMouseLeave={handleMemoryPopoverClose}>
                                    Memory feature helps the AI remember past interactions within the same session, leading to more context-aware and consistent responses.
                                </div>
                            )}
                        </div>
                        <div className="preference-switch">
                            <label className="switch">
                                <input type="checkbox" checked={isMemoryOn} onChange={toggleMemory} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div className="preference-item">
                        <div className="preference-label">
                            Taste
                            <button
                                className="preference-info-button"
                                onMouseEnter={handleTastePopoverOpen}
                                onMouseLeave={handleTastePopoverClose}
                            >
                                <FaQuestionCircle />
                            </button>
                            {isTastePopoverVisible && (
                                <div className="preference-popover" onMouseEnter={handleTastePopoverOpen} onMouseLeave={handleTastePopoverClose}>
                                    Taste feature influences the AI's creative style and output, allowing for variations in tone, aesthetics, and approach based on user preference.
                                </div>
                            )}
                        </div>
                        <div className="preference-switch">
                            <label className="switch">
                                <input type="checkbox" checked={isTasteOn} onChange={toggleTaste} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div className="preference-item">
                        <div className="preference-label">
                            Retention
                            <button
                                className="preference-info-button"
                                onMouseEnter={handleRetentionPopoverOpen}
                                onMouseLeave={handleRetentionPopoverClose}
                            >
                                <FaQuestionCircle />
                            </button>
                            {isRetentionPopoverVisible && (
                                <div className="preference-popover" onMouseEnter={handleRetentionPopoverOpen} onMouseLeave={handleRetentionPopoverClose}>
                                    Retention preference determines how long the AI retains information from past sessions, impacting long-term memory and personalization.
                                </div>
                            )}
                        </div>
                        <div className="preference-switch">
                            <label className="switch">
                                <input type="checkbox" checked={isRetentionOn} onChange={toggleRetention} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
                 <div className="preferences-cards">
                    <a href="https://guide.neocu.com/working-with-neocu/general-intelligence" target="_blank" rel="noopener noreferrer" className="api-card">
                        <FaBook className="api-card-icon" />
                        <span className="api-card-text">Guide</span>
                    </a>
                    <a href="https://guide.neocu.com/platform/billing" target="_blank" rel="noopener noreferrer" className="api-card">
                        <FaCreditCard className="api-card-icon" />
                        <span className="api-card-text">Billing</span>
                    </a>
                </div>
            </section>


            <section className="history-section">
                <h2 className="api-section-title">History</h2>
                {loadingHistory && <p className="api-loading">Loading History...</p>}
                {errorHistory && <p className="api-error">Error fetching history: {errorHistory}</p>}
                {!loadingHistory && !errorHistory && historyData.length === 0 && <p className="api-no-data">No history data available.</p>}
                {historyData.length > 0 && (
                    <div className="history-table-container">
                        <table className="history-table">
                            <thead>
                                <tr className="history-header-row">
                                    <th className="history-header-cell">Name</th>
                                    <th className="history-header-cell">Format</th>
                                    <th className="history-header-cell">Status</th>
                                    <th className="history-header-cell">Date</th>
                                    <th className="history-header-cell">Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                {historyData.map((item, index) => (
                                    <tr key={index} className="history-row">
                                        <td className="history-cell">{item.name}</td>
                                        <td className="history-cell">{getFormatIcon(item.media_type)}</td>
                                        <td className="history-cell">{getStatusIndicator(item.status)}</td>
                                        <td className="history-cell">{formatDate(item.createdAt)}</td>
                                        <td className="history-cell">{item.cost || '1.00'}</td> {/* Default cost 1.00 */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <div className="history-pagination">
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                    {totalImages > 0 && <span className="history-total">Total Images: {totalImages}</span>}
                </div>
            </section>
        </div>
    );
}

export default Api;
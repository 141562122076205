// Hub.js
import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './Upload.css';
import { FaImage, FaCube, FaFilePdf, FaFileVideo, FaFolder, FaLightbulb } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';

function Upload() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [smartSourceOn, setSmartSourceOn] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    setUploadedFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
    setIsUploaded(true);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const handleSmartSourceToggle = () => {
    setSmartSourceOn(!smartSourceOn);
  };

  return (
    <div className="hub-container" style={{ justifyContent: isUploaded ? 'flex-start' : 'center' }}>
      <h1 className={`hub-title ${isUploaded ? 'hub-title-small' : ''}`}>AI Source</h1>

      <div {...getRootProps()} className={`drag-drop-box ${isDragActive ? 'drag-drop-box-active' : ''} ${isUploaded ? 'drag-drop-box-small' : ''}`}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop files here ...</p> :
            <p>Drag and drop files here, or click to select files</p>
        }
      </div>

      {uploadedFiles.length > 0 && (
        <div className="uploaded-files-grid">
          {uploadedFiles.map(file => (
            <div key={file.name} className="uploaded-file-item">
              {file.type.startsWith('image/') && <img src={file.preview} alt="thumbnail" className="file-thumbnail" />}
              {file.type.startsWith('application/pdf') && <FaFilePdf className="file-thumbnail-icon" />}
              {file.type.startsWith('video/') && <FaFileVideo className="file-thumbnail-icon" />}
              {!file.type.startsWith('image/') && !file.type.startsWith('application/pdf') && !file.type.startsWith('video/') && <FaFileVideo className="file-thumbnail-icon" />} {/* Generic icon for other file types, you can replace with a more suitable one */}
              <p className="file-name">{file.name}</p>
              <p className="file-type">{file.type.split('/')[0] || 'File'}</p>
            </div>
          ))}
        </div>
      )}


      <div className={`hub-cards ${isUploaded ? 'hub-cards-small' : ''}`}>
        <Link to="/image" className="hub-card">
          <FaImage className="hub-card-icon" />
          <h2 className="hub-card-title">Image</h2>
          <p className="hub-card-description">
            Explore image related AI tools.
          </p>
        </Link>

        <Link to="/3d" className="hub-card">
          <FaCube className="hub-card-icon" />
          <h2 className="hub-card-title">3D</h2>
          <p className="hub-card-description">
            Work with 3D models and scenes.
          </p>
        </Link>

        <Link to="/pdf" className="hub-card">
          <FaFilePdf className="hub-card-icon" />
          <h2 className="hub-card-title">PDF</h2>
          <p className="hub-card-description">
            Interact with PDF documents.
          </p>
        </Link>

        <Link to="/video" className="hub-card">
          <FaFileVideo className="hub-card-icon" />
          <h2 className="hub-card-title">Video</h2>
          <p className="hub-card-description">
            Process and analyze video content.
          </p>
        </Link>

        <Link to="/assets" className="hub-card">
          <FaFolder className="hub-card-icon" />
          <h2 className="hub-card-title">My Assets</h2>
          <p className="hub-card-description">
            Manage and access your assets.
          </p>
        </Link>

        <div className="hub-card smart-source-card">
          <FaLightbulb className="hub-card-icon" />
          <h2 className="hub-card-title">Smart Source</h2>
          <p className="hub-card-description">
            Intelligent AI source processing.
          </p>
          <label className="switch">
            <input type="checkbox" checked={smartSourceOn} onChange={handleSmartSourceToggle} />
            <span className="slider round"></span>
          </label>
          <p className="switch-label">{smartSourceOn ? 'On' : 'Off'}</p>
        </div>
      </div>
    </div>
  );
}

export default Upload
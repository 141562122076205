// Room.js
import React, { useState } from 'react';
import './Room.css';
import { AiOutlineSetting, AiOutlineBug, AiOutlineLogs, AiOutlineClose, AiOutlineMenu, AiOutlineOrderedList } from 'react-icons/ai';
import { BiCloud } from 'react-icons/bi'; // Using BiCloud instead of IoLogoSupabase
import { FiEdit } from 'react-icons/fi';
import { RiEarthFill } from 'react-icons/ri';
import { BsFillTriangleFill } from 'react-icons/bs';
import { IoMdAttach } from 'react-icons/io';
import { SlArrowRight, SlArrowLeft } from 'react-icons/sl';

const dummyChatMessages = [
    { sender: 'Bot', text: 'Hello!', time: '10:00 AM' },
    { sender: 'User', text: 'Hi there, how are you?', time: '10:01 AM' },
    { sender: 'Bot', text: 'I am doing well, thank you for asking. How can I help you today?', time: '10:02 AM' },
    { sender: 'User', text: 'I need some assistance with my project.', time: '10:03 AM' },
    { sender: 'Bot', text: 'Sure, I can help with that. Please describe your project and the issue you are facing.', time: '10:04 AM' },
    { sender: 'User', text: 'It is a dashboard application and I am having trouble with the confetti animation. This is a very long sentence to test overflow and word wrapping in the chat bubble.', time: '10:05 AM' },
    { sender: 'Bot', text: 'Okay, I understand. Let\'s take a look at your code. Can you share the relevant code snippet?', time: '10:06 AM' },
    { sender: 'User', text: 'Yes, here it is...', time: '10:07 AM' },
];

const dummyHistoryCards = [
    { title: 'Initial Setup', description: 'Project creation and initial configurations.', date: 'Yesterday' },
    { title: 'Added Confetti Animation', description: 'Implemented confetti animation on button click.', date: '2 days ago' },
    { title: 'Stat Cards Implementation', description: 'Created animated stat cards for the dashboard.', date: '3 days ago' },
];


const Room = () => {
    const [activeTab, setActiveTab] = useState('Chat');
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    return (
        <div className="room-container">
            <div className="room-header">
                <div className="header-left">
                    <img src="/logo.png" alt="Logo" className="logo-room" />
                    <button className="sidebar-toggle-button" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                        {isSidebarOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
                    </button>
                    <div className="project-title-dropdown">
                        <div className="project-title">festive-tsparticles-haven</div>
                        <BsFillTriangleFill className="dropdown-icon" />
                    </div>
                </div>
                <div className="header-right">
                    <button className="header-icon-button"><AiOutlineSetting /></button>
                    <button className="header-supabase-button">
                        <BiCloud /> Supabase {/* Using BiCloud */}
                    </button>
                    <button className="header-edit-code">
                        <FiEdit /> Edit code
                    </button>
                    <button className="header-publish">
                        <RiEarthFill /> Publish
                    </button>
                </div>
            </div>
            <div className="room-body">
                <div className={`room-sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
                    <div className="sidebar-tabs">
                        <button
                            className={`sidebar-tab ${activeTab === 'Chat' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Chat')}
                        >
                            Chat
                        </button>
                        <button
                            className={`sidebar-tab ${activeTab === 'History' ? 'active' : ''}`}
                            onClick={() => setActiveTab('History')}
                        >
                            History
                        </button>
                    </div>
                    <div className="sidebar-content">
                        {activeTab === 'Chat' && (
                            <div className="chat-tab-content">
                                {/* Removed the content above the chat messages */}
                                <div className="chat-messages-container">
                                    {dummyChatMessages.map((message, index) => (
                                        <div key={index} className={`chat-message ${message.sender.toLowerCase()}`}>
                                            <div className="message-sender">{message.sender}</div>
                                            <div className="message-text">{message.text}</div>
                                            <div className="message-time">{message.time}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className="ask-lovable-input">
                                    <div className="input-area">
                                        <input type="text" placeholder="Ask Lovable..." />
                                    </div>
                                    <div className="input-buttons-bottom"> {/* New container for buttons below input */}
                                        <button className="attach-button"><IoMdAttach /></button>
                                        <button className="select-button">Select</button>
                                    </div>
                                    <button className="send-button">→</button>
                                </div>
                            </div>
                        )}
                        {activeTab === 'History' && (
                            <div className="history-tab-content">
                                {dummyHistoryCards.map((card, index) => (
                                    <div key={index} className="history-card">
                                        <div className="history-card-title">{card.title}</div>
                                        <div className="history-card-date">{card.date}</div>
                                        <div className="history-card-description">{card.description}</div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="room-content">
                    <div className="error-box">
                        <div className="error-box-header">
                            <div className="error-icon"><BsFillTriangleFill /></div>
                            Error
                        </div>
                        <div className="error-box-message">The app encountered an error</div>
                        <div className="error-box-actions">
                            <button className="try-to-fix-button">
                                Try to fix <AiOutlineBug />
                            </button>
                            <button className="show-logs-button">
                                Show logs <AiOutlineOrderedList /> {/* Using AiOutlineOrderedList as similar to Logs */}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Room;
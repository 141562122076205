import React, { useState, useEffect } from 'react';
import './MarkerCard.css';

const MarkerCard = ({ feature, map }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (map && feature) {
      const updatePosition = () => {
        // Project the feature's coordinates to pixel coordinates on the map.
        const pos = map.project(feature.geometry.coordinates);

        // Calculate the card's position relative to the bottom-right corner of the map.
        // We're initially using position.x/y so that we have the position correct on initial load.
         let cardX = pos.x;
         let cardY = pos.y;

        // Get the map's container dimensions to calculate the bottom right corner of the map
         const mapContainer = map.getContainer();
         const mapWidth = mapContainer.offsetWidth;
         const mapHeight = mapContainer.offsetHeight;

         // Set the initial position to be the bottom right corner of the map
           cardX = mapWidth -10 ; // 10px margin from the right edge
          cardY = mapHeight - 10; // 10px margin from the bottom edge

        setPosition({ x: cardX, y: cardY });
      };

      updatePosition();

      map.on('move', updatePosition);

      return () => {
        map.off('move', updatePosition);
      };
    }
  }, [map, feature]);

  if (!feature) {
    return null;
  }

  return (
    <div
      className="marker-card"
      style={{
        right: `${position.x}px`, //Use right instead of left, we are positioning from the right
        bottom: `${position.y}px`, // Use bottom instead of top, we are positioning from the bottom
      }}
    >
      <div className="marker-card-content">
        <h3>{feature.properties.name}</h3>
        <p>
          <b>Description:</b> {feature.properties.description}
        </p>
        <p>
          <b>Case ID:</b> {feature.properties.case_id}
        </p>
        {/* Add more details as needed */}
      </div>
    </div>
  );
};

export default MarkerCard;
// src/components/Workspace.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import './Workspace.css';
import { FaTrashAlt, FaArrowRight } from 'react-icons/fa';
import { useAuth } from '@clerk/clerk-react';

const Workspace = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [mapData, setMapData] = useState([]);
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (!isSignedIn) {
      navigate('/signin');
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch('https://embed.axv.ai/api/block/map');
        const data = await response.json();
        if (data && data.blockList) {
          setMapData(data.blockList);
        }
      } catch (error) {
        console.error('Error fetching map data:', error);
      }
    };

    fetchData();
  }, [isSignedIn, navigate]);

  const handleDelete = async (id) => {
    if (!isSignedIn) {
      return;
    }

    try {
      const response = await fetch(`https://embed.axv.ai/api/block/map/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setMapData(mapData.filter((map) => map._id !== id));
      } else {
        console.error('Error deleting map:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting map:', error);
    }
  };

  const handleNavigate = (id) => {
    if (!isSignedIn) {
      return;
    }
    navigate(`/workspace/explore/${id}`);
  };

  if (!isSignedIn) {
    return null;
  }

  return (
    <div className="workspace-container">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      <div className="main-content" style={{ marginLeft: isSidebarOpen ? '250px' : '80px' }}>
        <div className="top-bar">
          <h2>Maps</h2>
          <div className="top-bar-right">
            <div className="dropdown">
              <span>Recent in workspace</span>
              <i className="fas fa-caret-down"></i>
            </div>
            <div className="dropdown">
              <span>View</span>
              <i className="fas fa-caret-down"></i>
            </div>
            <button className="new-map-button">New map</button>
          </div>
        </div>
        <div className="map-grid">
          {mapData.map((map) => (
            <div
              className="map-card"
              key={map._id}
              onClick={() => handleNavigate(map._id)}
            >
              <div className="map-image-container">
                <img src={map.thumbnail} alt={map.name} />
                <div className="map-title-overlay">
                  <h3>{map.name}</h3>
                </div>
                <div className="map-icons">
                  <FaTrashAlt
                    className="delete-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(map._id);
                    }}
                  />
                  <FaArrowRight className="navigate-icon" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Workspace;
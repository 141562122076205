import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ClerkProvider } from '@clerk/clerk-react';
import { neobrutalism } from '@clerk/themes';

const rootElement = document.getElementById("root");

// Use JSDoc to indicate the type
/** @type {HTMLDivElement} */
if (!rootElement) throw new Error("Root element not found");
const root = ReactDOM.createRoot(rootElement);

//pk_test_bG95YWwtZ29yaWxsYS02Ny5jbGVyay5hY2NvdW50cy5kZXYk
//pk_live_Y2xlcmsubmVvY3UuY29tJA
// JSDoc for environment variable
/** @type {string} */
const publishableKey = 'pk_live_Y2xlcmsuZGVuZHJpYy5jb20k';
if (!publishableKey) {
    throw new Error("Missing _CLERK_PUBLISHABLE_KEY env variable");
}



root.render(
  <React.StrictMode>
 <ClerkProvider
  publishableKey={publishableKey}
  afterSignOutUrl="/signin"
  appearance={{
    baseTheme: 'dark',
    variables: {
      colorPrimary: '#FF6700',
      colorBackground: '#000000',
      colorText: '#FFFFFF',
      colorTextOnPrimaryBackground: '#FFFFFF',
      colorTextSecondary: '#FFFFFF',
      colorInputText: '#FFFFFF',
      spacingUnit: '1rem'
    },
    elements: {
      card: {
        fontSize: 14,
        backgroundColor: '#000000',
        maxHeight: '96vh',
        marginTop: '8vh',
        marginBottom: '8vh',
        overflowY: 'auto'
      },
    },
  }}
>
  <App />
</ClerkProvider>
  </React.StrictMode>
);
// SignUp.js
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSignUp, SignUp as ClerkSignUp } from '@clerk/clerk-react';
import './SignInModal.css';

const SignUp = () => {
  const navigate = useNavigate();
  const { isLoaded, signUp, setActive } = useSignUp();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    const handleSignUp = async () => {
      if (!isLoaded) {
        return;
      }

      if (signUp.status === 'complete') {
        try {
          await setActive({ session: signUp.createdSessionId });
          navigate(from, { replace: true });
        } catch (err) {
          console.error('Error setting active session', err);
        }
      } else {
        console.log("Sign-up status:", signUp.status);
      }
    };

    handleSignUp();
  }, [isLoaded, signUp, setActive, navigate, from]);

 

  return (
    <div
      style={{
        backgroundImage: `url('/blue_ocean.jpg')`, // Set the background image
        backgroundSize: 'cover', // Cover the entire container
        backgroundPosition: 'center', // Center the image
        backgroundRepeat: 'no-repeat', // Prevent image repetition
        position: 'fixed',
        top: 10,
        left: 0,
        right: 0,
        bottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}
    >
      {isLoaded && (
        <ClerkSignUp
          afterSignUpUrl={from}
          routing="path"
          path="/signup"
          signInUrl="/signin"
       
        />
      )}
    </div>
  );
};

export default SignUp;
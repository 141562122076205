import React, { useState } from 'react';
import './Legend.css';
import {
  FaTimes,
  FaQuestionCircle,
  FaMinus,
  FaPlus,
  FaCrosshairs,
} from 'react-icons/fa';

const Legend = ({ items, onItemVisibilityChange, onItemRemove, onCenterItem, onMinimize, isMinimized }) => {
  const [minimized, setMinimized] = useState(isMinimized);

  const handleMinimize = () => {
    setMinimized(!minimized);
    onMinimize(!minimized);
  };

  const toggleVisibility = (id) => {
    onItemVisibilityChange(id);
  };

  const removeItem = (id) => {
    onItemRemove(id);
  };

  const centerItem = (center) => {
    onCenterItem(center);
  };

  return (
    <div className={`legend ${minimized ? 'minimized' : ''}`}>
      <div className="legend-header">
        <span className="legend-title">{minimized ? '' : 'Legend'}</span>
        <button className="minimize-button" onClick={handleMinimize}>
          {minimized ? <FaPlus /> : <FaMinus />}
        </button>
      </div>
      {!minimized && (
        <ul className="legend-list">
          {items.map((item) => (
            <li key={item.id} className="legend-item">
              <span
                className="legend-color"
                style={{ backgroundColor: item.color }}
              ></span>
              <span className="legend-label">{item.legendName}</span>
              <div className="tooltip-container">
                <FaQuestionCircle className="tooltip-icon" />
                <span className="tooltip-text">{item.description}</span>
              </div>
              <div className="legend-icons">
                <button
                  className="center-button"
                  onClick={() => centerItem(item.center)}
                >
                  <FaCrosshairs />
                </button>
                <input
                  type="checkbox"
                  checked={item.visible}
                  onChange={() => toggleVisibility(item.id)}
                  id={`legend-item-${item.id}`}
                />
                <label htmlFor={`legend-item-${item.id}`}></label>
                <button className="remove-button" onClick={() => removeItem(item.id)}>
                  <FaTimes />
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Legend;
// vistorContent.js
import React, { useRef, useEffect, useState } from 'react';
import './VisitorContent.css';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faPlay, faPause);


function EarthModel() {
    const earth = useRef();
    const gltf = useLoader(GLTFLoader, '/earth_3d.glb');

    useFrame(() => {
        if (earth.current) {
            earth.current.rotation.y += 0.003;
        }
    });

    useEffect(() => {
        if (gltf.scene) {
            gltf.scene.scale.set(1.5, 1.5, 1.5);
        }
    }, [gltf]);

    return <primitive object={gltf.scene} ref={earth} />;
}


function VisitorContent({ dynamicMapEmbedUrl, personalizeData }) {
    const defaultMapUrl = `https://embed.neocu.com/map/3434`;
    const iframeRef = useRef(null);
    const panoramaIframeRef = useRef(null); // Ref for panorama iframe if needed for direct manipulation
    const [activeSegment, setActiveSegment] = useState(null);
    const [currentEmbedUrl, setCurrentEmbedUrl] = useState(dynamicMapEmbedUrl || defaultMapUrl);
    const crimeText = personalizeData?.crime;
    const economyText = personalizeData?.economy;
    const consumerAudio = personalizeData?.consumer_audio;
    const businessAudio = personalizeData?.business_audio;
    const panorama = personalizeData?.panorama;
    const threeD = personalizeData?.three_d;
    const [activeOverlay, setActiveOverlay] = useState(null); // State to control active overlay
    const consumerAudioRef = useRef(null);
    const businessAudioRef = useRef(null);
    const [isPlayingConsumerAudio, setIsPlayingConsumerAudio] = useState(false);
    const [isPlayingBusinessAudio, setIsPlayingBusinessAudio] = useState(false);
    const [activePanorama, setActivePanorama] = useState(false); // State for panorama visibility
    const [active3D, setActive3D] = useState(false);


    useEffect(() => {
        setCurrentEmbedUrl(dynamicMapEmbedUrl || defaultMapUrl); // Update currentEmbedUrl when dynamicMapEmbedUrl prop changes
    }, [dynamicMapEmbedUrl, defaultMapUrl]);

    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.src = currentEmbedUrl;
        }
    }, [currentEmbedUrl]);


    const handleSegmentButtonClick = (segment) => {
        setActiveSegment(segment);
        setActivePanorama(false); // Hide panorama when segment button is clicked
        setActive3D(false);
        let baseUrl = dynamicMapEmbedUrl || defaultMapUrl;
        let newEmbedUrl = baseUrl;
        if (segment) {
            newEmbedUrl = `${baseUrl}&segment=${segment}`;
        } else {
            // If segment is null (when deselecting), use the base URL
            newEmbedUrl = baseUrl;
        }
        setCurrentEmbedUrl(newEmbedUrl);
        setActiveOverlay(null); // Ensure no overlay when changing segments, handled more specifically below

        // Control overlay visibility based on segment clicked
        if (segment === 'Economy') {
            setActiveOverlay('economy');
        } else if (segment === 'Social' || segment === 'Vision' || segment === 'Environment' || segment === 'Business') {
            setActiveOverlay(null); // No overlay for these segments
        } else if (segment === 'Crime') {
            setActiveOverlay('crime');
        } else {
            setActiveOverlay(null); // Default no overlay, also for 'All' to remove any segment specific overlay
        }
    };

    const handlePanoramaButtonClick = () => {
        setActiveSegment(null); // Deselect any segment button
        setActivePanorama(true); // Show panorama
        setActive3D(false);
        setActiveOverlay(null); // Hide any overlays when panorama is active
        setCurrentEmbedUrl(defaultMapUrl); // Optionally reset map URL to default when panorama is active. Or keep last map url.
    };

    const handle3DButtonClick = () => {
        setActiveSegment(null);
        setActivePanorama(false);
        setActive3D(true);
        setActiveOverlay(null);
        setCurrentEmbedUrl(defaultMapUrl);
    };


    const handleConsumerAudioToggle = () => {
        if (consumerAudioRef.current) {
            if (isPlayingConsumerAudio) {
                consumerAudioRef.current.pause();
                setIsPlayingConsumerAudio(false);
            } else {
                // Pause business audio if it's playing
                if (isPlayingBusinessAudio && businessAudioRef.current) {
                    businessAudioRef.current.pause();
                    setIsPlayingBusinessAudio(false);
                }
                consumerAudioRef.current.play();
                setIsPlayingConsumerAudio(true);
            }
        }
    };

    const handleBusinessAudioToggle = () => {
        if (businessAudioRef.current) {
            if (isPlayingBusinessAudio) {
                businessAudioRef.current.pause();
                setIsPlayingBusinessAudio(false);
            } else {
                // Pause consumer audio if it's playing
                if (isPlayingConsumerAudio && consumerAudioRef.current) {
                    consumerAudioRef.current.pause();
                    setIsPlayingConsumerAudio(false);
                }
                businessAudioRef.current.play();
                setIsPlayingBusinessAudio(true);
            }
        }
    };

    useEffect(() => {
        const consumerAudioPlayer = consumerAudioRef.current;
        const businessAudioPlayer = businessAudioRef.current;

        const handleConsumerAudioEnd = () => {
            setIsPlayingConsumerAudio(false);
        };
        const handleBusinessAudioEnd = () => {
            setIsPlayingBusinessAudio(false);
        };


        if (consumerAudioPlayer) {
            consumerAudioPlayer.addEventListener('ended', handleConsumerAudioEnd);
        }
        if (businessAudioPlayer) {
            businessAudioPlayer.addEventListener('ended', handleBusinessAudioEnd);
        }


        return () => {
            if (consumerAudioPlayer) {
                consumerAudioPlayer.removeEventListener('ended', handleConsumerAudioEnd);
            }
            if (businessAudioPlayer) {
                businessAudioPlayer.removeEventListener('ended', handleBusinessAudioEnd);
            }
        };
    }, []);


    return (
        <div className="visitor-content">

            {(consumerAudio || businessAudio) && (
                <div className="audio-reports-container">
                    {consumerAudio && (
                        <div className="audio-report-button-container">
                            <button className="report-button orange-button" onClick={handleConsumerAudioToggle}>
                                {isPlayingConsumerAudio ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />} Local Report
                            </button>
                            <audio ref={consumerAudioRef} src={consumerAudio} />
                        </div>
                    )}
                    {businessAudio && (
                        <div className="audio-report-button-container">
                            <button className="report-button orange-button" onClick={handleBusinessAudioToggle}>
                                {isPlayingBusinessAudio ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />} Business Report
                            </button>
                            <audio ref={businessAudioRef} src={businessAudio} />
                        </div>
                    )}
                </div>
            )}


            <div className="quick-actions">
                <button
                    className={`quick-action-button ${activeSegment === 'Economy' ? 'active' : ''} ${activePanorama || active3D ? '' : ''}`}
                    onClick={() => { handleSegmentButtonClick('Economy'); }}
                >
                    Economy
                </button>
                <button
                    className={`quick-action-button ${activeSegment === 'Social' ? 'active' : ''} ${activePanorama || active3D ? '' : ''}`}
                    onClick={() => handleSegmentButtonClick('Social')}
                >
                    Social
                </button>
                <button
                    className={`quick-action-button ${activeSegment === 'Crime' ? 'active' : ''} ${activePanorama || active3D ? '' : ''}`}
                    onClick={() => handleSegmentButtonClick('Crime')}
                >
                    Crime
                </button>
                <button
                    className={`quick-action-button ${activeSegment === 'Environment' ? 'active' : ''} ${activePanorama || active3D ? '' : ''}`}
                    onClick={() => handleSegmentButtonClick('Environment')}
                >
                    Environment
                </button>
                 <button
                    className={`quick-action-button ${activeSegment === 'Business' ? 'active' : ''} ${activePanorama || active3D ? '' : ''}`}
                    onClick={() => handleSegmentButtonClick('Business')}
                >
                    Business
                </button>
                 <button
                    className={`quick-action-button ${activeSegment === null && !activePanorama && !active3D ? 'active' : ''} ${activePanorama || active3D ? '' : ''}`}
                    onClick={() => handleSegmentButtonClick(null)} // Option to reset/remove segment
                >
                    All
                </button>
                {panorama && (
                    <button
                        className={`quick-action-button ${activePanorama ? 'active' : ''}`}
                        onClick={handlePanoramaButtonClick}
                    >
                        Imagine
                    </button>
                )}
                {threeD && (
                    <button
                        className={`quick-action-button ${active3D ? 'active' : ''}`}
                        onClick={handle3DButtonClick}
                    >
                        3D
                    </button>
                )}
            </div>


                <div className="embed-container">
                    {activePanorama && (
                        <div className="embed-overlay-card top-left imagine-space-card">
                            Imagine Any Space
                        </div>
                    )}

                    {!activePanorama && !active3D && (
                        <iframe
                            ref={iframeRef}
                            title="Data Embed"
                            width="100%"
                            height="400px"
                            src={currentEmbedUrl}
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    )}
                    {activeOverlay === 'crime' && crimeText && (
                        <div className="embed-overlay-card bottom-right crime-card">
                            {crimeText}
                        </div>
                    )}
                    {activeOverlay === 'economy' && economyText && (
                        <div className="embed-overlay-card bottom-right economy-card">
                            {economyText}
                        </div>
                    )}
                    {activePanorama && panorama && (
                        <>
                            <div className="embed-overlay-card top-right ai-generated-card">
                                AI Generated
                            </div>
                            <iframe
                                ref={panoramaIframeRef}
                                title="Panorama Embed"
                                width="100%"
                                height="400px" // Same height as map embed
                                src={panorama}
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                        </>
                    )}
                    {active3D && threeD && (
                        <div className="three-d-container">
                            <div className="three-d-video-container">
                                <video src={threeD} autoPlay loop muted controls className="three-d-video" />
                            </div>
                            <div className="digital-physical-card">
                                <h3 className="digital-physical-title">Digital To Physical</h3>
                                <p className="digital-physical-description">Move digital products into the physical world. Transform sketches, blueprints, floor plans or your ideas into high fidelity 3D models</p>
                            </div>
                        </div>
                    )}
                </div>


            <section className="feature-section">
                <h2 className="feature-section-heading">Earth's New Lens</h2>
                <div className="features-container">
                    <div className="feature-item">
                        <div className="feature-icon-container"> 🎥 </div>
                        <h3 className="feature-title">Capture</h3>
                        <p className="feature-description"> Access real-world video of virtually any location on Earth. Explore dynamic environments and witness events as they unfold, powered by our extensive network. </p>
                    </div>
                    <div className="feature-item">
                        <div className="feature-icon-container"> 📍 </div>
                        <h3 className="feature-title">Geolocate</h3>
                        <p className="feature-description">Pinpoint and access a wealth of geographical data layers. Overlay crime statistics, business data, social trends, and more onto our interactive world map.</p>
                    </div>
                    <div className="feature-item">
                        <div className="feature-icon-container"> 📊 </div>
                        <h3 className="feature-title">Analyze</h3>
                        <p className="feature-description"> Uncover hidden patterns and gain actionable insights by analyzing diverse spatial datasets. Identify trends, correlations, and anomalies with our intuitive analysis tools. </p>
                    </div>
                    <div className="feature-item">
                        <div className="feature-icon-container"> 🌍 </div>
                        <h3 className="feature-title">Visualize</h3>
                        <p className="feature-description"> Experience the world in a dynamic, interactive 3D digital twin. Visualize complex information in an intuitive and engaging way, bringing data to life.</p>
                    </div>
                </div>
            </section>


            <div className="earth-model-container">
                <Canvas camera={{ position: [0, 0, 3] }}>
                    <ambientLight intensity={0.5} />
                    <directionalLight position={[-2, 5, 2]} intensity={1} />
                    <EarthModel />
                </Canvas>
            </div>


            <section className="faq-section">
                <h2 className="faq-section-heading">Frequently Asked Questions about Dendric</h2>
                <div className="faq-items">
                    {/* FAQ items remain the same */}
                    <div className="faq-item">
                        <div className="faq-question">What is Dendric and what makes it different? <span className="faq-arrow">↓</span></div>
                        <div className="faq-answer">Dendric is building the digital twin of the world, providing unparalleled access to real-world video and geographically indexed data. Unlike traditional data platforms, Dendric offers a single, unified interface to explore, analyze, and visualize real-time and historical information across the globe. We empower businesses, researchers, and organizations to understand our world with unprecedented clarity and depth.</div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-question">Who is Dendric for? <span className="faq-arrow">↓</span></div>
                        <div className="faq-answer">Dendric is for any organization or individual seeking a deeper understanding of our world through real-world video and spatial data. This includes businesses needing location-based insights, researchers studying geographical trends, government agencies monitoring infrastructure and events, and anyone curious about global dynamics. If you need to explore and analyze the world in a visual and data-rich way, Dendric is for you.</div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-question">Do I need to be a tech expert to use Dendric? <span className="faq-arrow">↓</span></div>
                        <div className="faq-answer">Not at all! Dendric is designed to be user-friendly and accessible to everyone. You don't need specialized technical skills to navigate our platform and access powerful insights. Our intuitive interface allows you to explore and analyze complex data with ease.</div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-question">What kind of data can I access with Dendric? <span className="faq-arrow">↓</span></div>
                        <div className="faq-answer">Dendric provides access to a vast array of geographically indexed data, including real-world video from numerous sources, crime statistics, business sales data, social media trends, environmental sensor readings, political event information, and much more. We are continuously expanding our data partnerships to offer the most comprehensive digital twin of the world.</div>
                    </div>

                     <div className="faq-item">
                        <div className="faq-question">Is my data safe and private with Dendric? <span className="faq-arrow">↓</span></div>
                        <div className="faq-answer">Yes, data integrity and security are paramount at Dendric, headquartered in Toronto, Canada. We are committed to protecting user data and ensuring privacy. We adhere to strict data security protocols and comply with relevant privacy regulations. Your usage data is handled with the utmost confidentiality.</div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-question">Do you have an API? <span className="faq-arrow">↓</span></div>
                        <div className="faq-answer">Yes, Dendric offers a powerful API. This allows you to integrate Dendric's digital twin capabilities directly into your existing systems and workflows. Please visit our API documentation or contact us to explore how you can leverage the Dendric API.</div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-question">Is there a free plan available to try Dendric? <span className="faq-arrow">↓</span></div>
                        <div className="faq-answer">Yes! We offer a free plan so you can experience the power of Dendric firsthand. Check out our pricing page for details on our free plan and subscription options to access even more data and advanced features as your needs evolve.</div>
                    </div>
                </div>
            </section>

            <section className="era-section">
                <h2 className="era-section-heading">Step into the Future of World Understanding</h2>
                <div className="era-container">
                    {/* ERA items remain the same */}
                    <div className="era-item">
                        <div className="era-icon-container"> 🗺️ </div>
                        <h3 className="era-title">Explore</h3>
                        <p className="era-description">Virtually travel to any location on Earth and explore real-world environments through our extensive video network. Discover places like never before.</p>
                    </div>
                    <div className="era-item">
                       <div className="era-icon-container"> 🧠 </div>
                        <h3 className="era-title">Understand</h3>
                        <p className="era-description">Gain a deeper understanding of complex global trends by visualizing and analyzing diverse spatial datasets in a unified platform. </p>
                    </div>
                    <div className="era-item">
                        <div className="era-icon-container"> 📈 </div>
                        <h3 className="era-title">Predict</h3>
                        <p className="era-description">Leverage historical and real-time data to identify patterns and predict future outcomes based on geographical and temporal trends.</p>
                    </div>
                    <div className="era-item">
                        <div className="era-icon-container"> 🔗 </div>
                        <h3 className="era-title">Connect</h3>
                        <p className="era-description">Seamlessly connect and integrate diverse datasets from spatial and sensor systems, creating a holistic and interconnected view of our world.</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default VisitorContent;